<template>
  <div>
    <!--博客内容展示区域-->
    <div ref="scroll" class="blogInfo" v-if="blog.blog_id != null">
      <div class="ql-container ql-snow ql-demo">
        <div class="ql-editor ql-editor1">
          <Xqs class="details_box" v-html="blog.blog_content"> </Xqs>
        </div>
      </div>
    </div>
    <!--内容结尾与评论区衔接区（评论数、登录状态以及横线）-->
    <div class="the_end">
      <!--数据区-->
      <div class="comment_num">
        <!--评论数-->
        <div>
          <span>{{ commentsNum }}</span
          >条评论
        </div>
        <!--登录状态-->
        <div>
          <el-dropdown
            v-if="$store.state.user == null"
            @command="handleCommand"
            trigger="click"
          >
            <span
              class="el-dropdown-link"
              style="cursor: pointer; color: #e6a23c"
            >
              未登录<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="toLogin">登录</el-dropdown-item>
              <el-dropdown-item command="toRegist">注册</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div v-else>留下精彩的评论吧</div>
        </div>
      </div>
      <div class="to-top" v-if="distanceToTop > 500">
        <img @click="toTop()" src="@/assets/icon/totop.png" />
      </div>
      <div
        class="easy-search"
        v-if="blog.blog_json_nav != null && blog.blog_json_nav.length > 0"
      >
        <el-select
          v-if="showSelect"
          v-model="value"
          filterable
          placeholder="搜索关键字"
          @change="toFindElement()"
        >
          <el-option
            v-for="item in blog.blog_json_nav"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <img
          id="selectElement"
          @click="showSelect = !showSelect"
          src="@/assets/icon/nav.png"
        />
      </div>
      <!--分划线-->
      <hr class="hr0" />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { MSG } from "../../../../store/msg";
export default {
  name: "blogBody",
  props: ["blog", "commentsNum"],
  data() {
    return {
      value: "",
      distanceToTop: null,
      showSelect: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollx);
    window.addEventListener("click", (e) => {
      if (e.target.id != "selectElement") {
        this.showSelect = false;
      }
    });
  },
  methods: {
    /**
     * 前往登录/注册
     * @param command
     */
    handleCommand(command) {
      if (command === "toLogin") {
        this.toLoginPage();
      } else if (command === "toRegist") {
        this.toRegistPage();
      }
    },
    /**
     * 前往登陆页
     */
    toLoginPage() {
      this.$router.push({
        path: "/login",
        query: { route: this.$route.fullPath },
      });
    },
    /**
     * 前往注册页
     */
    toRegistPage() {
      this.$router.push({
        path: "/regist",
        query: { route: this.$route.fullPath },
      });
    },
    handleScrollx() {
      this.distanceToTop = window.pageYOffset;
      // console.log("滚动高度", window.pageYOffset);
      // console.log(
      //   "距离顶部高度",
      //   this.$refs.scroll.getBoundingClientRect().top
      // );
    },
    toTop() {
      $("html,body").animate({ scrollTop: 0 }, 200);
    },
    /**
     * 寻找元素
     */
    toFindElement() {
      let word = this.value;
      //局部定义搜索结果计数器
      var Count = 0;
      $("body")
        .find("xqs p,xqs h2,xqs h1,xqs h3,xqs h4")
        .each(function () {
          var text = $(this).text();
          //判断是否是空输入、且是否在文档中找到word
          if (word != "" && text.indexOf(word) >= 0) {
            //如果这是第一个搜索结果，就定位
            if (Count == 0) {
              $("html,body").stop(true);
              $("html,body").animate(
                { scrollTop: $(this).offset().top - 100 },
                200
              );
            }
            //定义两个变量
            let str = $(this).text();
            let temp = $(this).text();
            //首先将选中的元素获取，修改样式
            str = str
              .split(word)
              .join(
                '<span style="background-color:rgba(243, 164, 2,0.2);color:red;font-weight:bold">' +
                  word +
                  "</span>"
              );
            $(this).html(str);
            //然后设置定时器，600ms后恢复样式
            setTimeout(() => {
              $(this).html(temp);
            }, 600);

            //计数器+1
            Count++;
          }
        });
      if (Count == 0) {
        MSG("warning", "未找到结果");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/*删除博客详情边框*/
.ql-container.ql-snow {
  border: none;
}
/*删除博客详情内边距*/
.ql-editor {
  padding: 0;
}
/*富文本代码框样式*/
/deep/ .ql-editor {
  /*强制英文数字换行*/
  word-break: break-all;
}
/deep/ .ql-snow .ql-editor pre.ql-syntax {
  margin-left: 0;
  width: 100%;
  color: #606266;
  background: #f5f6f7;
  font-size: 1rem;
  position: relative;
  line-height: 1.4rem;
  overflow-y: hidden;
  overflow-x: auto;
  border-radius: 0.2rem;
}
/*代码框代码左侧缩进2rem，不随便换行，宽度100%*/
/deep/ .ql-snow .ql-editor pre {
  padding-left: 2rem;
  white-space: pre;
  width: 100%;
}
/*代码框生成序号*/
/deep/ .ql-snow .ql-editor pre.ql-syntax::before {
  content: "1\A 2\A 3\A 4\A 5\A 6\A 7\A 8\A 9\A 10\A 11\A 12\A 13\A 14\A 15\A 16\A 17\A 18\A 19\A 20\A 21\A 22\A 23\A 24\A 25\A 26\A 27\A 28\A 29\A 30\A 31\A 32\A 33\A 34\A 35\A 36\A 37\A 38\A 39\A 40\A 41\A 42\A 43\A 44\A 45\A 46\A 47\A 48\A 49\A 50\A 51\A 52\A 53\A 54\A 55\A 56\A 57\A 58\A 59\A 60\A 61\A 62\A 63\A 64\A 65\A 66\A 67\A 68\A 69\A 70\A 71\A 72\A 73\A 74\A 75\A 76\A 77\A 78\A 79\A 80\A 81\A 82\A 83\A 84\A 85\A 86\A 87\A 88\A 89\A 90\A 91\A 92\A 93\A 94\A 95\A 96\A 97\A 98\A 99\A ..";
  position: absolute;
  left: 0.2rem;
  color: #909399;
  font-size: 1rem;
  width: 1.2rem;
  text-align: center;
  line-height: 1.4rem;
  margin-top: -0.4rem;
  padding-top: 0.4rem;
  border-right: 1px solid #c0c4cc;
}
//快速搜索
.easy-search {
  position: fixed;
  z-index: 9999;
  bottom: 3rem;
  right: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  img {
    opacity: 0.5;
    height: 3rem;
    cursor: pointer;
    margin-left: 0.4rem;
    transition-duration: 0.2s;
  }
  img:hover {
    transform: scale(1.1);
    opacity: 1;
  }
}
//回到顶部
.to-top {
  position: fixed;
  z-index: 9999;
  bottom: 6.5rem;
  right: 3rem;
  img {
    opacity: 0.5;
    height: 3rem;
    cursor: pointer;
    transition-duration: 0.2s;
  }
  img:hover {
    transform: scale(1.1);
    opacity: 1;
  }
}
//内容结尾与评论区衔接区（评论数、登录状态以及横线）
.the_end {
  margin-top: 2rem;
  //数据区
  .comment_num {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1rem;
    color: #777777;
    div:nth-child(1) {
      span {
        color: rgba(21, 139, 241, 0.8);
        font-weight: bold;
        margin-right: 0.2rem;
      }
    }
  }
  //分划线
  .hr0 {
    margin-top: 0.5rem;
    margin-bottom: 0.8rem;
    border: 0;
    background-color: rgb(220, 223, 230);
    height: 1px;
  }
  @media screen and (max-width: 850px) {
    //快速搜索
    .easy-search {
      bottom: 2rem;
      img {
        height: 2.4rem;
      }
      /deep/ .el-select,
      /deep/.el-input__inner {
        height: 2.2rem;
      }
      /deep/ .el-input__icon {
        height: 2.4rem;
      }
      /deep/ .el-select-dropdown {
        box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
      }
    }
    //回到顶部
    .to-top {
      bottom: 4.8rem;
      img {
        height: 2.4rem;
      }
    }
  }
}
</style>