<template>
  <div class="MainComponent">
    <div class="blogNotice">
      <span>
        &ensp;<router-link :to="{ path: '/index' }">首页</router-link>
        &ensp;<i class="fa fa-angle-right"></i>&ensp;双人游戏-炸飞机
      </span>
      <span></span>
      <span @click="toBack()" style="font-weight: 500;cursor: pointer">返回&ensp;</span>
    </div>
    <hr style="border:0;background-color:#DCDFE6;height:1px;">
    <!--游戏模块-->
    <div v-show="!isLoading && isConnected">
      <div v-show="isOnline">
        <blow-up-plane-game-panel ref="child" @toBack="toBack" @sendMsg="sendText"></blow-up-plane-game-panel>
      </div>
      <div v-show="!isOnline">
        <el-empty :image-size="200">
          <template slot="description">
              <span style="color: #777777">
                连接成功，正在等待对方连接..
              </span>
          </template>
          <template slot="image"><img src="../../../../../assets/img/wait.png"/></template>
        </el-empty>
        <div class="exitWait"><button @click="toBack">退出等待</button></div>
      </div>
    </div>
    <div v-show="!isLoading && !isConnected">
      <el-empty :image-size="200">
        <template slot="description">
              <span style="color: #777777">
                连接失败，点击<span @click="$router.go(0)" style="color: #158bf1;cursor: pointer">刷新</span>
              </span>
        </template>
        <template slot="image"><img src="../../../../../assets/img/403.png"/></template>
      </el-empty>
    </div>
    <div v-show="isLoading">
      <el-empty description="正在连接服务器.." :image-size="200">
        <template slot="image"><img src="../../../../../assets/img/pic_wait.png"/></template>
      </el-empty>
    </div>
  </div>
</template>
<script>
import BlowUpPlaneGamePanel from "./blowUpPlaneGamePanel";
import {MSG} from "../../../../../store/msg";
export default {
  name: "blowUpPlanePart",
  components: {BlowUpPlaneGamePanel},
  data(){
    return{
      ws: null,
      count: 0,
      userId: this.$store.getters.id, // 当前用户ID
      username: this.$store.getters.name, // 当前用户昵称
      avatar: this.$store.getters.avatar, // 当前用户头像
      targetName:this.$route.query.targetName,//目标（靶向）用户
      isOnline:0,//对方是否在线（1在线，0离线）
      subscribe:0,//0无关系，1关注，2粉丝，3朋友
      isConnected:false,//您是否已连线
      isLoading:true,//是否加载
    }
  },
  created() {
  },
  mounted() {
    this.initWebSocket();
  },
  destroyed() {
    // 离开页面时关闭websocket连接
    this.ws.close();
    // 离开页面时删除选择状态
    this.$store.state.isHaveRound = null;
    this.$store.state.game_level = 0;
  },
  methods:{
    /**
     * 返回上一页
     */
    toBack(){
      // this.$refs.child.text(this.datas.handleCanvas)
      this.$router.go(-1);
      // this.sendText({type:-1})
      // this.ws.onclose(undefined);
    },
    // 进入页面创建websocket连接
    initWebSocket() {
      let _this = this;
      this.isLoading = true;
      //subscribe:3,//0无关系，1关注，2粉丝，3朋友
      this.subscribe = this.$route.query.subscribe;
      // 判断页面有没有存在websocket连接
      if (window.WebSocket) {
        // var serverHot =  window.location.hostname;
        //房间号码：计算方式，小id+小tel后三位+大id+大tel后三位

        //获取route的内容-生成两人的唯一房间号
        let user1 = [this.$route.query.handleId,this.$route.query.handlePhone];
        let user2 = [this.$route.query.targetId,this.$route.query.targetPhone];
        let str1 = user1[0] > user2[0] ? user2[0] : user1[0];
        let str2 = user1[0] > user2[0] ? user2[1] : user1[1];
        let str3 = user1[0] > user2[0] ? user1[0] : user2[0];
        let str4 = user1[0] > user2[0] ? user1[1] : user2[1];
        let sip = str1+str2+str3+str4;
        // 生成网络地址
        var url = 'ws://' + this.$store.state.ip + ':' + this.$store.state.port + '/gameCenter/blowUpPlane/' + sip + '/' + this.$route.query.handleId;
        this.userId = this.$route.query.handleId;
        let ws = new WebSocket(url);
        _this.ws = ws;
        ws.onopen = function(e) {
          console.log("服务器连接成功: " + url + '..'+e);
        };
        ws.onclose = function(e) {
          console.log("服务器连接关闭: " + url + '..'+e);
          console.error(e)
          _this.isConnected = false;
          _this.isLoading = false;
        };
        ws.onerror = function() {
          console.log("服务器连接出错: " + url);
          _this.isConnected = false;
          _this.isLoading = false;
        };
        ws.onmessage = function(e) {
          //接收服务器返回的数据
          let resData = JSON.parse(e.data)
          console.log(resData)
          _this.count = resData.count;
          _this.isConnected = true;
          _this.isLoading = false;
          //对方的在线状态-在线数量为2或者一个人在线而那个人就是对方的情况
          if(resData.count == 2 || _this.$store.state.user.uid != resData.userId){
            _this.isOnline = resData.isOnline;
          }
          //判断消息类别
          if (resData.msgType === 1){
            if (_this.$store.state.isHaveRound == null){
              if (parseInt(_this.userId) !== parseInt(resData.userId)){
                MSG('success','对方已连接，游戏开始！')
                let rand = Math.round(Math.random());
                let obj = {
                  type:10,
                  data:rand
                }
                _this.sendText(obj);
              }
            }else {
              if (parseInt(_this.userId) === parseInt(resData.userId)){
                // 如果是丢炸弹期间，则断开连接
                _this.ws.close();
                _this.$store.state.isHaveRound = null;
                _this.$store.state.game_level = 0;
                _this.$alert('你不能在游戏中刷新页面，游戏无法继续！', '数据异常', {
                  confirmButtonText: '离开游戏',
                  callback: action => {
                    if (action == 'confirm'){
                      _this.toBack();
                    }
                  }
                });
                // //如果是画布期间，正常提示：
                // if (_this.$store.state.game_level === 0){
                //   _this.$refs.child.isYourRound = _this.$store.state.isHaveRound;
                //   _this.$forceUpdate();
                //   _this.$alert('游戏中请勿随意刷新，在提交画布后刷新将导致游戏崩溃！', '画布数据丢失', {
                //     confirmButtonText: '我知道了',
                //     callback: action => {
                //       if (action == 'confirm'){
                //         return false;
                //       }
                //     }
                //   });
                // }else{
                //   //如果是丢炸弹期间，则断开连接
                //   _this.ws.close();
                //   this.$store.state.isHaveRound = null;
                //   this.$store.state.game_level = 0;
                //   _this.$alert('您在提交画布后进行了刷新，造成了您和对方的数据不同步，游戏无法继续', '数据异常', {
                //     confirmButtonText: '离开游戏',
                //     callback: action => {
                //       if (action == 'confirm'){
                //         _this.toBack();
                //       }
                //     }
                //   });
                // }
              }
            }
          }
          if (resData.msgType === 2){
            if (parseInt(_this.userId) !== parseInt(resData.userId)){
              _this.ws.close();
              _this.$store.state.isHaveRound = null;
              _this.$store.state.game_level = 0;
              _this.$alert('对方断开连接，本局失效', '连接中断', {
                confirmButtonText: '离开游戏',
                callback: action => {
                  if (action == 'confirm'){
                    _this.toBack();
                  }
                }
              });
            }
          }
          //对方没连接，不能继续
          if (_this.isOnline !== 1){
            return false;
          }
          //判断传回来的值
          //判断先手：在没有分配之前，分配后不再执行。0给小id，1给大id
          if (resData.data.type != null && resData.data.type === 10){
            if (_this.$store.state.isHaveRound == null){
              if (_this.$route.query.handleId > _this.$route.query.targetId){
                //您的id大
                if (resData.data.data === 1){
                  MSG('success','系统决定您的优先回合')
                  _this.$refs.child.isYourRound = true;
                  _this.$store.state.isHaveRound = true;
                }else{
                  MSG('success','对方优先')
                  _this.$refs.child.isYourRound = false;
                  _this.$store.state.isHaveRound = false;
                }
              }else{
                //您的id小
                if (resData.data.data === 0){
                  MSG('success','系统决定您的优先回合')
                  _this.$refs.child.isYourRound = true;
                  _this.$store.state.isHaveRound = true;
                }else{
                  MSG('success','对方优先')
                  _this.$refs.child.isYourRound = false;
                  _this.$store.state.isHaveRound = false;
                }
              }
            }else{
              _this.$refs.child.isYourRound = _this.$store.state.isHaveRound;
              _this.$forceUpdate();
            }
          }
          if (resData.data.type === 11){
            if (parseInt(_this.userId) !== parseInt(resData.userId)){
              //对方提醒您快点画了
              _this.$refs.child.getOrderQuickly();
            }else{
              //发送催画成功
              _this.$refs.child.getOrderQuicklyCallBack();
            }
          }
          //提交飞机画
          if (resData.data.type === 0){
            if (parseInt(_this.userId) !== parseInt(resData.userId)){
              //说明对方画好了，替换就行
              _this.$refs.child.enemyDrawComplete(resData.data.handleCanvas);
            }else{
              //您画好了
              _this.$refs.child.youDrawComplete(resData.data.handleCanvas);
            }
          }
          //投雷阶段
          if(resData.data.type === 1) {
            if (parseInt(_this.userId) !== parseInt(resData.userId)) {
              //说明对方回合
              _this.$refs.child.enemyRound(resData.data);
            } else {
              //您的回合
              _this.$refs.child.yourRound(resData.data);
            }
          }
          //结算阶段
          if(resData.data.type === 2) {
            if (parseInt(_this.userId) !== parseInt(resData.userId)) {
              //说明对方赢了
              _this.$refs.child.enemyWin(resData.data);
            } else {
              //您赢了
              _this.$refs.child.yourWin(resData.data);
            }
          }
        };
      }
    },
    // 发送聊天信息
    sendText(data) {
      let _this = this;
      let params = {
        userId: _this.userId,
        username: _this.username,
        avatar: _this.avatar,
        msg: _this.contentText,
        count: _this.count,
        //消息类型：0聊天信息，1通知类消息
        msgType:0,
        //具体消息类型：0正常文字，1图片音频等，2纯语音消息
        msgDetailType:0,
        data:data
      };
      _this.ws.send(JSON.stringify(params)); //调用WebSocket send()发送信息的方法
    },
  }
}
</script>
<style scoped>
/*整体样式*/
.MainComponent{
  min-height: 40rem;
  background: #FFFFFF;
  box-shadow: 0 0 0.5rem #cccccc;/*设置阴影*/
  border-radius: 0.4rem;/*定义圆角*/
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 0.8rem;
}
/*头部区域*/
.blogNotice{
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}
.blogNotice span:nth-child(1){
  min-Width: 2rem;
}
/*面包屑，索引*/
a:-webkit-any-link{
  font-weight: 900;
  text-decoration:none;
  color: #000000;
}
.blogNotice span:nth-child(2){
  flex: 1;
}
.blogNotice span:nth-child(3){
  min-Width: 2rem;
}
.blogNotice span:nth-child(3) span span{
  font-weight: 900;
  color: rgb(230, 162, 60);
}
.exitWait{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.exitWait button{
  width: 8rem;
  height: 2.3rem;
  background: #E6A23C;
  cursor: pointer;
  border: none;
  color: #FFFFFF;
  font-size: 1.1rem;
  border-radius: 10rem;
  letter-spacing: 0.2rem;
}


@media screen and (max-width: 600px){
  /*整体样式*/
  .MainComponent{
    min-height: 36rem;
    box-shadow: none;/*设置阴影*/
    padding: 1rem 0rem 0rem 0rem;
    margin-top: 0;
  }
  .MainComponent hr{
    display: none;
  }
  .blogNotice{
    display: none;
  }
}
</style>