<template>
  <div>
    <div class="tableInfo">
      <span>
        <p>{{liked}}</p>
        <p>获赞</p>
      </span>
      <span>
        <p>{{followNum}}</p>
        <p>关注</p>
      </span>
      <span>
        <p>{{fansNum}}</p>
        <p>粉丝</p>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'userCardCount',
  props:[
    "liked","fansNum","followNum"
  ],
  data() {
    return {
      isActivity:false
    };
  }
}
</script>

<style scoped>
*{
  width: 100%;
}
.tableInfo{
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
}
.tableInfo span{
  text-align: center;
}
.tableInfo span p:nth-child(1){
  font-size: 1.2rem;
}
.tableInfo span p:nth-child(2){
  font-size: 0.9rem;
  color: #777777;
}
@media screen and (max-width: 600px){
  .tableInfo{
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
  }
  .tableInfo span{
    text-align: center;
  }
  .tableInfo span p:nth-child(1){
    font-size: 1rem;
  }
  .tableInfo span p:nth-child(2){
    font-size: 0.7rem;
    color: #777777;
  }
}

</style>