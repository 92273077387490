<template>
  <div style="margin-top: 10px">
    <el-alert type="warning" :closable="false"
      ><B
        >2023年元旦前后，随着行程码、健康码的下线，中国疫情已基本开放🎉。本站将不再获取实时数据（最后更新于2023.2.22）</B
      ></el-alert
    >
    <div class="SarsInfo">
      <div class="total">
        <div>—— <B>2023.2.22日疫情数据</B> ——</div>
        <div class="one">
          <div class="a">
            <div>境外输入</div>
            <div>{{ chinaTotal.total.input }}</div>
            <div>
              较昨日<span>+{{ chinaTotal.today.input }}</span>
            </div>
          </div>
          <div class="b">
            <div>无症状者</div>
            <div>{{ chinaTotal.extData.noSymptom }}</div>
            <div>
              较昨日<span>+{{ chinaTotal.extData.incrNoSymptom }}</span>
            </div>
          </div>
          <div class="c">
            <div>现有确诊</div>
            <div>
              {{
                chinaTotal.total.confirm -
                chinaTotal.total.heal -
                chinaTotal.total.dead
              }}
            </div>
            <div>
              较昨日<span>+{{ chinaTotal.today.storeConfirm }}</span>
            </div>
          </div>
        </div>
        <div class="two">
          <div class="d">
            <div>累计确诊</div>
            <div>{{ chinaTotal.total.confirm }}</div>
            <div>
              较昨日<span>+{{ chinaTotal.today.confirm }}</span>
            </div>
          </div>
          <div class="e">
            <div>累计死亡</div>
            <div>{{ chinaTotal.total.dead }}</div>
            <div>
              较昨日<span>+{{ chinaTotal.today.dead }}</span>
            </div>
          </div>
          <div class="f">
            <div>累计治愈</div>
            <div>{{ chinaTotal.total.heal }}</div>
            <div>
              较昨日<span>+{{ chinaTotal.today.heal }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChinaAllCard",
  props: ["chinaTotal"],
}
</script>

<style lang="scss" scoped>
.SarsInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .total {
    width: 590px;
    height: 15rem;
    border-radius: 0.8rem;
    box-shadow: 0 0 0.5rem #cccccc; /*设置阴影*/
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .one,
    .two {
      height: 6rem;
      width: 88%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      .a,
      .b,
      .c,
      .d,
      .e,
      .f {
        height: 94%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div:nth-child(1) {
          font-weight: bold;
          font-size: 1.1rem;
        }
        div:nth-child(2) {
          font-weight: bold;
          font-size: 1.5rem;
          color: red;
        }
        div:nth-child(3) {
          font-size: 1rem;
          color: grey;
        }
      }
      .a {
        div:nth-child(2),
        div:nth-child(3) span {
          color: #ffa352;
        }
      }
      .b {
        div:nth-child(2),
        div:nth-child(3) span {
          color: #791618;
        }
      }
      .c {
        div:nth-child(2),
        div:nth-child(3) span {
          color: #e44a3d;
        }
      }
      .d {
        div:nth-child(2),
        div:nth-child(3) span {
          color: #a31d13;
        }
      }
      .e {
        div:nth-child(2),
        div:nth-child(3) span {
          color: #333333;
        }
      }
      .f {
        div:nth-child(2),
        div:nth-child(3) span {
          color: #34aa70;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .total {
    width: 98% !important;
    height: 10rem !important;
    .one,
    .two {
      height: 3rem !important;
      .a,
      .b,
      .c,
      .d,
      .e,
      .f {
        div:nth-child(1) {
          font-size: 0.8rem !important;
        }
        div:nth-child(2) {
          font-size: 1rem !important;
        }
        div:nth-child(3) {
          font-size: 0.6rem !important;
        }
      }
    }
  }
}
</style>
