<template>
  <div class="MainComponent">
    <!--页头-->
    <user-page-header
      :isCenter="isCenter"
      :user="user"
      @toBack="toBack"
    ></user-page-header>
    <!--资料卡片-->
    <user-card
      :isCenter="isCenter"
      :user="user"
      :liked="liked"
      :fansNum="fansNum"
      :followNum="followNum"
      @toStarUser="toStarUser"
      @toUnStarUser="toUnStarUser"
      @toReport="toReport"
      @showEdit="showEdit"
    />

    <!--待改造区-->
    <!--待改造区-->
    <!--待改造区-->
    <div class="mailConfig">
      <div>
        <!--编辑模块-PC-->
        <span class="otherPanel">
          <el-tabs v-model="activeName">
            <el-tab-pane name="first">
              <template slot="label" slot-scope="">
                {{ isCenter ? "博客(" + blogNums + ")" : "博客 " + blogNums }}
              </template>
              <user-info-component-second
                ref="blogDetails"
              ></user-info-component-second>
            </el-tab-pane>
            <el-tab-pane label="资料" name="second" v-if="isCenter">
              <user-info-component-first></user-info-component-first>
            </el-tab-pane>
            <el-tab-pane label="朋友" name="forth" v-if="isCenter">
              <user-info-component-forth></user-info-component-forth>
            </el-tab-pane>
            <el-tab-pane label="消息" name="third" v-if="isCenter">
              <template slot="label" slot-scope=""> 消息(0) </template>
              敬请期待..
            </el-tab-pane>
          </el-tabs>
        </span>
      </div>
    </div>
    <report-dialog ref="child"></report-dialog>
  </div>
</template>

<script>
import UserInfoComponentFirst from "./userInfoComponentFirst";
import UserInfoComponentSecond from "./userInfoComponentSecond";
import userInfoComponentForth from "./userInfoComponentForth";
import { MSG } from "../../../../store/msg";
import { clearToken, requestToken } from "../../../../store/request";
import ReportDialog from "../../blogDetailPanel/components/reportDialog";
import UserPageHeader from "./userPageHeader";
import UserCard from "./userCard";
export default {
  name: "userInfoComponent",
  components: {
    UserCard,
    UserPageHeader,
    ReportDialog,
    UserInfoComponentSecond,
    UserInfoComponentFirst,
    userInfoComponentForth,
  },
  data() {
    return {
      uId: null,
      user: {},
      isCenter: true,
      activeName: "first",
      blogNums: 0,
      defaultAvatar: require("../../../../assets/icon/user.png"),
      liked: 0,
      fansNum: 0,
      followNum: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.uId = this.$parent.uId;
      this.isCenter = this.$parent.isCenter;

      if (this.uId != 0) {
        this.getUserInfo(this.$store.state.user.uid, this.uId);
      }
    },
    /**
     * 返回上一页
     */
    toBack() {
      this.$router.go(-1);
    },
    toReport(commentId, commentUserName, content, type) {
      this.$refs.child.open(commentId, commentUserName, content, type);
    },
    /**
     * 展示编辑页
     */
    showEdit(command) {
      this.activeName = command;
    },
    /**
     * 关注用户
     * @param targetUid 目标用户id
     */
    toStarUser(targetUid) {
      requestToken({
        url: "/blogUser/toStarUser",
        method: "post",
        data: {
          handleId: this.$store.state.user.uid,
          targetId: targetUid,
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            MSG("success", "关注成功");
            this.user.follow = 1;
          } else {
            MSG("error", res.data.msg);
          }
        })
        .catch((err) => {
          MSG("error", "网络错误");
          console.log(err);
        });
    },
    /**
     * 取关用户
     * @param targetUid 目标用户id
     */
    toUnStarUser(targetUid) {
      requestToken({
        url: "/blogUser/toUnStarUser",
        method: "post",
        data: {
          handleId: this.$store.state.user.uid,
          targetId: targetUid,
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            MSG("success", "取关成功");
            this.user.follow = 0;
          } else {
            MSG("error", res.data.msg);
          }
        })
        .catch((err) => {
          MSG("error", "网络错误");
          console.log(err);
        });
    },
    subStr(str) {
      var len = 0;
      let index = 0;
      for (var i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 255) {
          len += 2;
        } else {
          len++;
        }
        index++;
        if (len == 7 || len == 8) {
          break;
        }
      }
      str = str.substring(0, index);
      return str + "...";
    },
    /**
     * 查看用户详情，目的是为了判断关系
     * fans follow res
     * 0      0  无关系
     * 1      1   好友
     * 0      1   关注
     * 1      0   好友
     * @param loginUserId 登陆者id
     * @param uId 目标用户id
     */
    getUserInfo(loginUserId, uId) {
      requestToken({
        url: "/blogUser/getUserInfoById",
        method: "post",
        data: {
          loginUserId: loginUserId,
          uId: uId,
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.user = res.data.data.userInfo;
            this.liked = res.data.data.userInfo.likedNum;
            this.fansNum = res.data.data.userInfo.fansNum;
            this.followNum = res.data.data.userInfo.followNum;
          } else {
            MSG("error", res.data.msg);
          }
        })
        .catch((err) => {
          MSG("error", "网络错误");
          console.log(err);
        });
    },

    uploadSuccess(res, f, fl) {
      if (res.stat == 200) {
        this.toUpdateAvatarSubmit(res.url);
      } else {
        MSG("error", "服务器异常，上传失败！");
        console.log(f + fl);
      }
    },
    uploadError(res, f, fl) {
      MSG("error", "图片异常，无法上传！");
      console.log(res + f + fl);
    },
    toChangePhone() {
      MSG("warning", "敬请期待");
    },
    toUpdateAvatarSubmit(url) {
      requestToken({
        url: "/blogUser/updateAvatar",
        method: "post",
        data: {
          url: url,
          uId: this.$store.state.user.uid,
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            MSG("success", "图片上传成功！");
            localStorage.setItem("user", JSON.stringify(res.data.data.user));
            this.$store.state.user = JSON.parse(localStorage.getItem("user"));
          } else {
            MSG("error", res.data.msg);
            if (res.data.code == 300) {
              clearToken();
            }
          }
        })
        .catch((err) => {
          MSG("error", "网络错误，无法上传！");
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
/*整体样式*/
.MainComponent {
  min-height: 40rem;
  background: #ffffff;
  box-shadow: 0 0 0.5rem #cccccc; /*设置阴影*/
  border-radius: 0.4rem; /*定义圆角*/
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 0.8rem;
}
p {
  margin-top: 0.5rem;
  text-align: center;
}
li {
  list-style-type: none;
}
.myTag {
  background-color: #ecf5ff;
  border-color: #d9ecff;
  padding: 0 0.4rem;
  font-size: 12px;
  color: #409eff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}
/*tab选项卡item内边距*/
/deep/ .el-tabs__item {
  padding: 0 10px;
}
/*头部区域*/
.blogNotice {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}
.blogNotice span:nth-child(1) {
  min-width: 2rem;
}
/*面包屑，索引*/
a:-webkit-any-link {
  font-weight: 900;
  text-decoration: none;
  color: #000000;
}
.blogNotice span:nth-child(2) {
  flex: 1;
}
.blogNotice span:nth-child(3) {
  min-width: 2rem;
}
.blogNotice span:nth-child(3) span span {
  font-weight: 900;
  color: rgb(230, 162, 60);
}

.mailConfig {
  margin-top: 1rem !important;
  width: 96%;
  margin: auto;
}
/*pc端个人信息*/
.baseInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
/*pc端个人信息-基本信息-头像块-头像*/
.mailConfig div:nth-child(1) span:nth-child(1) span:nth-child(1) {
  display: flex;
  flex-direction: column;
}
.mailConfig
  div:nth-child(1)
  span:nth-child(1)
  span:nth-child(1)
  span:nth-child(1)
  img {
  border-radius: 100%;
  width: 5rem;
}
.mailConfig
  div:nth-child(1)
  span:nth-child(1)
  span:nth-child(1)
  span:nth-child(2) {
  margin: 0;
  align-self: center;
  margin-top: 0.2rem;
}
/*pc端个人信息-基本信息-名片块*/
.mailConfig div:nth-child(1) span:nth-child(1) span:nth-child(2) {
  margin-left: 1rem;
}
</style>
<style>
@media screen and (max-width: 600px) {
  .otherPanelPE div:nth-child(1) {
    display: flex;
    flex-direction: row;
  }
  /*tab选择签*/
  .otherPanelPE div:nth-child(1) span {
    cursor: pointer;
    user-select: none;
    flex: 1;
    text-align: center;
    padding-bottom: 0.2rem;
    border-bottom: 0.1rem solid rgb(220, 223, 230);
  }
  .otherPanelPE div:nth-child(2) {
    margin-top: 1rem;
  }
  .baseInfo {
    margin-bottom: 0rem !important;
  }
  /*pc端个人信息*/
  .baseInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    align-items: center;
    margin-bottom: 1rem;
    height: 6rem;
    background: #f2f6fc;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
  }
  /*pc端个人信息-基本信息-头像块-头像*/
  .mailConfig div:nth-child(1) span:nth-child(1) span:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mailConfig
    div:nth-child(1)
    span:nth-child(1)
    span:nth-child(2)
    span:nth-child(2) {
    margin-left: 0rem !important;
  }
  .mailConfig
    div:nth-child(1)
    span:nth-child(1)
    span:nth-child(1)
    span:nth-child(1)
    img {
    width: 3rem !important;
    margin-left: 0.5rem;
    margin-top: 0.8rem;
  }
  .miniUserInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    justify-items: center !important;
    height: 6rem;
    width: 100%;
  }
  /*编辑按钮*/
  .editBtn {
    margin-left: 0.5rem;
    background: #009688;
    cursor: pointer;
    border: none;
    color: #ffffff;
    font-size: 0.7rem;
    border-radius: 4px;
    padding: 0.2rem 0.5rem;
    letter-spacing: 0.1rem;
  }
  .editBtn:hover {
    box-shadow: rgb(0, 150, 136) 0px 1px 4px 0px;
  }
  .cancelBtn {
    margin-left: 0.5rem;
    background: #f56c6c;
    cursor: pointer;
    border: none;
    color: #ffffff;
    font-size: 0.7rem;
    border-radius: 4px;
    padding: 0.2rem 0.5rem;
    letter-spacing: 0.1rem;
  }
  .cancelBtn:hover {
    box-shadow: rgb(245, 108, 108) 0px 1px 4px 0px;
  }
  /*换绑手机*/
  .updateTelBtn {
    background: #e6a23c;
    cursor: pointer;
    border: none;
    color: #ffffff;
    font-size: 0.7rem;
    border-radius: 4px;
    padding: 0.1rem 0.2rem;
    letter-spacing: 0.1rem;
  }
  .updateTelBtn:hover {
    box-shadow: #faecd8 0px 4px 8px 0px;
  }
  /*登出*/
  .logOutBtn {
    background: #f56c6c;
    cursor: pointer;
    border: none;
    color: #ffffff;
    border-radius: 4px;
    font-size: 0.7rem;
    padding: 0.1rem 0.2rem;
    letter-spacing: 0.1rem;
    margin-left: 0.4rem;
  }
  .logOutBtn:hover {
    box-shadow: #fde2e2 0px 4px 8px 0px;
  }
}
</style>