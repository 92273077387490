<template>
  <div class="MainComponent">
    <div class="blogNotice">
      <span>
        &ensp;<router-link :to="{ path: '/index' }">首页</router-link> &ensp;<i
          class="fa fa-angle-right"></i
        >&ensp;新冠数据（停止更新）
      </span>
      <span></span>
      <span @click="toBack()" style="font-weight: 500; cursor: pointer"
        >返回&ensp;</span
      >
    </div>
    <hr style="border: 0; background-color: #dcdfe6; height: 1px" />
    <china-all-card v-if="!isLoading" :chinaTotal="chinaTotal"></china-all-card>
    <area-all-card v-if="!isLoading" :areas="areas"></area-all-card>
    <p v-if="isLoading">
      <el-empty description="正在加载数据源" :image-size="200">
        <template slot="image"
          ><img src="../../../../assets/img/pic_wait.png"
        /></template>
      </el-empty>
    </p>
  </div>
</template>
<script>
// import { request } from "../../../../store/request"
// import { MSG } from "../../../../store/msg"
import jsonData from "./local_data.json"
import ChinaAllCard from "./ChinaAllCard"
import AreaAllCard from "./AreaAllCard"

export default {
  name: "sarsCovPart",
  components: { AreaAllCard, ChinaAllCard },
  data() {
    return {
      isLoading: false,
      areas: [], //中国地区
      total: [], //中国概览
      chinaTotal: {}, //全国疫情
      max: null, //中国疫情最大数
    }
  },
  created() {
    this.init()
  },
  methods: {
    /**
     * 返回上一页
     */
    toBack() {
      this.$router.go(-1)
    },
    init() {
      this.isLoading = true
      // 为热烈庆祝中国疫情大开放，本接口停止请求，取而代之是终止于2023.2.22请求到的数据，日后不再更新
      // request({
      //   url:'/public/sarsCov/getList',
      //   method:'get',
      // }).then((res) => {
      //   if (res.data.code === 200) {
      //     setTimeout(() => {
      //       this.isLoading = false;
      //     }, 500);
      //     this.areas = res.data.data.area;
      //     this.total = res.data.data.total;
      //     this.chinaTotal = res.data.data.chinaTotal;
      //     this.max = res.data.data.max;
      //   } else {
      //     MSG('error',res.data.msg);
      //   }
      // })
      // .catch((error) => {
      //   setTimeout(() => {
      //     this.isLoading = false;
      //   }, 500);
      //   MSG('error','网络错误，获取失败！');
      //   console.log("getSarsCovError：" + error)
      // })
      setTimeout(() => {
        this.isLoading = false
      }, 500)
      const data = jsonData
      this.areas = data.area
      this.total = data.total
      this.chinaTotal = data.chinaTotal
      this.max = data.max
    },
  },
}
</script>
<style scoped>
/*整体样式*/
.MainComponent {
  min-height: 40rem;
  background: #ffffff;
  box-shadow: 0 0 0.5rem #cccccc; /*设置阴影*/
  border-radius: 0.4rem; /*定义圆角*/
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 0.8rem;
}
/*头部区域*/
.blogNotice {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}
.blogNotice span:nth-child(1) {
  min-width: 2rem;
}
/*面包屑，索引*/
a:-webkit-any-link {
  font-weight: 900;
  text-decoration: none;
  color: #000000;
}
.blogNotice span:nth-child(2) {
  flex: 1;
}
.blogNotice span:nth-child(3) {
  min-width: 2rem;
}
.blogNotice span:nth-child(3) span span {
  font-weight: 900;
  color: rgb(230, 162, 60);
}
@media screen and (max-width: 600px) {
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 600px) {
}
</style>
