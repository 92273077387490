<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    title="博客内导航"
  >
    <el-alert type="warning" show-icon>
      <template #title>
        博客内导航适用于单篇博客内容较多，需要快速精确定位的博客。<br />
      </template>
    </el-alert>
    <el-table :data="navsTemp" style="width: 100%">
      <el-table-column prop="label" label="日期">
        <template #default="{ row }">
          <input v-model="row.label" placeholder="" />
        </template>
      </el-table-column>
      <el-table-column prop="value" label="姓名">
        <template #default="{ row }">
          <input v-model="row.value" placeholder="" />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100px">
        <template slot-scope="scope">
          <el-button type="text" @click="toEdit()">保存</el-button
          ><el-button type="text" @click="toDelete(scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <button class="add-btn" @click="toAddItem">新增</button>
    <el-alert type="success" show-icon>
      <template #title>
        添加后，博客详情右下角会有一个飞机图标，展开即可
      </template>
    </el-alert>
    <div class="box" v-if="isLoading">
      <div class="box-text">正在请求...<i class="el-icon-loading"></i></div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "NavEditor",
  props: ["navs", "dialogVisible", "isLoading"],
  data() {
    return {
      navsTemp: [],
    };
  },
  mounted() {
    this.copyData(0);
  },
  methods: {
    copyData(time) {
      setTimeout(() => {
        console.log("haha");
        this.navsTemp = JSON.parse(JSON.stringify(this.navs));
        this.$forceUpdate();
        var isShouldRetry =
          time < 10 && this.navsTemp.length == 0 && this.navs.length != 0;
        if (isShouldRetry) {
          this.copyData(time + 1);
        }
      }, 200);
    },
    handleClose() {
      this.$emit("close");
    },
    toAddItem() {
      let obj = {
        label: "",
        value: "",
      };
      this.navsTemp.push(obj);
    },
    toEdit() {
      this.$emit("update", this.navsTemp);
    },
    toDelete(index) {
      this.navsTemp.splice(index, 1);
      this.$emit("update", this.navsTemp);
    },
  },
};
</script>
<style lang="scss">
input {
  border: 0;
  border-bottom: 1px solid #158bf1;
  padding-bottom: 0.3rem;
  outline: none;
}
.add-btn {
  margin-top: 0.2rem;
  display: block;
  width: 100%;
  height: 2rem;
  margin-left: 0.2rem;
  outline: none;
  border: none;
  color: #fff;
  background: #158bf1;
  border-radius: 8px;
  letter-spacing: 0.5rem;
  cursor: pointer;
}
/*遮罩层*/
.box {
  position: absolute;
  z-index: 998;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  opacity: 0.8;
}
.box-text {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  font-weight: bold;
  color: #1e9fff;
  font-size: 1.2rem;
  text-align: center;
}
@media screen and (max-width: 1250px) {
  .el-dialog {
    width: 70% !important;
  }
}
@media screen and (max-width: 1050px) {
  .el-dialog {
    width: 70% !important;
  }
}
@media screen and (max-width: 850px) {
  .el-dialog {
    width: 90% !important;
  }
}
</style>

