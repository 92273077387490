import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "font-awesome/css/font-awesome.min.css";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import VueClipboard from "vue-clipboard2";
import store from "./store";
import { returnBack } from "./store/request";
import swal from "sweetalert2";
import jquery from "jquery";

Vue.swal = swal;
Vue.prototype.$swal = swal;
Vue.prototype.$ = jquery;
Vue.use(VueClipboard);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.use(ElementUI);
Vue.use(VueQuillEditor);

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.path.startsWith("/login") && store.state.token != null) {
    //如果已经登录了仍然前往登录页，强制返回
    returnBack();
  } else {
    next();
  }
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
