<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="60px" class="demo-ruleForm">
      <span class="myLabel">昵称</span>
      <el-form-item label="昵称" prop="uName">
        <el-input v-model="ruleForm.uName"></el-input>
      </el-form-item>
      <span class="myLabel">手机</span>
      <el-form-item label="手机" prop="uTel">
        <el-input v-model="ruleForm.uTel" disabled></el-input>
      </el-form-item>
      <span class="myLabel">生日</span>
      <el-form-item label="生日" prop="uBirth">
        <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.uBirth" style="width: 100%;"></el-date-picker>
      </el-form-item>
      <span class="myLabel">性别</span>
      <el-form-item label="性别" prop="uSex">
        <div style="display: flex!important;">
          <span @click="ruleForm.uSex = 1" :style="ruleForm.uSex === 1?'font-weight:bold;color:#409eff;cursor: pointer':'cursor: pointer;color:#777777'">男</span>
          <span style="cursor: default;user-select: none">&ensp;&ensp;&ensp;</span>
          <span @click="ruleForm.uSex = 0" :style="ruleForm.uSex === 0?'font-weight:bold;color:rgb(196, 70, 139);cursor: pointer':'cursor: pointer;color:#777777'">女</span>
        </div>
      </el-form-item>
      <div class="foot_btn">
        <el-button @click="submitForm('ruleForm')">确认编辑</el-button>
        <span></span>
        <el-button @click="resetForm('ruleForm')">重置初始</el-button>
      </div>
    </el-form>
    <button @click="$parent.$parent.$parent.$parent.$refs.topBar.toLogout();" class="logout_btn">退出登录</button>
  </div>
</template>

<script>
import {clearToken, requestToken} from "../../../../store/request";
import {MSG} from "../../../../store/msg";

export default {
  name: "userInfoComponentFirst",
  data() {
    return {
      ruleForm: {
        uId:null,
        uName: null,
        uTel: null,
        uBirth: null,
        uSex: null
      },
      rules: {
        uName: [
          {required: true, message: '请输入昵称', trigger: 'blur'},
        ],uBirth: [
          {required: true, message: '请选择日期', trigger: 'blur'},
        ],uSex: [
          {required: true, message: '请选择性别', trigger: 'blur'},
        ],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      let user = this.$store.state.user;
      console.log(user)
      this.ruleForm.uSex = user.usex;
      this.ruleForm.uTel = user.utel;
      this.ruleForm.uBirth = user.ubirthday;
      this.ruleForm.uId = user.uid;
      this.ruleForm.uName = user.uname;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.uBirth = new Date(this.ruleForm.uBirth).getTime();
          requestToken({
            url:'blogUser/toUpdateUserInfo',
            method:'post',
            data:{
              form:this.ruleForm
            }
          }).then((res)=>{
            if (res.data.code === 200){
              MSG('success','修改成功！');
              localStorage.setItem('user',JSON.stringify(res.data.data.user));
              this.$store.state.user = JSON.parse(localStorage.getItem('user'));
            }else{
              MSG('error',res.data.msg);
              if (res.data.code == 300){
                clearToken();
              }
            }
          }).catch((err)=>{
            MSG('error','网络错误，无法访问！');
            console.error(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style lang="scss" scoped>
.myLabel{
  display: none!important;
}
.isNoHidden1{
  display: none;
}
/deep/ .el-switch__label.is-active{
  color: rgb(64, 158, 255);
}
.foot_btn{
  display: flex;
  flex-direction: row;
  button:nth-child(1){
    flex: 1;
    background-image: linear-gradient(to right, #009688 , #00BF88, #009688);
    height: 1.8rem;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    padding: 0.2rem 0.4rem;
    cursor: pointer;
    background-size: 200%;
    transition: 0.5s;
  }
  button:nth-child(1):hover{
    background-position: right;
  }
  span:nth-child(2){
    width: 0.8rem;
  }
  button:nth-child(3){
    flex: 1;
    background-image: linear-gradient(to right, #DCDFE6 , #DCDFE6, #FFFFFF, #DCDFE6);
    height: 1.8rem;
    color: #000000;
    border: none;
    border-radius: 4px;
    padding: 0.2rem 0.4rem;
    cursor: pointer;
    background-size: 200%;
  }
}
.logout_btn {
  width: 100%;
  margin-top: 2rem;
  background-image: linear-gradient(to right, #F56C6C, #F56C6C, rgba(245, 108, 108,0.8), #F56C6C);
  height: 1.8rem;
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
  background-size: 200%;
  transition: 0.8s;
}
.logout_btn:hover{
  background-position: right;
}
@media screen and (max-width: 600px){
  /deep/ div{
    margin-top: 0px!important;
  }
  /deep/ span{
    border: 0px!important;
  }
  /deep/ .el-form{
    width: 100%!important;
  }
  .isHidden1{
    display: none!important;
  }
  .isNoHidden1{
    display: block!important;
  }
  /deep/ .el-button{
    padding: 6px 12px!important;
  }
}

/*极小适配*/
@media screen and (max-width: 350px){
  /deep/ .el-form{
    width: 14rem;
  }
}
</style>