import { render, staticRenderFns } from "./bottomIcpIndex.vue?vue&type=template&id=5906f42a&scoped=true"
import script from "./bottomIcpIndex.vue?vue&type=script&lang=js"
export * from "./bottomIcpIndex.vue?vue&type=script&lang=js"
import style0 from "./bottomIcpIndex.vue?vue&type=style&index=0&id=5906f42a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5906f42a",
  null
  
)

export default component.exports