<template>
  <div class="user_card_body_head_info">
    <div class="user_card_body_head_info_top">
      <!--第一行，姓名、性别、和登录用户的关系、状态或举报按钮-->
      <div class="user_card_body_head_info_left">
        <!--昵称-->
        {{isCenter?
          (($store.state.user.uname!=='' && $store.state.user.uname!=null)?$store.state.user.uname:'用户***'+$store.state.user.utel.substring($store.state.user.utel.length-4)):
          ((user.uname!=='' && user.uname!=null)?user.uname:'用户***'+user.utel.substring(user.utel.length-4))}}
        <!--性别-->
        <i v-if="isCenter?$store.state.user.usex === 1:user.usex === 1" style="color: #158bf1" class="fa fa-mars"></i>
        <i v-else-if="isCenter?$store.state.user.usex === 0:user.usex === 0" style="color: #c4468b" class="fa fa-venus"></i>
        <i v-else class="fa fa-genderless"></i>
        <!--很你（登录用户）关系-->
        <button v-if="!isCenter && user.fans === 1 && user.follow === 0" style="margin-left: 0.5rem" class="myTag">粉丝</button>
        <button v-if="!isCenter && user.fans === 0 && user.follow === 1" style="margin-left: 0.5rem" class="myTag">关注</button>
        <button v-if="!isCenter && user.fans === 1 && user.follow === 1" style="margin-left: 0.5rem" class="myTag">朋友</button>
      </div>
      <!--状态或举报按钮-->
      <div class="user_card_body_head_info_right">
        <button v-if="isCenter" @click="$emit('setStatus')"><i class="iconfont">&#xeb4c;</i>无状态</button>
        <button v-else @click="$emit('toReport',user.uid,user.uname,user.utel,0)"><i class="el-icon-warning"></i>举报</button>
      </div>
    </div>
    <!--第二行，生肖、星座、年龄-->
    <div class="user_card_body_head_info_bottom">
      <span style="display: block" v-if="user.ubirthday!=null && user.ubirthday!== ''">
        {{getZodiacByBirthday(user.ubirthday)}}&ensp;{{getConstellationByBirthday(user.ubirthday)}}&ensp;{{getAgeByBirthday(user.ubirthday)}}岁
      </span>
      <span style="display: block" v-else>
        未设置生日
      </span>
    </div>
  </div>
</template>

<script>
import moment from "_moment@2.29.1@moment";

export default {
  name: "userCardInfo",
  props: ['user','isCenter'],
  methods:{
    /**
     * 根据生日计算年龄（周岁）
     * @param dateStr 日期
     * @returns {number}
     */
    getAgeByBirthday(dateStr){
      let pattern = 'YYYY-MM-DD';
      let birthday = moment(dateStr).format(pattern);
      let birthdayTime = Date.parse(new Date(birthday));
      let d = new Date(birthdayTime);
      let age =
          new Date().getFullYear() -
          d.getFullYear() -
          (new Date().getMonth() < d.getMonth() ||
          (new Date().getMonth() === d.getMonth() &&
              new Date().getDate() < d.getDate())
              ? 1
              : 0);
      return age;
    },

    /**
     * 根据生日获取星座
     * @param dateStr
     * @returns {string}
     */
    getConstellationByBirthday(dateStr){
      let pattern = 'YYYY-MM-DD';
      let birthday = moment(dateStr).format(pattern);
      let birthdayTime = Date.parse(new Date(birthday));
      let d = new Date(birthdayTime);
      let month = d.getMonth() + 1;
      let day = d.getDay();
      var s="魔羯水瓶双鱼牡羊金牛双子巨蟹狮子处女天秤天蝎射手魔羯";
      var arr=[20,19,21,21,21,22,23,23,23,23,22,22];
      return s.substr(month*2-(day<arr[month-1]?2:0),2) + '座';
    },

    /**
     * 根据生日获取生肖
     * @param dateStr
     * @returns {string}
     */
    getZodiacByBirthday(dateStr){
      let pattern = 'YYYY';
      let birthYear = moment(dateStr).format(pattern);
      let s = "猴鸡狗猪鼠牛虎兔龙蛇马羊";
      return '属'+s.substr(birthYear%12,1);
    }
  }
}
</script>

<style lang="scss" scoped>
  //整体除头像外的上方展示区
  .user_card_body_head_info{
    display: flex;
    height: 4rem;
    flex-direction: column;
    justify-content: center;
    line-height: 1.8rem;
    //第一行：姓名、性别、与登录用户的关系、举报or状态按钮
    .user_card_body_head_info_top{
      position: relative;
      //第一行加粗（特指昵称）
      .user_card_body_head_info_left{
        font-weight: 900;
      }
      //第一行右侧采用绝对布局，悬浮的按钮（举报or状态）
      .user_card_body_head_info_right{
        position: absolute;
        top: 0;
        right: 0;
      }
      //悬浮按钮（举报or状态）的样式
      .user_card_body_head_info_right button{
        border: none;
        border-radius: 10rem;
        padding: 0.2rem 0.8rem 0.2rem 0.8rem;
        background-image: linear-gradient(to right, #DCDFE6 , #DCDFE6 , #FFFFFF, #DCDFE6);
        background-size:400%;
        transition: 0.8s;
        color: #909399;
        cursor: pointer;
        font-size:0.9rem;
        //悬浮按钮（举报or状态）里的 i 标签
        i{
          font-size:0.9rem;
          margin-right: 0.2rem;
        }
      }
      //悬浮按钮（举报or状态）鼠标放上去后的样式
      .user_card_body_head_info_right button:hover{
        background-position: right;
      }
    }
    //第二行：生肖、星座、年龄
    .user_card_body_head_info_bottom{
      font-size: 0.9rem;
    }
  }
  //通用的小卡片，用来封装展示 '用户和登录用户的关系' 和 '年龄'
  .myTag{
    background-color: #ecf5ff;
    border-color: #d9ecff;
    padding: 0 0.4rem;
    font-size: 12px;
    color: #409EFF;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
  }
  @media screen and (max-width: 600px){
    //整体除头像外的上方展示区
    .user_card_body_head_info{
      height: 3rem;
      line-height: 1.4rem;
      //第一行：姓名、性别、与登录用户的关系、举报or状态按钮
      .user_card_body_head_info_top{
        //第一行布局
        .user_card_body_head_info_left{
          display: flex;
          flex-direction: row;
          align-items: center;
          i{
            font-size: 0.8rem;
            margin-left: 0.2rem;
          }
        }
        //悬浮按钮（举报or状态）的样式
        .user_card_body_head_info_right button{
          padding: 0.14rem 0.5rem 0.1rem 0.5rem;
          font-size: 0.8rem;
          i{
            font-size: 0.8rem;
          }
        }
        //悬浮按钮（举报or状态）鼠标悬浮上面时的样式
        .user_card_body_head_info_right button:hover{
          background-position: right;
        }
      }
      //第二行：生肖、星座、年龄
      .user_card_body_head_info_bottom{
        font-size: 0.8rem;
      }
    }
    //通用的小卡片，用来封装展示 '用户和登录用户的关系' 和 '年龄'
    .myTag{
      font-size: 0.6rem;
    }
  }
</style>