<template>
  <div class="main_body">
    <div class="box" id="box" v-show="isDoing"><span class="box-text">正在请求..<i class="el-icon-loading"></i></span></div>
    <!--您的飞机面板-->
    <div class="your_panel">
      <!--工具展示栏-->
      <div class="your_tool_bar" v-show="pencil<=2">
        <div @click="pencil = 0" v-show="pencil<=2">
          <i style="color: #009688;" class="iconfont">&#xe6aa;</i>
          <div v-show="pencil===0"><i class="fa fa-check completeIcon"></i></div>
        </div>
        <div @click="pencil = 1" v-show="pencil<=2">
          <i style="color: #F56C6C;" class="iconfont">&#xe8e3;</i>
          <div v-show="pencil===1"><i class="fa fa-check completeIcon"></i></div>
        </div>
        <div @click="pencil = 2" v-show="pencil<=2">
          <i style="color: #606266;" class="iconfont">&#xe60d;</i>
          <div v-show="pencil===2"><i class="fa fa-check completeIcon"></i></div>
        </div>
        <div style="margin-left: 1rem" @click="completeDraw()" v-show="pencil<=2"> <i style="color: #009688;font-size: 1.3rem" class="iconfont">&#xe61b;</i></div>
        <div v-show="pencil<=2" :style="isYourRound?'font-size: 0.8rem;color:#008000;cursor:auto;margin-left:0.5rem':'font-size: 0.8rem;color:#fc5531;cursor:auto;margin-left:0.5rem'">
          {{isYourRound?'您优先':'对方优先'}}
        </div>
      </div>
      <!--飞机格子-->
      <div class="your_block">
        <div>
          <div v-for="(i,index) in game_range_y+1" :key="index">{{index===0?'':index}}</div>
        </div>
        <div v-for="(your_canvas_x,index) in your_canvas" :key="index">
          <div>{{index+1 | getChar}}</div>
          <div v-for="(your_canvas_y,index2) in your_canvas_x" :key="index2">
            <div @click="pencil<=2?drawDot(index,index2):''">
              <template v-if="your_canvas_y.isShow === 0">
                <span v-if="your_canvas_y.position === 0" style="background: #FFFFFF"></span>
                <span v-if="your_canvas_y.position === 1" style="background: #009688"><i style="color: #FFFFFF;" class="iconfont">&#xe6aa;</i></span>
                <span v-if="your_canvas_y.position === 2" style="background: #F56C6C"><i style="color: #FFFFFF;" class="iconfont">&#xe8e3;</i></span>
              </template>
              <template v-if="your_canvas_y.isShow === 1">
                <span v-if="your_canvas_y.position === 0" style="background: #FFFFFF;" :class="enemyLastBoomPosition.x===index&&enemyLastBoomPosition.y===index2?'enemyLastBoom':''">
                  <i style="color: red" class="iconfont">&#xe658;</i>
                </span>
                <span v-if="your_canvas_y.position === 1" style="background: rgba(0, 150, 136,0.3)" :class="enemyLastBoomPosition.x===index&&enemyLastBoomPosition.y===index2?'enemyLastBoom':''">
                  <i style="color: rgb(254, 130, 82);font-size: 1.6rem" class="iconfont">&#xe610;</i>
                </span>
                <span v-if="your_canvas_y.position === 2" style="background: rgba(245, 108, 108,0.5)" :class="enemyLastBoomPosition.x===index&&enemyLastBoomPosition.y===index2?'enemyLastBoom':''">
                  <i style="color: rgb(255, 43, 63);font-size: 1.6rem" class="iconfont">&#xe610;</i>
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--对方的飞机面板-->
    <div class="enemy_panel">
      <!--工具展示栏-->
      <div class="enemy_tool_bar">
        <div @click="pencil = 3" v-show="pencil>=3 && pencil <= 4">
          <i style="color: black;" class="iconfont">&#xe62d;</i>
          <div v-show="pencil===3"><i class="fa fa-check completeIcon"></i></div>
        </div>
        <div @click="pencil = 4" v-show="pencil>=3 && pencil <= 4">
          <i style="color: #F56C6C;" class="iconfont">&#xe63a;</i>
          <div v-show="pencil===4"><i class="fa fa-check completeIcon"></i></div>
        </div>
        <div v-show="pencil>=3" :style="isYourRound?'font-size: 0.8rem;color:#008000;cursor:auto':'font-size: 0.8rem;color:#fc5531;cursor:auto'">
          {{isYourRound?'您的回合':'对方回合'}}
        </div>
        <div v-show="pencil>=3" style="font-size: 0.8rem;flex: 1">
          — <span :style="second>10?'':'color:red'">{{second}}</span> —
        </div>
        <div v-show="pencil>=3" style="font-size: 0.8rem;display:flex;flex-direction: row-reverse;align-items: center">
          <span v-show="isEnemyComplete">比分 {{yourScore.kill}}:{{enemyScore.kill}}</span>
          <span v-show="!isEnemyComplete" @click="orderQuickly" style="color: #e6a23c;">对方作画中</span>
          <i v-show="!isEnemyComplete" @click="orderQuickly" style="margin-left: 0.2rem;color: #e6a23c;font-size: 1.4rem" class="iconfont">&#xe632;</i>
        </div>
      </div>
      <!--飞机格子-->
      <div class="enemy_block">
        <div>
          <div v-for="(i,index) in game_range_y+1" :key="index">{{index===0?'':index}}</div>
        </div>
        <div v-for="(enemy_canvas_x,index) in enemy_canvas" :key="index">
          <div>{{index+1 | getChar}}</div>
          <div v-for="(enemy_canvas_y,index2) in enemy_canvas_x" :key="index2">
            <div @click="pencil>=3?pointDot(index,index2):''">
              <!--隐藏块-->
              <template v-if="enemy_canvas_y.isShow === 0">
                <!--未标旗帜-->
                <span v-if="enemy_canvas_y.isFlag === 0" style="background: #C0C4CC"></span>
                <!--标记旗帜-->
                <span v-if="enemy_canvas_y.isFlag === 1" style="background: #C0C4CC"><i style="color: #F56C6C" class="el-icon-s-flag"></i></span>
              </template>
              <!--显示块-->
              <template v-if="enemy_canvas_y.isShow === 1">
                <!--空白-->
                <span v-if="enemy_canvas_y.position === 0" style="background: #FFFFFF" :class="yourLastBoomPosition.x===index&&yourLastBoomPosition.y===index2?'yourLastBoom':''"></span>
                <!--机身-->
                <span v-if="enemy_canvas_y.position === 1" style="background: rgba(0, 150, 136,0.3)" :class="yourLastBoomPosition.x===index&&yourLastBoomPosition.y===index2?'yourLastBoom':''">
                  <i style="color: rgb(254, 130, 82);font-size: 1.6rem" class="iconfont">&#xe610;</i>
                </span>
                <!--机头-->
                <span v-if="enemy_canvas_y.position === 2" style="background: rgba(245, 108, 108,0.5)" :class="yourLastBoomPosition.x===index&&yourLastBoomPosition.y===index2?'yourLastBoom':''">
                  <i style="color: rgb(255, 43, 63);font-size: 1.6rem" class="iconfont">&#xe610;</i>
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MSG} from "../../../../../store/msg";
import Vue from "_vue@2.6.14@vue";
Vue.filter('getChar', function (value) {
  let str = 'ABCDEFGHIJ';
  return str.substr(value-1,1)
});
export default {
  name: "blowUpPlaneGamePanel",
  data(){
    return{
      game_range_x:10,
      game_range_y:10,
      your_canvas:'',
      enemy_canvas:'',
      pencil:0,
      isDoing:false,
      isEnemyComplete:false,
      isYouComplete:false,
      yourScore:{
        kill:0,
        hurt:0,
        empty:0,
      },//击毁数-击中数-落空数
      enemyScore:{
        kill:0,
        hurt:0,
        empty:0,
      },//击毁数-击中数-落空数
      isYourRound:null,//null的意义在于，最开始是否分配了true或false
      //你最后一次击中位置
      yourLastBoomPosition:{
        x:null,
        y:null
      },
      //敌方最后一次击中位置
      enemyLastBoomPosition:{
        x:null,
        y:null
      },
      //剩余操作时间
      second:30,
      //定时器
      timer1:null,
    }
  },
  mounted() {
    this.init();
  },
  methods:{
    init(){
      let obj = {
        position:0,
        isShow:0,
        isFlag:0,
        face:0,
      }
      this.your_canvas = new Array();
      this.enemy_canvas = new Array();
      for(let i = 0 ; i < this.game_range_x ; i++){
        this.your_canvas[i] = new Array();
        this.enemy_canvas[i] = new Array();
        for(let j = 0 ; j < this.game_range_y ; j++){
          this.your_canvas[i][j] = obj;
          this.enemy_canvas[i][j] = obj;
        }
      }
      this.$forceUpdate();
    },
    drawDot(x,y){
      //将目标块画相应画笔
      let obj = JSON.parse(JSON.stringify(this.your_canvas[x][y]));
      obj.position = this.pencil===0?1:this.pencil===1?2:this.pencil===2?0:0;
      this.your_canvas[x][y] = obj;
      this.$forceUpdate();
    },
    completeDraw(){
      this.isDoing = true
      let positions = []
      //收集所有的机头，查询所有的机身
      let bodys = 0
      for(let i = 0 ; i < this.game_range_x ; i++){
        for(let j = 0 ; j < this.game_range_y ; j++){
          if (this.your_canvas[i][j].position === 2){
            let obj_temp = {x:i,y:j};
            positions.push(obj_temp);
          }
          if (this.your_canvas[i][j].position === 1){
            bodys++;
          }
        }
      }
      if (positions.length !== 3){
        //没画完，机头不满足三个
        this.$swal.fire({
          title: '<strong style="color: #777777">画作不完整</strong>',
          icon: 'warning',
          html:
              '必须画完三架飞机</br>' +
              '<div style="color: #ff6b13;margin-top: 0.2rem">机头数 '+positions.length+'/3</div>',
          showCloseButton: true,
          focusConfirm: true,
          confirmButtonText:
              '<i class="iconfont">&#xe630;</i> 继续修改',
          confirmButtonColor: '#158bf0',
          showCancelButton:false,
        })
        this.isDoing = false;
        return false;
      }
      if (bodys!==27){
        //机身数量不对
        let text = bodys<27?'机身数量不足，请查看完整性':'机身数量冗余，请擦除余项';
        this.$swal.fire({
          title: '<strong style="color: #777777">画作不完整</strong>',
          icon: 'warning',
          html:
              text+'</br>' +
              '<div style="color: #ff6b13;margin-top: 0.2rem">机身数 '+bodys+'/27</div>',
          showCloseButton: true,
          focusConfirm: true,
          confirmButtonText:
              '<i class="iconfont">&#xe630;</i> 继续修改',
          confirmButtonColor: '#158bf0',
          showCancelButton:false,
        })
        this.isDoing = false;
        return false;
      }
      //判断每架飞机的合法性
      for (let i = 0; i < positions.length; i++) {
        let x = positions[i].x;
        let y = positions[i].y;
        let bodyNum = 0;
        let face = 0;//1上2右3下4左
        /**
         * 算法：判断机翼在哪一侧，若四方机身都小于9，说明飞机缺少
         * try_catch是为了防止溢出
         */
        //机头朝上
        try{
          //飞机头朝上
          this.your_canvas[x+1][y].position === 1?bodyNum++:'';
          this.your_canvas[x+2][y].position === 1?bodyNum++:'';
          this.your_canvas[x+3][y].position === 1?bodyNum++:'';
          this.your_canvas[x+3][y+1].position === 1?bodyNum++:'';
          this.your_canvas[x+3][y-1].position === 1?bodyNum++:'';
          this.your_canvas[x+1][y-1].position === 1?bodyNum++:'';
          this.your_canvas[x+1][y-2].position === 1?bodyNum++:'';
          this.your_canvas[x+1][y+1].position === 1?bodyNum++:'';
          this.your_canvas[x+1][y+2].position === 1?bodyNum++:'';
        }catch (e){
          bodyNum = 0;
        }
        if (bodyNum < 9){
          //机头朝下
          bodyNum = 0;
          try{
            this.your_canvas[x-1][y].position === 1?bodyNum++:'';
            this.your_canvas[x-2][y].position === 1?bodyNum++:'';
            this.your_canvas[x-3][y].position === 1?bodyNum++:'';
            this.your_canvas[x-3][y+1].position === 1?bodyNum++:'';
            this.your_canvas[x-3][y-1].position === 1?bodyNum++:'';
            this.your_canvas[x-1][y-1].position === 1?bodyNum++:'';
            this.your_canvas[x-1][y-2].position === 1?bodyNum++:'';
            this.your_canvas[x-1][y+1].position === 1?bodyNum++:'';
            this.your_canvas[x-1][y+2].position === 1?bodyNum++:'';
          }catch (e){
            bodyNum = 0;
          }
          if (bodyNum < 9){
            //机头朝右
            bodyNum = 0;
            try{
              this.your_canvas[x-1][y-3].position === 1?bodyNum++:'';
              this.your_canvas[x+1][y-3].position === 1?bodyNum++:'';
              this.your_canvas[x][y-3].position === 1?bodyNum++:'';
              this.your_canvas[x][y-2].position === 1?bodyNum++:'';
              this.your_canvas[x][y-1].position === 1?bodyNum++:'';
              this.your_canvas[x-1][y-1].position === 1?bodyNum++:'';
              this.your_canvas[x-2][y-1].position === 1?bodyNum++:'';
              this.your_canvas[x+1][y-1].position === 1?bodyNum++:'';
              this.your_canvas[x+2][y-1].position === 1?bodyNum++:'';
            }catch (e){
              bodyNum = 0;
            }
            if (bodyNum < 9){
              //机头朝左
              bodyNum = 0;
              try{
                this.your_canvas[x-1][y+3].position === 1?bodyNum++:'';
                this.your_canvas[x+1][y+3].position === 1?bodyNum++:'';
                this.your_canvas[x][y+3].position === 1?bodyNum++:'';
                this.your_canvas[x][y+2].position === 1?bodyNum++:'';
                this.your_canvas[x][y+1].position === 1?bodyNum++:'';
                this.your_canvas[x-1][y+1].position === 1?bodyNum++:'';
                this.your_canvas[x-2][y+1].position === 1?bodyNum++:'';
                this.your_canvas[x+1][y+1].position === 1?bodyNum++:'';
                this.your_canvas[x+2][y+1].position === 1?bodyNum++:'';
              }catch (e){
                bodyNum = 0;
              }
              if(bodyNum < 9){
                let text = '机头坐标为 '+'ABCDEFGHJ'.substr(x,1)+''+parseInt(y+1)+' 的飞机不完整'
                this.$swal.fire({
                  title: '<strong style="color: #777777">画布不符规则</strong>',
                  icon: 'warning',
                  html:
                      '<div style="color: #ff6b13;">'+text+'</div>' +
                      '<div style="margin-top: 0.2rem">请完善画作</div>',
                  showCloseButton: true,
                  focusConfirm: true,
                  confirmButtonText:
                      '<i class="iconfont">&#xe630;</i> 继续修改',
                  confirmButtonColor: '#158bf0',
                  showCancelButton:false,
                })
                this.isDoing = false;
                return false;
              }else{
                face = 4;
              }
            }else{
              face = 2;
            }
          }else{
            face = 3;
          }
        }else{
          face = 1;
        }
        /**
         * 再判断方向上的格子的isFlag，若0则改为1，若1则提示错误
         * 因为isFlag默认是0，且不会产生实际影响，因此可以temp，循环的最后再解除所有的isFlag
         */
        let repeatNum = 0;
        switch (face){
          case 1:
            //上
            this.your_canvas[x+1][y].isFlag === 0?this.putDataByTypeAndXY(1,1,x+1,y,1):repeatNum++;
            this.your_canvas[x+2][y].isFlag === 0?this.putDataByTypeAndXY(1,1,x+2,y,1):repeatNum++;
            this.your_canvas[x+3][y].isFlag === 0?this.putDataByTypeAndXY(1,1,x+3,y,1):repeatNum++;
            this.your_canvas[x+3][y+1].isFlag === 0?this.putDataByTypeAndXY(1,1,x+3,y+1,1):repeatNum++;
            this.your_canvas[x+3][y-1].isFlag === 0?this.putDataByTypeAndXY(1,1,x+3,y-1,1):repeatNum++;
            this.your_canvas[x+1][y-1].isFlag === 0?this.putDataByTypeAndXY(1,1,x+1,y-1,1):repeatNum++;
            this.your_canvas[x+1][y-2].isFlag === 0?this.putDataByTypeAndXY(1,1,x+1,y-2,1):repeatNum++;
            this.your_canvas[x+1][y+1].isFlag === 0?this.putDataByTypeAndXY(1,1,x+1,y+1,1):repeatNum++;
            this.your_canvas[x+1][y+2].isFlag === 0?this.putDataByTypeAndXY(1,1,x+1,y+2,1):repeatNum++;
            break;
          case 2:
            //右
            this.your_canvas[x-1][y-3].isFlag === 0?this.putDataByTypeAndXY(1,1,x-1,y-3,1):repeatNum++;
            this.your_canvas[x+1][y-3].isFlag === 0?this.putDataByTypeAndXY(1,1,x+1,y-3,1):repeatNum++;
            this.your_canvas[x][y-3].isFlag === 0?this.putDataByTypeAndXY(1,1,x,y-3,1):repeatNum++;
            this.your_canvas[x][y-2].isFlag === 0?this.putDataByTypeAndXY(1,1,x,y-2,1):repeatNum++;
            this.your_canvas[x][y-1].isFlag === 0?this.putDataByTypeAndXY(1,1,x,y-1,1):repeatNum++;
            this.your_canvas[x-1][y-1].isFlag === 0?this.putDataByTypeAndXY(1,1,x-1,y-1,1):repeatNum++;
            this.your_canvas[x-2][y-1].isFlag === 0?this.putDataByTypeAndXY(1,1,x-2,y-1,1):repeatNum++;
            this.your_canvas[x+1][y-1].isFlag === 0?this.putDataByTypeAndXY(1,1,x+1,y-1,1):repeatNum++;
            this.your_canvas[x+2][y-1].isFlag === 0?this.putDataByTypeAndXY(1,1,x+2,y-1,1):repeatNum++;
            break;
          case 3:
            //下
            this.your_canvas[x-1][y].isFlag === 0?this.putDataByTypeAndXY(1,1,x-1,y,1):repeatNum++;
            this.your_canvas[x-2][y].isFlag === 0?this.putDataByTypeAndXY(1,1,x-2,y,1):repeatNum++;
            this.your_canvas[x-3][y].isFlag === 0?this.putDataByTypeAndXY(1,1,x-3,y,1):repeatNum++;
            this.your_canvas[x-3][y+1].isFlag === 0?this.putDataByTypeAndXY(1,1,x-3,y+1,1):repeatNum++;
            this.your_canvas[x-3][y-1].isFlag === 0?this.putDataByTypeAndXY(1,1,x-3,y-1,1):repeatNum++;
            this.your_canvas[x-1][y-1].isFlag === 0?this.putDataByTypeAndXY(1,1,x-1,y-1,1):repeatNum++;
            this.your_canvas[x-1][y-2].isFlag === 0?this.putDataByTypeAndXY(1,1,x-1,y-2,1):repeatNum++;
            this.your_canvas[x-1][y+1].isFlag === 0?this.putDataByTypeAndXY(1,1,x-1,y+1,1):repeatNum++;
            this.your_canvas[x-1][y+2].isFlag === 0?this.putDataByTypeAndXY(1,1,x-1,y+2,1):repeatNum++;
            break;
          case 4:
            //左
            this.your_canvas[x-1][y+3].isFlag === 0?this.putDataByTypeAndXY(1,1,x-1,y+3,1):repeatNum++;
            this.your_canvas[x+1][y+3].isFlag === 0?this.putDataByTypeAndXY(1,1,x+1,y+3,1):repeatNum++;
            this.your_canvas[x-1][y+1].isFlag === 0?this.putDataByTypeAndXY(1,1,x-1,y+1,1):repeatNum++;
            this.your_canvas[x-2][y+1].isFlag === 0?this.putDataByTypeAndXY(1,1,x-2,y+1,1):repeatNum++;
            this.your_canvas[x+1][y+1].isFlag === 0?this.putDataByTypeAndXY(1,1,x+1,y+1,1):repeatNum++;
            this.your_canvas[x+2][y+1].isFlag === 0?this.putDataByTypeAndXY(1,1,x+2,y+1,1):repeatNum++;
            this.your_canvas[x][y+3].isFlag === 0?this.putDataByTypeAndXY(1,1,x,y+3,1):repeatNum++;
            this.your_canvas[x][y+2].isFlag === 0?this.putDataByTypeAndXY(1,1,x,y+2,1):repeatNum++;
            this.your_canvas[x][y+1].isFlag === 0?this.putDataByTypeAndXY(1,1,x,y+1,1):repeatNum++;
            break;
          default:
            return false;
        }
        if (repeatNum > 0){
          //当前飞机与已判断过的飞机有部件冲突（共用一处）
          //恢复数据
          for (let i = 0; i < this.game_range_x; i++) {
            for (let j = 0; j < this.game_range_y; j++) {
              let obj = JSON.parse(JSON.stringify(this.your_canvas[i][j]));
              if (obj.isFlag === 1){
                obj.isFlag = 0;
                this.your_canvas[i][j] = obj;
              }
            }
          }
          let text = '机头坐标为 '+'ABCDEFGHJ'.substr(x,1)+''+parseInt(y+1)+' 的飞机与其他飞机有'+repeatNum+'处重叠'
          this.$swal.fire({
            title: '<strong style="color: #777777">画布不符规则</strong>',
            icon: 'warning',
            html:
                '<div style="color: #ff6b13;">'+text+'</div>' +
                '<div style="margin-top: 0.2rem">请修改重叠处</div>',
            showCloseButton: true,
            focusConfirm: true,
            confirmButtonText:
                '<i class="iconfont">&#xe630;</i> 继续修改',
            confirmButtonColor: '#158bf0',
            showCancelButton:false,
          })
          this.isDoing = false;
          return false;
        }
        //一切无误，记录机头的位置
        this.putDataByTypeAndXY(1,2,x,y,face);
      }
      //发出确认
      this.$swal.fire({
        title: '<strong style="color: #87adbd">即将提交画布</strong>',
        icon: 'question',
        html:
            '<span style="color:#f8bb86;">提交后不可修改</span>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText:
            '<i class="iconfont">&#xe61b;</i> 确认提交',
        confirmButtonColor: '#158bf0',
        cancelButtonText:
            '<i class="iconfont">&#xe630;</i> 返回编辑'
      }).then((isConfirm) =>{
        try {
          if (isConfirm.value){
            //恢复数据
            for (let i = 0; i < this.game_range_x; i++) {
              for (let j = 0; j < this.game_range_y; j++) {
                let obj = JSON.parse(JSON.stringify(this.your_canvas[i][j]));
                if (obj.isFlag === 1){
                  obj.isFlag = 0;
                  this.your_canvas[i][j] = obj;
                }
              }
            }
            //整理和封装数据
            let obj = {
              type:0,//0：绘画阶段
              handleCanvas:this.your_canvas,
              targetCanvas:this.enemy_canvas
            }
            //发送数据
            this.$emit("sendMsg",obj);
          }else{
            //恢复数据
            for (let i = 0; i < this.game_range_x; i++) {
              for (let j = 0; j < this.game_range_y; j++) {
                let obj = JSON.parse(JSON.stringify(this.your_canvas[i][j]));
                if (obj.isFlag === 1){
                  obj.isFlag = 0;
                  this.your_canvas[i][j] = obj;
                }
              }
            }
            this.isDoing = false
            return false
          }
        }catch (e){
          console.log(e)
        }
      })
    },
    text(data){
      this.your_canvas = data;
      this.$forceUpdate();
    },
    randomPosition(){
      //结果
      let res = false;
      let x = 0;
      let y = 0;
      while (!res){
        //随机坐标
        x = Math.floor(Math.random()*10);
        y = Math.floor(Math.random()*10);
        //看这个位置的isShow是否是0
        if (this.enemy_canvas[x][y].isShow === 0){
          res = true;
        }
      }
      //将画笔赋予3
      this.pencil = 3;
      this.pointDot(x,y);
    },
    putDataByTypeAndXY(canvasType,type,x,y,data){
      //canvasType:1我方，2敌方
      //类型：1 isFlag， 2 face，3 position，4 is Show
      //data:修改的值
      if (canvasType === 1){
        //我方飞机
        if (type === 1){
          //修改flag
          let obj = JSON.parse(JSON.stringify(this.your_canvas[x][y]));
          obj.isFlag = data;
          this.your_canvas[x][y] = obj;
        }else if (type === 2){
          //修改face
          let obj = JSON.parse(JSON.stringify(this.your_canvas[x][y]));
          obj.face = data;
          this.your_canvas[x][y] = obj;
        }
      }else{
        //敌方飞机
        if (type === 4){
          //修改isShow
          let obj = JSON.parse(JSON.stringify(this.enemy_canvas[x][y]));
          obj.isShow = data;
          this.enemy_canvas[x][y] = obj;
        }
      }
    },
    enemyDrawComplete(data){
      //对方画完了，展示对方的棋盘
      this.enemy_canvas = data;
      MSG('success','Ta画完了')
      this.isEnemyComplete = true;
      //对面画完了，你也画完了，而且是你的回合
      if (this.isYouComplete && this.isYourRound){
        this.second = 30;
        this.timer1 = setInterval(()=>{
          if (this.second >= 1){
            if (this.second === 10){
              MSG('warning','还有'+this.second+'秒！');
            }
            this.second --;
          }else{
            this.randomPosition();
            clearInterval(this.timer1);
          }
        },1000);
      }
      this.$forceUpdate();
    },
    youDrawComplete(data){
      //笔刷变为炸弹
      this.pencil = 3;
      this.isYouComplete = true;
      //您画好了，赋值的意义防止在程序处理中用户意外改变形态
      this.your_canvas = data;
      this.isDoing = false;
      //持久化进入丢炸弹阶段
      this.$store.state.game_level = 1;
      //你画完了，对面也画完了，而且是你的回合
      if (this.isEnemyComplete && this.isYourRound){
        this.second = 30;
        this.timer1 = setInterval(()=>{
          if (this.second >= 1){
            if (this.second === 10){
              MSG('warning','还有'+this.second+'秒！');
            }
            this.second --;
          }else{
            this.randomPosition();
            clearInterval(this.timer1);
          }
        },1000);
      }
      this.$forceUpdate();
    },
    yourRound(data){
      clearInterval(this.timer1);
      this.second = 30;
      this.timer1 = setInterval(()=>{
        if (this.second >= 1){
          this.second --;
        }else{
          this.randomPosition();
          clearInterval(this.timer1);
        }
      },1000);
      this.yourLastBoomPosition = data.lastBoomPosition;
      this.isYourRound = false;
      this.isDoing = false;
      console.log(data)
    },
    enemyRound(data){
      MSG('success','到你了！')
      clearInterval(this.timer1);
      this.second = 30;
      this.timer1 = setInterval(()=>{
        if (this.second >= 1){
          if (this.second === 10){
            MSG('warning','还有'+this.second+'秒！');
          }
          this.second --;
        }else{
          this.randomPosition();
          clearInterval(this.timer1);
        }
      },1000);
      this.enemyLastBoomPosition = data.lastBoomPosition;
      this.your_canvas = data.targetCanvas;
      this.enemyScore = data.handleSCore;
      this.isYourRound = true;
    },
    enemyWin(data){
      this.your_canvas = data.targetCanvas;
      this.enemyScore = data.handleSCore;
      this.enemyLastBoomPosition = data.lastBoomPosition;
      this.$swal.fire({
        title: '<strong style="color: #777777">游戏结束</strong>',
        icon: 'none',
        html:
            '<i class="iconfont" style="font-size: 6rem;color: #b88e54">&#xe611;</i><br></br>' +
            'Ta把你所有的飞机都打烂了',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText:
            '<i class="iconfont">&#xeb6f;</i> 离开',
        confirmButtonColor: '#158bf0',
        cancelButtonText:
            '<i class="iconfont">&#xe608;</i> 再看看'
      }).then((isConfirm) =>{
        try {
          if (isConfirm.value){
            this.$emit("toBack");
          }else{
            this.pencil = -1;
            this.showAllEnemyOfPlanes();
          }
        }catch (e){
          console.log(e)
        }
      })
    },
    orderQuickly(){
      let obj = {
        type: 11
      }
      this.$emit("sendMsg",obj);
    },
    getOrderQuickly(){
      MSG('warning','Ta催你快点画');
    },
    getOrderQuicklyCallBack(){
      MSG('success','已发出催画通知');
    },
    yourWin(data){
      this.isDoing = false;
      this.yourLastBoomPosition = data.lastBoomPosition;
      this.$swal.fire({
        title: '<strong style="color: #41d1a0">你胜利了</strong>',
        icon: 'none',
        html:
            '<i class="iconfont" style="font-size: 6rem;color: #62ccb4">&#xe64f;</i><br></br>' +
            '你摧毁了Ta所有的飞机！',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText:
            '<i class="iconfont">&#xeb6f;</i> 离开',
        confirmButtonColor: '#158bf0',
        cancelButtonText:
            '<i class="iconfont">&#xe608;</i> 再看看'
      }).then((isConfirm) =>{
        try {
          if (isConfirm.value){
            this.$emit("toBack");
          }else{
            this.pencil = -1;
            this.showAllEnemyOfPlanes();
          }
        }catch (e){
          console.log(e)
        }
      })
    },
    pointDot(x,y){
      if (this.pencil === 3 && !this.isEnemyComplete){
        MSG('warning','对方尚未画完，请等待')
        return false;
      }
      if (this.pencil === 3 && !this.isYourRound){
        MSG('warning','对方回合，请耐心等待')
        return false;
      }
      this.isDoing = true;
      //获取目标块
      let obj = JSON.parse(JSON.stringify(this.enemy_canvas[x][y]));
      //将目标块点亮
      if (this.pencil === 3){
        if (obj.isShow === 1){
          //已经炸过了，不允许再炸
          MSG('warning','这个区域已经炸过了');
          this.isDoing = false;
          return false;
        }
        obj.isShow = 1;
      }else if(this.pencil === 4){
        obj.isFlag = obj.isFlag===0?1:0;
      }
      this.enemy_canvas[x][y] = obj;
      //判断是否是机头，若是，则点开所有机体
      if (this.pencil === 3){
        if(obj.position === 2){
          this.showOnePlane(x,y);
          //击杀数+1
          this.yourScore.kill ++;
        }else if (obj.position === 1){
          //击中数+1--有待考量
          this.yourScore.hurt ++;
        }else if (obj.position === 0){
          //空枪数+1
          this.yourScore.empty ++;
        }
      }
      this.$forceUpdate();
      //笔刷是3，说明是炸弹，即时反馈给对方和自己
      if (this.pencil === 3){
        let obj = {
          type: this.yourScore.kill<3?1:2,//1：提交阶段,2结算阶段
          handleSCore:this.yourScore,
          targetScore:this.enemyScore,
          handleCanvas:this.your_canvas,
          targetCanvas:this.enemy_canvas,
          //最后一次炸的位置
          lastBoomPosition:{
            x:x,
            y:y
          }
        }
        clearInterval(this.timer1);
        this.$emit("sendMsg",obj);
      }else if (this.pencil === 4){
        //笔刷是4，插旗
        this.isDoing = false
      }
    },
    showAllEnemyOfPlanes(){
      for (let i = 0; i < this.game_range_x; i++) {
        for (let j = 0; j < this.game_range_y; j++) {
          let obj = JSON.parse(JSON.stringify(this.enemy_canvas[i][j]));
          if (obj.position === 1 || obj.position === 2){
            obj.isShow = 1;
            this.enemy_canvas[i][j] = obj;
          }
        }
      }
      this.$forceUpdate();
    },
    showOnePlane(x,y){
      if (this.enemy_canvas[x][y].face === 1){
        //机头朝上
        let obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+1][y]));
        obj.isShow = 1;
        this.enemy_canvas[x+1][y] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+2][y]));
        obj.isShow = 1;
        this.enemy_canvas[x+2][y] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+3][y]));
        obj.isShow = 1;
        this.enemy_canvas[x+3][y] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+3][y+1]));
        obj.isShow = 1;
        this.enemy_canvas[x+3][y+1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+3][y-1]));
        obj.isShow = 1;
        this.enemy_canvas[x+3][y-1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+1][y-1]));
        obj.isShow = 1;
        this.enemy_canvas[x+1][y-1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+1][y-2]));
        obj.isShow = 1;
        this.enemy_canvas[x+1][y-2] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+1][y+1]));
        obj.isShow = 1;
        this.enemy_canvas[x+1][y+1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+1][y+2]));
        obj.isShow = 1;
        this.enemy_canvas[x+1][y+2] = obj;
        this.$forceUpdate();
      }else if(this.enemy_canvas[x][y].face === 2){
        //机头朝右
        let obj = JSON.parse(JSON.stringify(this.enemy_canvas[x][y-1]));
        obj.isShow = 1;
        this.enemy_canvas[x][y-1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x][y-2]));
        obj.isShow = 1;
        this.enemy_canvas[x][y-2] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x][y-3]));
        obj.isShow = 1;
        this.enemy_canvas[x][y-3] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-1][y-3]));
        obj.isShow = 1;
        this.enemy_canvas[x-1][y-3] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+1][y-3]));
        obj.isShow = 1;
        this.enemy_canvas[x+1][y-3] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-1][y-1]));
        obj.isShow = 1;
        this.enemy_canvas[x-1][y-1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-2][y-1]));
        obj.isShow = 1;
        this.enemy_canvas[x-2][y-1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+1][y-1]));
        obj.isShow = 1;
        this.enemy_canvas[x+1][y-1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+2][y-1]));
        obj.isShow = 1;
        this.enemy_canvas[x+2][y-1] = obj;
        this.$forceUpdate();
      }else if(this.enemy_canvas[x][y].face === 3){
        //机头朝下
        let obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-1][y]));
        obj.isShow = 1;
        this.enemy_canvas[x-1][y] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-2][y]));
        obj.isShow = 1;
        this.enemy_canvas[x-2][y] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-3][y]));
        obj.isShow = 1;
        this.enemy_canvas[x-3][y] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-3][y+1]));
        obj.isShow = 1;
        this.enemy_canvas[x-3][y+1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-3][y-1]));
        obj.isShow = 1;
        this.enemy_canvas[x-3][y-1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-1][y-1]));
        obj.isShow = 1;
        this.enemy_canvas[x-1][y-1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-1][y-2]));
        obj.isShow = 1;
        this.enemy_canvas[x-1][y-2] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-1][y+1]));
        obj.isShow = 1;
        this.enemy_canvas[x-1][y+1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-1][y+2]));
        obj.isShow = 1;
        this.enemy_canvas[x-1][y+2] = obj;
        this.$forceUpdate();
      }else if(this.enemy_canvas[x][y].face === 4){
        //机头朝左
        let obj = JSON.parse(JSON.stringify(this.enemy_canvas[x][y+1]));
        obj.isShow = 1;
        this.enemy_canvas[x][y+1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x][y+2]));
        obj.isShow = 1;
        this.enemy_canvas[x][y+2] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x][y+3]));
        obj.isShow = 1;
        this.enemy_canvas[x][y+3] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-1][y+3]));
        obj.isShow = 1;
        this.enemy_canvas[x-1][y+3] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+1][y+3]));
        obj.isShow = 1;
        this.enemy_canvas[x+1][y+3] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-1][y+1]));
        obj.isShow = 1;
        this.enemy_canvas[x-1][y+1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x-2][y+1]));
        obj.isShow = 1;
        this.enemy_canvas[x-2][y+1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+1][y+1]));
        obj.isShow = 1;
        this.enemy_canvas[x+1][y+1] = obj;
        obj = JSON.parse(JSON.stringify(this.enemy_canvas[x+2][y+1]));
        obj.isShow = 1;
        this.enemy_canvas[x+2][y+1] = obj;
        this.$forceUpdate();
      }

    }
  }
}
</script>

<style scoped>
/*遮罩层*/
.box{
  position:absolute;
  z-index: 998;
  top:0;
  bottom:-16rem;
  left:0;
  right:0;
  background:#FFFFFF;
  opacity:0.7;
}.box-text{
   position:absolute;
   top:35%;
   left:0;
   right:0;
   color: #1E9FFF;
   font-size: 1.2rem;
   text-align: center;
 }
</style>
<style lang="scss" scoped>
.main_body{
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  .your_panel{
    //飞机操作格子
    .your_tool_bar{
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.3rem;
      height: 1.8rem;
      div{
        cursor: pointer;
        min-width: 1.5rem;
        text-align: center;
        position: relative;
        div{
          position: absolute;
          z-index: 997;
          top: 0;
          left: 0.1rem;
          font-size: 1.6rem;
          color: blueviolet;
        }
      }
      div:nth-child(4){
        i{
          color: #009688;
          font-size: 1.4rem;
        }
      }
    }
    .your_block{
      //行
      div{
        display: flex;
        flex-direction: row;
        height: 1.8rem;
        //列（具体块）
        div{
          background: #EBEEF5;
          width: 1.8rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          div{
            width: 90%;
            height: 90%;
            cursor: pointer;
            span{
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .enemy_panel{
    .enemy_tool_bar{
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.3rem;
      height: 1.8rem;
      div{
        cursor: pointer;
        min-width: 1.5rem;
        text-align: center;
        position: relative;
        div{
          position: absolute;
          z-index: 997;
          top: 0;
          left: 0.1rem;
          font-size: 1.6rem;
          color: blueviolet;
        }
      }
      div:nth-child(4){
        i{
          color: #009688;
          font-size: 1.4rem;
        }
      }
    }
    //飞机操作格子
    .enemy_block{
      //行
      div{
        display: flex;
        flex-direction: row;
        height: 1.8rem;
        //列（具体块）
        div{
          background: #EBEEF5;
          width: 1.8rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          div{
            width: 90%;
            height: 90%;
            cursor: pointer;
            span{
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
.enemyLastBoom{
  border: 2px dashed red;
  margin-bottom: 0.05rem;
}
.yourLastBoom{
  border: 2px dashed red;
  margin-bottom: 0.05rem;
}
</style>
