<template>
  <div class="user_card_body">
    <!--头像、姓名、年龄、属相、星座、状态 / 举报-->
    <div class="user_card_body_head">
      <!--头像-->
      <div class="user_card_body_head_avatar" @click="toChangeAvatar()">
        <img :src="isCenter?
              ($store.state.user.uavatar!=''&&$store.state.user.uavatar!=null ? $store.state.user.uavatar:defaultAvatar):
              (user.uavatar!=''&&user.uavatar!=null ? user.uavatar:defaultAvatar)">
        <div><i class="fa fa-pencil-square-o"></i></div>
        <el-upload class="avatar_upload"
                   :headers="{'Authorization':this.$store.state.token}"
                   :action="'http://'+this.$store.state.ip+':'+this.$store.state.port+'/blogUser/toUploadAvatar'" name="file"
                   :show-file-list="false" :on-success='(res,file,fileList)=>uploadSuccess(res,file,fileList)'
                   :on-error='(res,file,fileList)=>uploadError(res,file,fileList)'
                   style="display:none"
                   accept='.jpg,.jpeg,.png,.gif'></el-upload>
      </div>
      <!--除头像外的内容区-->
      <div class="user_card_body_head_info">
        <user-card-info :isCenter="isCenter" :user="user" @toReport="toReport" @setStatus="setStatus"></user-card-info>
      </div>
    </div>
    <!--获赞、关注、粉丝-->
    <div>
      <user-card-count :liked="liked" :fansNum="fansNum" :followNum="followNum"></user-card-count>
    </div>
    <!--编辑资料、创作博客、关注、已关注、互相关注、私信..-->
    <div class="user_card_body_foot">
      <div v-if="isCenter" class="one">
        <button @click="$emit('showEdit','second')">编辑资料 {{ completeNum }}%</button>
        <p></p>
        <button @click="toNewBlog(0)"><i class="iconfont">&#xe62f;</i>创作博客</button>
      </div>
      <div v-else class="two">
        <button v-if="!isCenter && user.fans ===0 && user.follow === 0" @click="$emit('toStarUser',user.uid)">+关注</button>
        <button v-if="!isCenter && (user.fans ===1 && user.follow === 1)" @click="$emit('toUnStarUser',user.uid)" style="flex: 1;background-image: linear-gradient(to right, #dcdfe6 , #dcdfe6, #FFFFFF, #dcdfe6);color: #606266;">互相关注</button>
        <button v-if="!isCenter && (user.fans ===0 && user.follow === 1)" @click="$emit('toUnStarUser',user.uid)" style="flex: 1;background-image: linear-gradient(to right, #dcdfe6 , #dcdfe6, #FFFFFF, #dcdfe6);color: #606266;">已关注</button>
        <button v-if="!isCenter && user.fans ===1 && user.follow === 0" @click="$emit('toStarUser',user.uid)">+回关</button>
        <p></p>
        <button @click="toChat(user.uid,user.utel,user.uname==null||user.uname==''?'用户***'+user.utel.substring(user.utel.length - 4):user.uname,(user.follow===1&&user.fans===1?3:user.follow===1&&user.fans===0?1:user.follow===0&&user.fans===1?2:0))"><i class="iconfont">&#xe62f;</i>私信</button>
      </div>
    </div>
  </div>
</template>

<script>
import UserCardCount from "./userCardCount";
import UserCardInfo from "./userCardInfo";
import {MSG} from "../../../../store/msg";
import {clearToken, requestToken} from "../../../../store/request";
export default {
  name: "userCard",
  components: {UserCardInfo, UserCardCount},
  props: ['user','isCenter',"liked","fansNum","followNum"],
  data(){
    return{
      defaultAvatar:require('../../../../assets/icon/user.png'),
      completeNum: 'load'
    }
  },
  created() {
    if (this.isCenter){
      this.computeInfo();
    }
  },
  methods:{
    toReport(commentId,commentUserName,content,type){
      this.$emit('toReport',commentId,commentUserName,content,type);
    },
    setStatus(){
      MSG('warning','敬请期待')
    },
    /**
     * 创作博客
     * @param blogId
     */
    toNewBlog(blogId) {
      console.log(blogId)
      this.$router.push({path: '/writeBlogPanel'})
    },
    /**
     * 计算资料完成百分比
     */
    computeInfo(){
      let total = 100
      let user = this.$store.state.user
      if (user.uavatar == null || user.uavatar === ''){
        total -= 20
      }
      if (user.utel == null || user.utel === ''){
        total -= 20
      }
      if (user.usex == null || user.usex === ''){
        total -= 20
      }
      if (user.uname == null || user.uname === ''){
        total -= 20
      }
      if (user.ubirthday == null || user.ubirthday === ''){
        total -= 20
      }
      this.completeNum = total
    },
    toChangeAvatar(){
      document.querySelector(".avatar_upload input").click();
    },
    uploadSuccess(res, f, fl) {
      if (res.stat === 200) {
        this.toUpdateAvatarSubmit(res.url);
      } else {
        MSG('error','服务器异常，上传失败！');
        console.log(f+fl);
      }
    },
    toUpdateAvatarSubmit(url){
      requestToken({
        url:'/blogUser/updateAvatar',
        method:'post',
        data:{
          url:url,
          uId:this.$store.state.user.uid
        }
      }).then((res)=>{
        if (res.data.code === 200){
          MSG('success','图片上传成功！');
          localStorage.setItem('user',JSON.stringify(res.data.data.user));
          this.$store.state.user = JSON.parse(localStorage.getItem('user'));
        }else{
          MSG('error',res.data.msg);
          if (res.data.code === 300){
            clearToken();
          }
        }
      }).catch((err)=>{
        MSG('error','网络错误，无法上传！');
        console.log(err)
      })
    },
    uploadError(res, f, fl) {
      MSG('error','图片异常，无法上传！');
      console.log(res+f+fl);
    },
    /**
     * 私信
     * @param targetId
     * @param targetPhone
     * @param targetName
     * @param subscribe
     */
    toChat(targetId,targetPhone,targetName,subscribe){
      if (this.$store.state.token == null){
        MSG('error','尚未登录，请先登录');
        return;
      }
      let user = this.$store.state.user;
      let handleId = user.uid;
      let handleName = user.uname==null||user.uname==""?'用户***'+user.utel.substring(user.utel.length - 4):user.uname;
      let handlePhone = user.utel;
      this.$router.push({
        path:'/socketChatPanel',
        query:{
          handleId:handleId,
          handlePhone:handlePhone.substring(handlePhone.length - 3),
          handleName:handleName,
          targetId:targetId,
          targetPhone:targetPhone.substring(targetPhone.length - 3),
          targetName:targetName,
          subscribe:subscribe,
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .user_card_body{
    margin: 1rem 1rem 0.5rem 1rem;
    .user_card_body_head{
      display: flex;
      flex-direction: row;
      margin-bottom: 0.8rem;
      .user_card_body_head_avatar{
        margin-right: 1rem;
        width: 4rem;
        height: 4rem;
        border-radius: 100%;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        img{
          position: absolute;
          width: 4rem;
          height: 4rem;
          border-radius: 100%;
        }
        div{
          position: absolute;
          bottom: -0.6rem;
          left: 0;
          background: rgba(34, 34, 34,0.6);
          width: 4rem;
          height: 2rem;
          border-radius: 0 0 100px 100px;
          color: #FFFFFF;
          font-size: 1rem;
          text-align: center;
        }
      }
      .user_card_body_head_info{
        flex: 1;
      }
    }
    .user_card_body_foot{
      margin-top: 1rem;
      .one{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        button{
          flex: 1;
          border: none;
          height: 2rem;
          line-height: 2rem;
          border-radius: 0.2rem;
          padding: 0 0.8rem 0 0.8rem;
          background-size:500%;
          transition: 0.8s;
          color: #606266;
          cursor: pointer;
          i{
            font-size: 1rem;
            margin-right: 0.2rem;
          }
        }
        button:nth-child(1),button:nth-child(2){
          background-image: linear-gradient(to right, #DCDFE6 , #DCDFE6, #FFFFFF, #DCDFE6);
        }
        p{
          width: 0.5rem;
        }
        button:nth-child(3){
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          background-image: linear-gradient(to right, #DCDFE6 , #DCDFE6, #FFFFFF, #DCDFE6);
          i{
            font-size: 0.9rem;
          }
        }
        button:hover{
          background-position: right;
        }
      }
      .two{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        button{
          border: none;
          height: 2rem;
          line-height: 2rem;
          border-radius: 0.2rem;
          padding: 0 0.8rem 0 0.8rem;
          background-size:400%;
          transition: 0.8s;
          color: #606266;
          cursor: pointer;
          i{
            font-size: 1rem;
            margin-right: 0.2rem;
          }
        }
        button:nth-child(1){
          flex: 3;
          color: #FFFFFF;
          background-image: linear-gradient(to right, #ee2d5a , #ee2d5a, #F56C6C, #ee2d5a);
        }
        p{
          width: 0.5rem;
        }
        button:nth-child(3){
          flex: 1;
          min-width: 4rem;
          background-image: linear-gradient(to right, #DCDFE6 , #DCDFE6, #FFFFFF, #DCDFE6);
        }
        button:hover{
          background-position: right;
        }
      }
    }
  }
  @media screen and (max-width: 600px){
    .user_card_body{
      margin: 1rem 0 0 0;
      .user_card_body_head{
        margin-bottom: 0.8rem;
        .user_card_body_head_avatar{
          margin-right: 0.6rem;
          width: 3rem;
          height: 3rem;
          img{
            width: 3rem;
            height: 3rem;
          }
          div{
            bottom: -0.6rem;
            width: 3rem;
            height: 1.5rem;
            font-size: 0.7rem;
          }
        }
        .user_card_body_head_info{
          flex: 1;
        }
      }
      .user_card_body_foot{
        margin-top: 1rem;
        .one{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          button{
            flex: 1;
            border: none;
            height: 2rem;
            line-height: 2rem;
            border-radius: 0.2rem;
            padding: 0 0.8rem 0 0.8rem;
            background-size:500%;
            transition: 0.8s;
            color: #606266;
            cursor: pointer;
            i{
              font-size: 1rem;
              margin-right: 0.2rem;
            }
          }
          button:nth-child(1){
            background-image: linear-gradient(to right, #DCDFE6 , #DCDFE6, #FFFFFF, #DCDFE6);
          }
          p{
            width: 0.5rem;
          }
          button:nth-child(3){
            background-image: linear-gradient(to right, #DCDFE6 , #DCDFE6, #FFFFFF, #DCDFE6);
          }
          button:hover{
            background-position: right;
          }
        }
        .two{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          button{
            border: none;
            height: 2rem;
            line-height: 2rem;
            border-radius: 0.2rem;
            padding: 0 0.8rem 0 0.8rem;
            background-size:400%;
            transition: 0.8s;
            color: #606266;
            cursor: pointer;
            i{
              font-size: 1rem;
              margin-right: 0.2rem;
            }
          }
          button:nth-child(1){
            flex: 3;
            color: #FFFFFF;
            background-image: linear-gradient(to right, #ee2d5a , #ee2d5a, #F56C6C, #ee2d5a);
          }
          p{
            width: 0.5rem;
          }
          button:nth-child(3){
            flex: 1;
            background-image: linear-gradient(to right, #DCDFE6 , #DCDFE6, #FFFFFF, #DCDFE6);
            .iconfont{
              display: none;
            }
          }
          button:hover{
            background-position: right;
          }
        }
      }
    }
  }
</style>