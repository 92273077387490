import ElementUI from "_element-ui@2.15.6@element-ui";
import 'element-ui/lib/theme-chalk/index.css'

//方法
export function MSG(type,text) {
    let msg;
    if (type == 'load'){
        msg = ElementUI.Message({
            iconClass:'none',
            customClass:"loadStyle",
            message: '<div style="text-align: center;">'+text+'&ensp;<i class="el-icon-loading"></i></div>',
            dangerouslyUseHTMLString:'true',
            center: true,
            duration:0,
            offset:40
        });
    }else if (type == 'success'){
        msg = ElementUI.Message({
            iconClass:'el-icon-success',
            customClass:"successStyle",
            message: '<div style="text-align: center;">&ensp;'+text+'</div>',
            dangerouslyUseHTMLString:'true',
            center: true,
            duration:3000,
            offset:40
        });
    }else if (type == 'warning'){
        msg = ElementUI.Message({
            iconClass:'el-icon-warning',
            customClass:"warningStyle",
            message: '<div style="text-align: center;">&ensp;'+text+'</div>',
            dangerouslyUseHTMLString:'true',
            center: true,
            duration:3000,
            offset:40
        });
    }else if (type == 'error'){
        msg = ElementUI.Message({
            iconClass:'el-icon-error',
            customClass:"errorStyle",
            message: '<div style="text-align: center;">&ensp;'+text+'</div>',
            dangerouslyUseHTMLString:'true',
            center: true,
            duration:3000,
            offset:40
        });
    }
    return msg;
}