<template>
  <div class="blogDetailPanelComponent">
    <!--页头-->
    <el-page-header title="返回" @back="toBack" style="margin-bottom: 0.5rem">
      <template #content>
        <div style="font-size: 1rem; line-height: 1.4rem">详情</div>
      </template>
    </el-page-header>
    <!--顶部划线-->
    <hr style="border: 0; background-color: #f2f6fc; height: 1px" />

    <!--博客标头信息-->
    <blog-header
      v-show="!loading && errorStatus == 0"
      :blog="blog"
      :commentsNum="commentsNum"
      @getBlogById="getBlogById"
      @toReport="toReport"
      @toUserCenter1="toUserCenter1"
    ></blog-header>
    <!--博客内容-->
    <blog-body
      v-show="!loading && errorStatus == 0"
      :blog="blog"
      :commentsNum="commentsNum"
      @toUserCenter1="toUserCenter1"
    ></blog-body>
    <!--评论-->
    <blog-comments
      v-show="!loading && errorStatus == 0"
      :blog="blog"
      :comments="comments"
      @toReport="toReport"
      @changeCommentsData="changeCommentsData"
      @toUserCenter1="toUserCenter1"
    ></blog-comments>

    <!--底部提示-->
    <p v-if="errorStatus == 0" class="bottom_text">
      {{
        commentsNum > 0
          ? "已全部加载" + commentsNum + "条评论"
          : "暂无评论，占个楼吧 ~"
      }}
    </p>

    <!--访问博客异常-不存在或私密权限-->
    <p
      v-if="errorStatus != 0 && !loading"
      style="color: #ea605c; margin-top: 1rem"
    >
      <blog-error :errorStatus="errorStatus"></blog-error>
    </p>
    <!--加载中-->
    <p v-if="loading">
      <loading-component></loading-component>
    </p>
    <!--举报弹窗-->
    <report-dialog ref="child"></report-dialog>
  </div>
</template>

<script>
import { request, returnBack } from "../../../store/request";
import { MSG } from "../../../store/msg";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "quill-emoji/dist/quill-emoji.css";
import moment from "moment";
import Vue from "vue";
import ReportDialog from "./components/reportDialog";
import BlogHeader from "./components/blogHeader";
import BlogBody from "./components/blogBody";
import BlogComments from "./components/blogComments";
import BlogError from "../../otherComponent/blogError";
import LoadingComponent from "../../otherComponent/loadingComponent";
//需要自行引入
Vue.filter("dateFormat1", function (dateStr, pattern = "YYYY-MM-DD HH:mm") {
  return moment(dateStr).format(pattern);
});

export default {
  name: "blogDetailPanelComponent",
  components: {
    LoadingComponent,
    BlogError,
    BlogComments,
    BlogBody,
    BlogHeader,
    ReportDialog,
  },
  data() {
    return {
      blog: {}, //博客obj
      comments: [], //评论列表
      commentsNum: null, //评论数
      loading: false, //是否加载
      msg: null, //信息obj
      blogId: 0, //博客id
      errorStatus: 0, //错误码，0正常，420不存在，430权限异常（私密博客），500网络错误
    };
  },
  created() {
    this.blogId = this.$route.query.blog_id;
    this.getBlogById();
  },
  mounted() {},
  destroyed: function () {
    this.$store.state.viewBlogId = null;
  },
  methods: {
    /**
     * 加载博客信息
     */
    getBlogById() {
      //加载
      this.loading = true;
      this.msg = MSG("load", "加载中");
      let blogId = this.$route.query.blog_id;
      request({
        url: "/blogBlog/getBlogByBlogId",
        method: "post",
        data: {
          blogId: this.blogId,
          //这个接口不需要token，但是需要验证
          token:
            this.$store.state.token == null || this.$store.state.token == ""
              ? "-1"
              : this.$store.state.token,
        },
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.blog = res.data.data.blog;
            this.getBlogComments(blogId);
            //要在pc端右侧显示哪个博文正在被看
            this.$parent.$refs.blogSidePanel.$refs.sideBlog.whoIsSeeing();
            //视为一次“浏览博客”
            let viewBlogIdMemory = this.$store.state.viewBlogId;
            this.$store.state.viewBlogId = this.blogId;
            if (viewBlogIdMemory == null || viewBlogIdMemory !== this.blogId) {
              //浏览博客：除了当前页面刷新都算0  。
              this.viewBlog(this.blogId);
            }
          } else {
            MSG("error", res.data.msg);
            this.errorStatus = res.data.code;
          }
          this.msg.close();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          MSG("error", "网络异常");
          this.errorStatus = 500;
          this.msg.close();
          this.loading = false;
        });
    },
    /**
     * 浏览博客（计数+1）
     */
    viewBlog() {
      request({
        url: "/blogBlog/addViewTime",
        method: "post",
        data: {
          blogId: this.blogId,
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.blog.blog_view_num++;
          } else {
            this.msg = MSG("error", res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
          this.msg.close();
          this.loading = false;
        });
    },
    /**
     * 更改评论数量
     * 用于每次更新内容时实时修改当前评论数量
     * @param data
     */
    changeCommentsData(data) {
      this.comments = data;
      let commentsNum = 0;
      commentsNum += this.comments.length;
      for (const comment of this.comments) {
        commentsNum += comment.comments.length;
      }
      this.commentsNum = commentsNum;
    },
    /**
     * 获取评论列表
     * @param blogId
     */
    getBlogComments(blogId) {
      request({
        url: "/blogComment/getCommentTreeById",
        method: "post",
        data: {
          uId: this.$store.state.user == null ? -1 : this.$store.state.user.uid,
          blogId: blogId,
        },
      })
        .then((res) => {
          this.comments = res.data.data;
          let commentsNum = 0;
          commentsNum += this.comments.length;
          for (const comment of this.comments) {
            commentsNum += comment.comments.length;
          }
          this.commentsNum = commentsNum;
          this.msg.close();
        })
        .catch((error) => {
          console.log(error);
          this.msg.close();
          MSG("error", "博客评论加载失败");
        });
    },
    /**
     * 打开举报页面
     * @param commentId
     * @param commentUserName
     * @param content
     * @param type
     */
    toReport(commentId, commentUserName, content, type) {
      this.$refs.child.open(commentId, commentUserName, content, type);
    },
    /**
     * 返回上一页
     */
    toBack() {
      returnBack();
    },
    /**
     * 前往用户个人中心
     * @param uId
     * @returns {boolean}
     */
    toUserCenter1(uId) {
      if (this.$store.state.token == null) {
        MSG("warning", "尚未登录，请先登录！");
        return false;
      } else {
        if (this.$store.state.user.uid === uId) {
          this.$router.push({ path: "/userCenterPanel", query: { uId: 0 } });
        } else {
          this.$router.push({ path: "/userCenterPanel", query: { uId: uId } });
        }
      }
    },
  },
};
</script>
<style scoped>
/*整体样式*/
.blogDetailPanelComponent {
  min-height: 40rem;
  background: #ffffff;
  box-shadow: 0 0 0.5rem #cccccc; /*设置阴影*/
  border-radius: 0.4rem; /*定义圆角*/
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 0.8rem;
}
p {
  margin-top: 0.5rem;
  text-align: center;
}
.bottom_text {
  color: #909399;
  margin-top: 1rem;
  font-size: 0.8rem;
}
</style>
<style lang="scss">
@import "../../../store/msg.css";
</style>