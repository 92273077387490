<template>
  <div class="MainComponent">
    <div class="blogNotice">
      <span>
        &ensp;<router-link :to="{ path: '/index' }">首页</router-link> &ensp;<i
          class="fa fa-angle-right"
        ></i
        >&ensp;在线聊天
      </span>
      <span></span>
      <span @click="toBack()" style="font-weight: 500; cursor: pointer"
        >返回&ensp;</span
      >
    </div>
    <hr style="border: 0; background-color: #dcdfe6; height: 1px" />
    <!--聊天模块-->
    <div class="chat-box">
      <!--在线状态-->
      <header>
        <span :style="isOnline == 1 ? 'color:#409EFF' : 'color:#909399'"
          >({{ targetName }}) {{ isOnline == 1 ? "在线" : "离线" }}</span
        ><i
          :style="
            isOnline == 1
              ? 'margin-left:0.4rem;color:#67c23a;font-size:0.5rem'
              : 'margin-left:0.4rem;color:#F56C6C;font-size:0.5rem'
          "
          class="fa fa-circle"
        ></i>
      </header>
      <!--提示标语-->
      <header v-show="!isOnline || subscribe != 3">
        <span v-if="!isOnline && subscribe == 3"
          >对方离线，上线后将看到您的消息</span
        >
        <span v-if="subscribe != 3"
          ><i class="fa fa-warning"></i> 对方和您不是好友，请谨慎交流</span
        >
      </header>
      <!--聊天记录窗口-->
      <div :class="openEmoji ? 'msg-box2' : 'msg-box'" ref="msg-box">
        <div
          style="
            text-align: center;
            color: #909399;
            cursor: pointer;
            margin: 0.5rem 0 0.5rem 0;
            font-size: 0.8rem;
          "
          @click="getMoreList"
        >
          <u v-if="!isLoading">{{
            haveHistory ? "加载历史记录" : "没有更多了"
          }}</u>
          <span v-else>请求中..<i class="el-icon-loading"></i></span>
        </div>
        <div v-for="(i, index) in list" :key="index">
          <!--时间显示-->
          <div
            v-show="
              index == 0
                ? true
                : i.sendTime - list[index - 1].sendTime >= 60 * 5
            "
            class="time-show"
          >
            {{ i.sendTime | unixToStr }}
          </div>
          <!--通知类消息-->
          <div v-show="i.msgType > 0" class="msg-notice">
            <span>
              {{ (i.userId + "_" + i.msgType + "_" + i.content) | msgNotice }}
            </span>
          </div>
          <div
            v-show="i.msgType === 0"
            class="msg"
            :style="i.userId == userId ? 'flex-direction:row-reverse' : ''"
          >
            <div class="user-head">
              <img
                @click="toUserCenter(i.userId)"
                :src="i.avatar"
                :title="i.username"
              />
            </div>
            <div class="user-msg">
              <span
                v-if="i.msgDetailType == 0"
                :style="i.userId == userId ? ' float: right;' : ''"
                :class="i.userId == userId ? 'right' : 'left'"
                >{{ i.content }}</span
              >
              <span
                v-else-if="i.msgDetailType == 1"
                @click="showBigImg(i.content)"
                :style="
                  i.userId == userId
                    ? ' float: right;padding:0.3rem 0.3rem;cursor:pointer'
                    : 'padding:0.2rem 0.2rem;cursor:pointer'
                "
                :class="i.userId == userId ? 'right' : 'left'"
                ><img :src="i.content"
              /></span>
              <span
                v-else
                :style="i.userId == userId ? ' float: right;' : ''"
                :class="i.userId == userId ? 'right' : 'left'"
                >{{ i.content }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!--电脑端：工具栏条-->
      <div :class="openEmoji ? 'tool-box2' : 'tool-box'">
        <span @click="toOpenOrCloseEmoji()"><i class="fa fa-smile-o"></i></span>
        <span @click="uploadImg()"><i class="fa fa-image"></i></span>
        <span @click="toBlowUpPlane()"><i class="iconfont">&#xe671;</i></span>
        <el-upload
          class="main_img_upload"
          :headers="{ Authorization: this.$store.state.token }"
          :action="
            'http://' +
            this.$store.state.ip +
            ':' +
            this.$store.state.port +
            '/blogUser/toUploadImg'
          "
          accept="image/*,.jpg,.jpeg,.png,.gif"
          style="text-align: left"
          :on-success="
            (res, file, fileList) => uploadSuccess2(res, file, fileList)
          "
          :show-file-list="false"
          :on-error="(res, file, fileList) => uploadError(res, file, fileList)"
        >
        </el-upload>
      </div>
      <!--手机端：输入框+工具栏一体式-->
      <div :class="openEmoji || openTools ? 'input-box2' : 'input-box'">
        <input
          id="input"
          @focus="openKeyboard()"
          type="text"
          ref="sendMsg"
          v-model="contentText"
          @keyup.enter="sendText()"
        />
        <div class="input-emoji" @click="toOpenOrCloseEmoji">
          <i
            :class="openEmoji ? 'fa fa-keyboard-o' : 'fa fa-smile-o'"
            :style="openEmoji ? 'font-size:1.5rem' : ''"
          ></i>
        </div>
        <div class="input-tools" @click="toOpenOrCloseTools">
          <i class="el-icon-circle-plus-outline"></i>
        </div>
        <div
          class="btn"
          :class="{ ['btn-active']: contentText }"
          @click="sendText()"
        >
          发送
        </div>
      </div>
      <!--手机端：图标组件-->
      <div>
        <div class="emoji">
          <VEmojiPicker v-show="openEmoji" @select="selectEmoji" />
        </div>
        <div class="tools" v-show="openTools">
          <span
            ><span @click="uploadImg()"><i class="fa fa-image"></i></span
          ></span>
          <span
            ><span @click="toBlowUpPlane()"
              ><i class="iconfont">&#xe671;</i></span
            ></span
          >
          <span
            ><span><i class="fa fa-ban"></i></span
          ></span>
          <span
            ><span><i class="fa fa-ban"></i></span
          ></span>
          <span
            ><span><i class="fa fa-ban"></i></span
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../../../../store";
import Vue from "vue";
import { MSG } from "../../../../store/msg";
import { VEmojiPicker } from "v-emoji-picker";
//需要自行引入
/**
 * vue的时间戳转日期格式
 */
Vue.filter("unixToStr", function (value) {
  let date = new Date(value * 1000);
  var Y = date.getFullYear();
  var M =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  var D = date.getDate();
  var h = (Array(2).join(0) + date.getHours()).slice(-2);
  var m = (Array(2).join(0) + date.getMinutes()).slice(-2);
  // var s = (Array(2).join(0) + date.getSeconds()).slice(-2);
  //初始化
  let ret = "";
  //获取当前时间与信息的秒级时间戳之差
  let time = parseInt(new Date().getTime() / 1000) - parseInt(value);
  //进行判断
  if (time < 60 * 60 * 24) {
    //为啥去掉time >= (60 * 5)，因为第一条消息往往时间比较早，他和当前时间一致，所以这么显示
    ret = h + ":" + m;
  } else if (time >= 60 * 60 * 24 && time < 60 * 60 * 24 * 2) {
    ret = "昨天" + " " + h + ":" + m;
  } else if (time >= 60 * 60 * 24 * 2 && Y === new Date().getFullYear()) {
    ret = M + "月" + D + "日" + " " + " " + h + ":" + m;
  } else {
    ret = Y + "年" + M + "月" + D + "日" + " " + " " + h + ":" + m;
  }
  return ret;
});
Vue.filter("msgNotice", function (value) {
  //取值
  let data = value.split("_");
  let userId = parseInt(data[0]);
  let msgType = parseInt(data[1]);
  let content = data[2];
  //初始化
  let ret = "";
  //判断是否是自己
  if (userId === store.state.user.uid) {
    //是关于自己的消息
    switch (msgType) {
      case 1:
        ret = "连线成功";
        break;
      case 2:
        ret = "您已断开连接";
        break;
      case 3:
        ret = content;
        break;
      case 5:
        break;
      default:
        ret = "系统异常";
        break;
    }
  } else {
    //是关于对方的消息
    switch (msgType) {
      case 1:
        ret = "对方已连线";
        break;
      case 2:
        ret = "对方断开连接";
        break;
      case 3:
        ret = content;
        break;
      case 5:
        break;
      default:
        ret = "系统异常";
        break;
    }
  }
  return ret;
});
export default {
  name: "socketChatPart",
  components: {
    VEmojiPicker,
  },
  data() {
    return {
      openEmoji: false,
      openTools: false,
      ws: null,
      count: 0,
      userId: this.$store.getters.id, // 当前用户ID
      username: this.$store.getters.name, // 当前用户昵称
      avatar: this.$store.getters.avatar, // 当前用户头像
      list: [], // 聊天记录的数组
      contentText: "", // input输入的值
      targetName: this.$route.query.targetName, //目标（靶向）用户
      isOnline: 0, //对方是否在线（1在线，0离线）
      subscribe: 0, //0无关系，1关注，2粉丝，3朋友
      haveHistory: true,
      isLoading: false,
    };
  },
  created() {},
  mounted() {
    this.initWebSocket();
    //全局点击
    document.addEventListener("click", (e) => {
      console.log(e.target.className);
      if (
        e.target.className === "right" ||
        e.target.className === "left" ||
        e.target.className === "time-show" ||
        e.target.className === "user-msg" ||
        e.target.className === "msg-box" ||
        e.target.className === "msg-notice"
      ) {
        //说明点了外部区域
        this.openEmoji = false;
        this.openTools = false;
      }
    });
  },
  destroyed() {
    // 离开页面时关闭websocket连接
    this.ws.onclose(undefined);
  },
  methods: {
    /**
     * 返回上一页
     */
    toBack() {
      this.$router.go(-1);
    },
    open() {
      MSG("success", "1");
    },
    //打开或关闭emoji表情盘
    toOpenOrCloseEmoji() {
      if (this.openEmoji) {
        //关闭表情盘
        this.openEmoji = false;
        this.$refs.sendMsg.focus();
      } else {
        //关闭工具栏
        this.openTools = false;
        //打开表情盘
        this.openEmoji = true;
        //屏蔽默认键盘弹出
        document.activeElement.blur();
      }
      this.scrollBottm();
    },
    toOpenOrCloseTools() {
      if (this.openTools) {
        //关闭工具组件
        this.openTools = false;
      } else {
        //关闭表情盘
        this.openEmoji = false;
        //打开工具组件
        this.openTools = true;
      }
      this.scrollBottm();
    },
    //打开虚拟键盘（手机端）
    openKeyboard() {
      //关闭表情盘
      this.openEmoji = false;
      //关闭工具栏
      this.openTools = false;
      this.$refs.sendMsg.focus();
    },
    selectEmoji(emoji) {
      let input = document.getElementById("input");
      let startPos = input.selectionStart;
      let endPos = input.selectionEnd;
      let resultText =
        input.value.substring(0, startPos) +
        emoji.data +
        input.value.substring(endPos);
      input.value = resultText;
      input.selectionStart = startPos + emoji.data.length;
      input.selectionEnd = startPos + emoji.data.length;
      // this.$refs["sendMsg"].value = resultText;
      this.contentText = resultText;
    },
    uploadImg() {
      document.querySelector(".main_img_upload input").click();
    },
    toBlowUpPlane() {
      this.$router.push({
        path: "/blowUpPlanePanel",
        query: {
          handleId: this.$route.query.handleId,
          handlePhone: this.$route.query.handlePhone,
          handleName: this.$route.query.handleName,
          targetId: this.$route.query.targetId,
          targetPhone: this.$route.query.targetPhone,
          targetName: this.$route.query.targetName,
          subscribe: this.$route.query.subscribe, //0无关系，1关注，2粉丝，3朋友
        },
      });
    },
    uploadSuccess2(res, f, fl) {
      if (res.stat === 200) {
        if (res.url == null || res.url === "") {
          return false;
        }
        MSG("success", "图片上传成功！");
        let params = {
          userId: this.userId,
          username: this.username,
          avatar: this.avatar,
          msg: res.url,
          count: this.count,
          //消息类型：0聊天信息，1通知类消息
          msgType: 0,
          //具体消息类型：0正常文字，1图片音频等，2纯语音消息
          msgDetailType: 1,
        };
        this.ws.send(JSON.stringify(params)); //调用WebSocket send()发送信息的方法
        this.contentText = "";
        setTimeout(() => {
          this.scrollBottm();
        }, 500);
      } else {
        MSG("error", "服务器异常，上传失败！");
        console.log(f + fl);
      }
    },
    uploadError(res, f, fl) {
      MSG("error", "图片异常，无法上传！");
      console.log(res + f + fl);
    },
    showBigImg(url) {
      window.open(url);
    },
    getMoreList() {
      let _this = this;
      if (!_this.haveHistory || _this.isLoading) {
        return false;
      }
      _this.isLoading = true;
      let params = {
        userId: _this.userId,
        chatId: _this.list[0].chatId,
        msgType: 5,
      };
      console.log(params);
      _this.ws.send(JSON.stringify(params)); //调用WebSocket send()发送信息的方法
    },
    // 发送聊天信息
    sendText() {
      let _this = this;
      console.log(this.list);
      _this.$refs["sendMsg"].focus();
      if (!_this.contentText) {
        return;
      }
      let params = {
        userId: _this.userId,
        username: _this.username,
        avatar: _this.avatar,
        msg: _this.contentText,
        count: _this.count,
        //消息类型：0聊天信息，1通知类消息
        msgType: 0,
        //具体消息类型：0正常文字，1图片音频等，2纯语音消息
        msgDetailType: 0,
      };
      _this.ws.send(JSON.stringify(params)); //调用WebSocket send()发送信息的方法
      _this.contentText = "";
      setTimeout(() => {
        _this.scrollBottm();
      }, 500);
    },
    // 进入页面创建websocket连接
    initWebSocket() {
      let _this = this;
      //subscribe:3,//0无关系，1关注，2粉丝，3朋友
      this.subscribe = this.$route.query.subscribe;
      // 判断页面有没有存在websocket连接
      if (window.WebSocket) {
        // var serverHot =  window.location.hostname;
        //房间号码：计算方式，小id+小tel后三位+大id+大tel后三位

        //获取route的内容-生成两人的唯一房间号
        let user1 = [this.$route.query.handleId, this.$route.query.handlePhone];
        let user2 = [this.$route.query.targetId, this.$route.query.targetPhone];
        let str1 = user1[0] > user2[0] ? user2[0] : user1[0];
        let str2 = user1[0] > user2[0] ? user2[1] : user1[1];
        let str3 = user1[0] > user2[0] ? user1[0] : user2[0];
        let str4 = user1[0] > user2[0] ? user1[1] : user2[1];
        let sip = str1 + str2 + str3 + str4;
        // 生成网络地址
        var url =
          "ws://" +
          this.$store.state.ip +
          ":" +
          this.$store.state.port +
          "/groupChat/" +
          sip +
          "/" +
          this.$route.query.handleId;
        this.userId = this.$route.query.handleId;
        let ws = new WebSocket(url);
        _this.ws = ws;
        ws.onopen = function (e) {
          console.log("服务器连接成功: " + url + ".." + e);
        };
        ws.onclose = function (e) {
          console.log("服务器连接关闭: " + url + ".." + e);
          console.error(e);
        };
        ws.onerror = function () {
          console.log("服务器连接出错: " + url);
        };
        ws.onmessage = function (e) {
          //接收服务器返回的数据
          let resData = JSON.parse(e.data);
          _this.count = resData.count;
          //对方的在线状态-在线数量为2或者一个人在线而那个人就是对方的情况
          if (
            resData.count === 2 ||
            _this.$store.state.user.uid !== resData.userId
          ) {
            _this.isOnline = resData.isOnline;
          }
          //加载历史记录，把传回来的msgtype为5改回0
          if (resData.msgType === 5) {
            _this.isLoading = false;
            if (resData.chatId === -1) {
              _this.haveHistory = false;
              return false;
            }
            _this.list.unshift({
              userId: resData.userId,
              username: resData.username,
              avatar: resData.avatar,
              msgType: 0,
              chatId: resData.chatId,
              content: resData.msg,
              sendTime: resData.sendTime,
              isOnline: resData.isOnline,
              msgDetailType: resData.msgDetailType,
            });
          } else {
            _this.list = [
              ..._this.list,
              {
                userId: resData.userId,
                username: resData.username,
                avatar: resData.avatar,
                msgType: resData.msgType,
                chatId: resData.chatId,
                content: resData.msg,
                sendTime: resData.sendTime,
                isOnline: resData.isOnline,
                msgDetailType: resData.msgDetailType,
              },
            ];
          }
        };
      }
    },
    // 滚动条到底部
    scrollBottm() {
      //pc端
      let el = this.$refs["msg-box"];
      el.scrollTop = el.scrollHeight;
      //pe端
      window.scrollTo(0, document.body.scrollHeight);
    },
    toUserCenter(uId) {
      if (this.$store.state.token == null) {
        MSG("warning", "尚未登录，请先登录！");
        return false;
      } else {
        if (this.$store.state.user.uid == uId) {
          this.$router.push({ path: "/userCenterPanel", query: { uId: 0 } });
        } else {
          this.$router.push({ path: "/userCenterPanel", query: { uId: uId } });
        }
      }
    },
  },
};
</script>
<style scoped>
/*整体样式*/
.MainComponent {
  min-height: 40rem;
  background: #ffffff;
  box-shadow: 0 0 0.5rem #cccccc; /*设置阴影*/
  border-radius: 0.4rem; /*定义圆角*/
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 0.8rem;
}
/*头部区域*/
.blogNotice {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}
.blogNotice span:nth-child(1) {
  min-width: 2rem;
}
/*面包屑，索引*/
a:-webkit-any-link {
  font-weight: 900;
  text-decoration: none;
  color: #000000;
}
.blogNotice span:nth-child(2) {
  flex: 1;
}
.blogNotice span:nth-child(3) {
  min-width: 2rem;
}
.blogNotice span:nth-child(3) span span {
  font-weight: 900;
  color: rgb(230, 162, 60);
}

@media screen and (max-width: 600px) {
  /*整体样式*/
  .MainComponent {
    min-height: 40rem;
    box-shadow: none; /*设置阴影*/
    border-radius: 0.8rem; /*定义圆角*/
    padding: 1rem 0rem 0rem 0rem;
    margin-top: -0.6rem;
  }
  .MainComponent hr {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.chat-box {
  margin: 0 auto;
  background: #fafafa;
  height: 30rem;
  width: 100%;
  header {
    width: 100%;
    height: 3rem;
    background: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    border-bottom: 1px solid #e4e7ed;
  }
  header:nth-child(2) {
    top: 6.4rem;
    height: 1.6rem;
    color: #e6a23c;
    font-weight: 400;
    background: rgba(230, 162, 60, 0.2);
    font-size: 0.9rem;
  }
  .msg-box2,
  .msg-box {
    height: calc(100% - 6.5rem);
    width: 100%;
    //手机端需要上边距
    margin-top: 1rem;
    overflow-y: scroll;
    .time-show {
      text-align: center;
      color: #777777;
      font-size: 0.8rem;
    }
    .msg-notice {
      margin: auto;
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;

      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .msg-notice span {
      background: #e4e7ed;
      padding: 0.2rem 0.5rem;
      font-size: 0.8rem;
      border-radius: 0.3rem;
    }
    .msg {
      width: 95%;
      min-height: 2.5rem;
      margin: 0.8rem 0.5rem;
      position: relative;
      display: flex;
      justify-content: flex-start !important;
      .user-head {
        min-width: 2.5rem;
        width: 20%;
        width: 2.5rem;
        background: #fafafa;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .head {
          width: 1.2rem;
          height: 1.2rem;
        }
        // position: absolute;
      }
      .user-head img {
        border-radius: 100%;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
      .user-msg {
        width: 80%;
        // position: absolute;
        word-break: break-all;
        position: relative;
        z-index: 5;
        span {
          display: inline-block;
          padding: 0.5rem 0.7rem;
          border-radius: 0.5rem;
          margin-top: 0.2rem;
          font-size: 0.88rem;
          img {
            max-width: 8rem !important;
            max-height: 8rem !important;
            border-radius: 0.2rem;
          }
        }
        .left {
          background: white;
          animation: toLeft 0.5s ease both 1;
        }
        .right {
          background: #53a8ff;
          color: white;
          animation: toright 0.5s ease both 1;
        }
        @keyframes toLeft {
          0% {
            opacity: 0;
            transform: translateX(-10px);
          }
          100% {
            opacity: 1;
            transform: translateX(0px);
          }
        }
        @keyframes toright {
          0% {
            opacity: 0;
            transform: translateX(10px);
          }
          100% {
            opacity: 1;
            transform: translateX(0px);
          }
        }
      }
    }
  }
  //pc端不受影响
  .tool-box2,
  .tool-box {
    padding: 0 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 1.8rem;
    background: #fafafa;
    box-shadow: 0 0 5px #ccc;
    color: #303133;
    span {
      font-size: 1.2rem;
      cursor: pointer;
      margin-right: 0.8rem;
    }
  }
  //pc端不受影响
  .input-box2,
  .input-box {
    padding: 0 0.5rem;
    bottom: 0;
    width: 100%;
    height: 3rem;
    background: #fafafa;
    box-shadow: 0 0 5px #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      outline: none;
      height: 1.5rem;
      display: inline-block;
      font-size: 1.1rem;
      width: 100%;
      padding: 0.5rem;
      border: none;
      border-radius: 0.2rem;
    }
    .btn {
      height: 1.5rem;
      line-height: 1.5rem;
      min-width: 4rem;
      background: #e0e0e0;
      padding: 0.5rem;
      font-size: 1rem;
      color: white;
      text-align: center;
      border-radius: 0.2rem;
      margin-left: 0.5rem;
      transition: 0.5s;
      letter-spacing: 0.2rem;
    }
    .btn-active {
      background: #409eff;
      cursor: pointer;
    }
  }
}
.input-emoji,
.input-tools {
  display: none;
}
@media screen and (max-width: 600px) {
  .chat-box {
    height: 100%;
    header {
      position: fixed;
      z-index: 999;
      top: 3.8rem;
      right: 0rem;
      width: 100%;
      margin-left: 1rem;
      height: 2.6rem;
      background: #e4e7ed;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 1rem;
      border-bottom: 1px solid #e4e7ed;
    }
    header:nth-child(2) {
      top: 6.4rem;
      height: 1.2rem;
      color: #e6a23c;
      font-weight: 400;
      background: rgba(230, 162, 60, 0.2);
      font-size: 0.8rem;
    }
    .msg-box2,
    .msg-box {
      height: 100%;
      width: 100%;
      background: #ffffff;
      //手机端需要上边距
      margin-bottom: 3.5rem;
      overflow-y: auto;
      .msg {
        width: 95%;
        min-height: 2.5rem;
        margin: 0.8rem 0.5rem;
        position: relative;
        display: flex;
        justify-content: flex-start !important;
        .user-head {
          min-width: 2.5rem;
          width: 20%;
          width: 2.5rem;
          background: #ffffff;
          height: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          .head {
            width: 1.2rem;
            height: 1.2rem;
          }
          // position: absolute;
        }
        .user-head img {
          border-radius: 100%;
          width: 30px;
          height: 30px;
        }
        .user-msg {
          width: 80%;
          // position: absolute;
          word-break: break-all;
          position: relative;
          z-index: 5;
          span {
            display: inline-block;
            padding: 0.5rem 0.7rem;
            border-radius: 0.5rem;
            margin-top: 0.2rem;
            font-size: 0.88rem;
          }
          .left {
            background: #f2f6fc;
            color: #606266;
            animation: toLeft 0.5s ease both 1;
          }
          .right {
            background: #53a8ff;
            color: white;
            animation: toright 0.5s ease both 1;
          }
          @keyframes toLeft {
            0% {
              opacity: 0;
              transform: translateX(-10px);
            }
            100% {
              opacity: 1;
              transform: translateX(0px);
            }
          }
          @keyframes toright {
            0% {
              opacity: 0;
              transform: translateX(10px);
            }
            100% {
              opacity: 1;
              transform: translateX(0px);
            }
          }
        }
      }
    }
    .msg-box2 {
      margin-bottom: 14rem;
    }
    .tool-box2,
    .tool-box {
      display: none;
    }
    .input-box2,
    .input-box {
      position: fixed;
      z-index: 999;
      right: 0rem;
      padding: 0 0rem;
      bottom: 0;
      width: 100%;
      height: 3rem;
      background: #fafafa;
      box-shadow: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        outline: none;
        height: 1.2rem;
        font-size: 1.1rem;
        padding: 0.4rem;
        margin-left: 0.5rem;
      }
      //手机端emoji
      .input-emoji {
        display: block;
        height: 1.8rem;
        line-height: 1.8rem;
        min-width: 1.5rem;
        padding: 0rem;
        font-size: 1.8rem;
        text-align: center;
        margin-left: 0.5rem;
        margin-right: 0.3rem;
      }
      .input-tools {
        display: block;
        height: 1.8rem;
        line-height: 1.8rem;
        min-width: 1.5rem;
        padding: 0rem;
        font-size: 1.8rem;
        text-align: center;
        margin-left: 0.2rem;
        margin-right: 0.3rem;
      }
      .btn {
        height: 1rem;
        line-height: 1rem;
        min-width: 2.4rem;
        padding: 0.5rem;
        font-size: 1rem;
        text-align: center;
        margin-left: 0.2rem;
        margin-right: 0.5rem;
      }
      .btn-active {
        cursor: pointer;
      }
    }
    .input-box2 {
      bottom: 12.2rem;
    }
    .emoji {
      position: fixed;
      z-index: 998;
      bottom: 0rem;
      width: 100%;
      //emoji选择器搜索框
      /deep/ .container-search {
        display: none;
      }
      //emoji选择器宽度
      /deep/ .emoji-picker {
        width: 100%;
      }
      //emoji列表的高度
      /deep/ .container-emoji {
        height: 10rem;
      }
    }
    .tools {
      position: fixed;
      z-index: 998;
      bottom: 0rem;
      width: 100%;
      height: 12.2rem;
      background: #fafafa;

      display: flex;
      flex-wrap: wrap;
      span {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          width: 60%;
          height: 60%;
          font-size: 1.6rem;
          border-radius: 1rem;
          background: #ffffff;
        }
      }
    }
  }
}
</style>