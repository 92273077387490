<template>
  <div>
    <!--正常列表-->
    <div class="dataList" v-for="(item,index) in friends" :key="index">
      <div @click="toChat($store.state.user.uid,item.uid,$store.state.user.utel,item.utel,$store.state.user.uname,item.uname==null||item.uname===''?'用户***'+item.utel.substring(item.utel.length - 4):item.uname)">
        <!--头像-->
        <div>
          <img :src="item.uavatar" v-if='item.uavatar!="" && item.uavatar != null'>
          <img src="../../../../assets/icon/user.png" v-else>
        </div>
        <!--用户名称、性别-->
        <div>
          <span>{{item.uname==""?'用户****'+item.utel.substring(item.utel.length-4):item.uname}}</span>
          <span>
            <i v-if="item.usex == 1" style="color: #158bf1" class="fa fa-mars"></i>
            <i v-else-if="item.usex == 0" style="color: #c4468b" class="fa fa-venus"></i>
            <i v-else class="fa fa-genderless"></i>
          </span>
        </div>
        <!--上次登录时间-->
        <div>
          <span>上次登录：{{item.ulastLoginTime | dateFormat3}}</span>
        </div>
      </div>
    </div>
    <!--加载中-->
    <div v-if="isLoading">加载中...<i class="el-icon-loading"></i></div>
    <!--数据内容为空-->
    <div v-if="!isLoading && friends.length == 0">
      <el-empty :image-size="200">
        <template slot="description">
              <span style="color: #777777">
                搜索结果为空，尝试<span @click="getFriendList" style="color: #158bf1;cursor: pointer">刷新</span>
              </span>
        </template>
        <template slot="image"><img src="../../../../assets/img/noData.png"/></template>
      </el-empty>
    </div>
  </div>
</template>

<script>
import {MSG} from "../../../../store/msg";
import moment from 'moment'
import Vue from "vue";
import {clearToken, requestToken} from "../../../../store/request";
//需要自行引入
Vue.filter('dateFormat3', function (dateStr,pattern = "YYYY年MM月DD日 HH:mm") {
  return moment(dateStr).format(pattern);
})
export default {
  name: "userInfoComponentSecond",
  data() {
    return {
      friends:[],
      //是否加载中
      isLoading:true,
    }
  },
  created() {
    this.getFriendList();
  },
  methods:{
    getFriendList(){
      requestToken({
        url:'/blogUser/getFriendList',
        method:'post',
      }).then((res)=>{
        this.isLoading = false;
        if (res.data.code == 200){
          this.friends = res.data.data.friends;
        }else{
          MSG('error',res.data.msg);
          if (res.data.code == 300){
            clearToken();
          }
        }
      })
    },
    toChat(handleId,targetId,handlePhone,targetPhone,handleName,targetName){
      this.$router.push({
        path:'/socketChatPanel',
        query:{
          handleId:handleId,
          handlePhone:handlePhone.substring(handlePhone.length - 3),
          handleName:handleName,
          targetId:targetId,
          targetPhone:targetPhone.substring(targetPhone.length - 3),
          targetName:targetName,
          subscribe:3,//0无关系，1关注，2粉丝，3朋友
        }
      })
    },
  }
}
</script>

<style scoped>
/deep/ span{
  border: none!important;
}
/deep/ div{
  margin-top: 0rem!important;
}
/*列表整体布局*/
.dataList{
  margin-top: -0.5rem!important;
}
/*每一个朋友的布局*/
.dataList div{
  cursor: pointer;
  display: flex;
  margin: 0.2rem 0 0 0.2rem!important;
  flex-direction: row;
  border-bottom: 1px solid #F2F6FC;
}
/*每一个朋友的高度*/
.dataList div div{
  height: 4rem;
}
/*第一模块：头像*/
.dataList div div:nth-child(1){
  width: 4rem;
  /*控制子组件*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
/*头像*/
.dataList div div:nth-child(1) img{
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
}
/*第二模块：好友名*/
.dataList div div:nth-child(2){
  flex: 3;
  font-weight: 400;
  font-size: 1.2rem;

  display: flex;
  align-items: center;
}
/*性别i标签*/
.dataList div div:nth-child(2) i{
  margin-left: 0.4rem;
}
/*第三模块：上次登录时间*/
.dataList div div:nth-child(3){
  flex: 4;
  font-weight: 400;
  font-size: 1.1rem;
  color: #777777;

  display: flex;
  align-items: center;
}
@media screen and (max-width: 600px){
  /*列表整体布局*/
  .dataList{
    margin-top: -0.6rem!important;
  }
  /*每一个朋友的高度*/
  .dataList div div{
    height: 3rem;
  }
  /*第一模块：头像*/
  .dataList div div:nth-child(1){
    width: 4rem;
  }
  /*头像*/
  .dataList div div:nth-child(1) img{
    width: 2.6rem;
    height: 2.6rem;
  }
  /*第二模块：好友名*/
  .dataList div div:nth-child(2){
    font-size: 1rem;
  }
  /*第三模块：上次登录时间*/
  .dataList div div:nth-child(3){
    display: none;
  }
}
</style>