<template>
  <div class="blogOther" v-if="blog.blog_id != null">
    <!--博客标题-->
    <div class="detail_title">
      {{ blog.blog_title }}
    </div>
    <!--博客其他信息-->
    <div class="detail_info">
      <!--用户信息-->
      <div class="user">
        <!--头像-->
        <img @click="$emit('toUserCenter1',blog.blog_author_id)" :src="blog.u_avatar!==''&&blog.u_avatar!=null ? blog.u_avatar:defaultAvatar" alt="">
        <!--昵称-->
        <span @click="$emit('toUserCenter1',blog.blog_author_id)">
          {{
            (blog.u_name !== '' && blog.u_name != null)
                ? blog.u_name
                : '用户***' + blog.u_tel.substring(blog.u_tel.length - 4)
          }}
        </span>
      </div>
      <div class="tool_split">|</div>
      <!--发布日期-->
      <div>
        <i class="iconfont">&#xe8b4;</i>
        <span>{{ blog.blog_create_date_time | dateFormat1 }}</span>
      </div>
      <div class="tool_split">|</div>
      <!--浏览次数-->
      <div class="cursor_pointer">
        <span @click="showViewNum(blog.blog_view_num)">
          <i class="iconfont">&#xe6db;</i>
          {{ blog.blog_view_num }}
        </span>
      </div>
      <div class="tool_split">|</div>
      <!--评论数-->
      <div class="cursor_pointer">
        <span @click="jumpToComment">
          <i class="iconfont">&#xe7e0;</i>
          {{ commentsNum }}
        </span>
      </div>
      <div class="tool_split">|</div>
      <!--点赞数-->
      <div class="cursor_pointer">
        <span @click="showLikeNum(blog.like_num)">
          <i
            :class="[blog.isLike === 1?'fa fa-thumbs-up':'fa fa-thumbs-o-up']"
            :style="[{color:(blog.isLike == 1?'#6b62fd':'#777777')}]" />
          {{ blog.like_num }}
        </span>
      </div>
      <div class="tool_split">|</div>
      <!--展示“举报”按钮或“更多”菜单-->
      <div class="todo">
        <span @click="$emit('toReport',blog.blog_id,blog.u_name,blog.blog_title,1)"
              v-if="$store.state.user == null || blog.blog_author_id !== $store.state.user.uid"><i
            class="fa fa-warning" style="color: #777777;"></i></span>
        <span v-if="$store.state.user != null && blog.blog_author_id === $store.state.user.uid">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                更多<i class="fa fa-chevron-circle-down" style="margin-right: 0rem;margin-left: 0.2rem"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item style="color: rgb(64, 158, 255)">
                  <span
                      @click="$router.push({path:'/writeBlogPanel',query:{blogId:blog.blog_id,route:$route.fullPath}})"><i
                      class="fa fa-pencil-square-o"></i>编辑博文</span>
                </el-dropdown-item>
                <el-dropdown-item style="color: rgb(255, 20, 147)" v-if="blog.blog_status==0 || blog.blog_status==2">
                  <span @click="hiddenBlogById(1)"><i class="fa fa-eye-slash"></i>设为隐藏</span>
                </el-dropdown-item>
                <el-dropdown-item style="color: rgba(138, 43, 226,1)" v-if="blog.blog_status==0 || blog.blog_status==1">
                  <span @click="hiddenBlogById(2)"><i class="fa fa-user" style="width: 0.9rem"></i>设为私密</span>
                </el-dropdown-item>
                <el-dropdown-item style="color: rgb(103, 194, 58)" v-if="blog.blog_status==1 || blog.blog_status==2">
                  <span @click="noHiddenBlogById"><i class="fa fa-eye"></i>设为公开</span>
                </el-dropdown-item>
                <el-dropdown-item style="color: rgb(245, 108, 108)">
                  <span @click="delBlogById"><i style="margin-right: 0.5rem;margin-left: 0.1rem"
                                                class="fa fa-trash"></i>删除博文</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
      </div>
    </div>
    <!--划线-->
    <hr class="hr0" />
  </div>
</template>

<script>
import {clearToken, requestToken} from "../../../../store/request";
import {MSG} from "../../../../store/msg";

export default {
  name: "blogHeader",
  props: ['blog','commentsNum'],
  data() {
    return {
      defaultAvatar: require('../../../../assets/icon/user.png'),
    }
  },
  methods: {
    /**
     * 弹框提示浏览次数
     * @param num
     */
    showViewNum(num) {
      MSG('success','此文已被浏览' + num + '次');
    },
    /**
     * 跳转至评论输入框
     */
    jumpToComment() {
      document.getElementById('commentMsg').focus();
    },
    /**
     * 删除博客byId
     */
    delBlogById() {
      this.$confirm('确定删除博客吗？', '警告', {
        confirmButtonText: '确定',
        callback: action => {
          if (action == 'confirm'){
            requestToken({
              url:'/blogBlog/toDelBlogById',
              method:'post',
              data:{
                blog_id: this.blog.blog_id
              }
            }).then((res) => {
              if (res.data.code == 200) {
                MSG("success","博客删除成功！")
                this.$router.push({
                  path: '/index'
                });
              } else {
                MSG('error',res.data.msg);
                if (res.data.code == 300){
                  clearToken();
                }
              }
            })
            .catch((error) => {
              MSG("error","网络错误，博客删除失败！");
              console.log("toDelBlog_error" + error)
            })
          }
        }
      });
    },
    /**
     * 点赞 or 取赞
     * @param num
     * @returns {boolean}
     */
    showLikeNum(num) {
      console.log(num)
      let type = 0;
      if (this.blog.isLike == 1){
        //已点赞，去取赞
        type = 0;
      }else{
        if (this.$store.state.token==null){
          //说明没有登录
          MSG('warning','您还未登录，登录后才能点赞哦');
          return false;
        }else{
          //没点赞，去点赞
          type = 1;
        }
      }
      requestToken({
        url:'/blogBlog/toLikeOrUnlikeBlog',
        method:'post',
        data:{
          uId: this.$store.state.user.uid,
          blog_id: this.blog.blog_id,
          type:type
        }
      }).then((res) => {
        if (res.data.code == 200) {
          MSG('success',type == 0?'取赞成功':'点赞成功');
          this.blog.isLike = type;
          this.blog.like_num = type==0?(this.blog.like_num-1):(this.blog.like_num+1);
        } else {
          MSG('error',res.data.msg);
          if (res.data.code == 300){
            clearToken();
          }
        }
      })
      .catch((error) => {
        MSG('error','网络错误，操作失败！');
        console.log("error:" + error)
      })
    },
    /**
     * 隐藏 or 私密博客
     * @param type
     */
    hiddenBlogById(type) {
      this.$confirm(type===1?'确定隐藏博客吗？':'确定设为私密吗，这将仅你可见', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          if (action === 'confirm'){
            requestToken({
              url:'/blogBlog/hiddenBlogById',
              method:'post',
              data:{
                blog_id: this.blog.blog_id,
                type:type
              }
            }).then((res) => {
              if (res.data.code === 200) {
                MSG("success",type===1?"成功隐藏博客！":"已设为私密！")
                this.$emit('getBlogById');
              } else {
                MSG('error',res.data.msg);
                if (res.data.code === 300){
                  clearToken();
                }
              }
            })
            .catch((error) => {
              MSG("error","网络错误，博客隐藏失败！");
              console.log("toDelBlog_error" + error)
            })
          }
        }
      });
    },
    /**
     * 公开被 隐藏 or 私密 的博客
     */
    noHiddenBlogById(){
      this.$confirm('确定公开博客吗？', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          if (action === 'confirm'){
            requestToken({
              url:'/blogBlog/noHiddenBlogById',
              method:'post',
              data:{
                blog_id: this.blog.blog_id
              }
            }).then((res) => {
              if (res.data.code === 200) {
                MSG("success","公开博客成功！")
                this.$emit('getBlogById');
              } else {
                MSG('error',res.data.msg);
                if (res.data.code === 300){
                  clearToken();
                }
              }
            })
            .catch((error) => {
              MSG("error","网络错误，博客公开失败！");
              console.log("toDelBlog_error" + error)
            })
          }
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
//博客详情标题和信息栏
.blogOther {
  //标题
  .detail_title {
    color: #303133;
    line-height: 2.8rem;
    font-size: 1.8rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    /*强制英文数字换行*/
    word-break:break-all;
  }
  //信息栏
  .detail_info{
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    color: #606266;
    //加小手
    .cursor_pointer{
      cursor: pointer;
    }
    //所有小图标都与右侧具有0.3rem边距
    i{
      margin-right: 0.3rem;
    }
    //每个块的隔断符号“|”
    .tool_split{
      font-size: 0.7rem;
      height: 1.5rem;
      width: 1.2rem;
      color: #909399;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: default;
      user-select: none;
    }
    .user{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      height: 1.5rem;
      img{
        width: 1.2rem;
        border-radius: 100%;
        margin-right: 0.2rem;
        cursor: pointer;
      }
      span{
        color: #158bf1;
        cursor: pointer;
      }
    }
    .todo{
      span:nth-child(1){
        color: #158bf1;
        cursor: pointer;
      }
      //更多下拉变色
      /deep/.el-dropdown-menu__item{
        font-weight: 900!important;
      }
      /deep/.el-dropdown-menu{
        border-radius: 0.6rem!important;
        box-shadow: 0 0 1rem #C0C4CC!important;
      }
    }
  }
  .hr0{
    margin-top: 0.5rem;
    margin-bottom: 0.8rem;
    border: 0;
    background-color: rgb(220, 223, 230);
    height: 1px;
  }
}
</style>