<template>
  <div class="basePanel">
    <template>
      <div class="infinite-list-wrapper">
        <!--头部区域-->
        <div class="blogNotice">
          <span>&ensp;博客</span>
          <span></span>
          <span>
            <span v-if="loading && total == 0 && !isInternetError">加载中<i class="el-icon-loading"></i></span>
            <span v-if="isInternetError">出错啦</span>
            <span v-if="!loading && !isInternetError">共<span>{{total}}</span>篇&ensp;</span>
          </span>
        </div>
        <hr class="hr_basePanel">
        <!--博客列表体-->
        <div v-for="(blog,index) in blogs" :key="index" class="blogMain">
          <!--封面-->
          <div class="blog_mainImgDiv"><img :src="blog.blog_main_img" @click="getBlogDetail(blog.blog_id)" @error="defImg"></div>
          <!--具体内容-->
          <div class="blog_InfoDiv">
            <div class="blog_InfoDiv_title" @click="getBlogDetail(blog.blog_id)"><span>{{index+1}}</span>{{blog.blog_title}}</div>
            <div class="blog_InfoDiv_content">{{blog.blog_content}}</div>
            <div class="blog_InfoDiv_other">
              <span><img @click="toUserCenter1(blog.blog_author_id)" :src="blog.u_avatar!=''&&blog.u_avatar!=null ? blog.u_avatar:defaultAvatar"></span>
              <span>
                <span @click="toUserCenter1(blog.blog_author_id)">{{(blog.u_name!='' && blog.u_name!=null)?blog.u_name:'用户***'+blog.u_tel.substring(blog.u_tel.length-4)}}</span>
                <span>{{blog.blog_create_date_time}}</span>
              </span>
              <span></span>
              <span><i class="fa fa-eye" style="color: #777777;"></i>&ensp;{{blog.blog_view_num}}&ensp;&ensp;</span>
              <span><i class="fa fa-thumbs-o-up" style="color: #777777;"></i>&ensp;{{blog.like_num}}</span>
              <span></span>
            </div>
          </div>
          <!--清除浮动-->
          <div style="clear: both"></div>
          <hr class="hr_basePanel_div">
        </div>
        <p v-if="isInternetError">
          <el-empty :image-size="200">
            <template slot="description">
              <span style="color: #777777">
                遇到了点问题，尝试<span @click="$router.go(0)" style="color: #158bf1;cursor: pointer">再次刷新</span>
              </span>
            </template>
            <template slot="image"><img src="../../assets/img/500.png"/></template>
          </el-empty>
        </p>
        <p v-if="loading && total == 0 && !isInternetError">
          <el-empty description="精彩即将呈现" :image-size="200">
            <template slot="image"><img src="../../assets/img/pic_wait.png"/></template>
          </el-empty>
        </p>
        <p v-if="loading && total != 0 && !isInternetError" class="totalBlogs">努力加载中<i class="el-icon-loading"></i></p>
        <p v-if="total <= count && total!=0 && !isInternetError" class="totalBlogs">已全部加载{{total}}条博客</p>
        <p v-if="total > count && !loading && total!=0 && !isInternetError"><button class="loadBtn" @click="continueLoadBlogs()">剩余{{total - count}}条，点击加载</button></p>
        <p v-if="!loading && total==0 && !isInternetError" class="totalBlogs">当前列表为空</p>
      </div>
    </template>
  </div>
</template>

<script>
import {MSG} from "../../store/msg";
import {request} from "../../store/request";
import moment from 'moment'
import Vue from "vue";//需要自行引入
Vue.filter('dateFormat', function (dateStr,pattern = "YYYY-MM-DD") {
  return moment(dateStr).format(pattern);
})
export default {
  name: "basePanel",
  data(){
    return{
      blogs:[],
      count: 0,
      total: 0,
      loading: false,
      msg:null,
      isInternetError:false,
      defaultAvatar:require('../../assets/icon/user.png'),
      defaultImg: require("../../assets/img/404.png"),
    }
  },
  created() {
    this.blogs = [];
    this.getBlogs(1,5);
  },
  methods: {
    getBlogs(index,num){
      this.loading = true;
      if (this.total == 0 && this.count == 0){
        //说明首次加载，不需要弹框
      }else{
        this.msg = MSG('load','加载中');
      }
      request({
        url: "/blogBlog/getSearchBlogJson",
        method: 'post',
        data:{
          uId:0,
          type:2,
          searchText:'',
          pageNum:index,
          num:num,
        }
      })
      .then((res)=>{
        /**
         * 做限制，因为滚动到底部可能会调用此方法n次，必须做判断
         */
        let length = res.data.data.blogs.length;
        if (this.total != 0 && this.blogs[this.blogs.length - length].blog_id == res.data.data.blogs[0].blog_id){
          //证明冲突了，这个方法被重复的调用了，不覆盖了
          return false;
        }
        //正常走逻辑
        for (let i = 0; i < res.data.data.blogs.length; i++) {
          this.$set(this.blogs,this.blogs.length,res.data.data.blogs[i])
        }
        //从html中获取纯文本
        for (var i = 0; i < this.blogs.length; i++) {
          // value.replace(/<[^>]+>/g, '').replace(/&nbsp;/ig, '').replace(/\s/g, '')
          var re1 = new RegExp("<.+?>", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
          var msg = this.blogs[i].blog_content.replace(re1, ''); //执行替换成空字符
          this.blogs[i].blog_content = msg.substring(0, 150)+'...';//缩减字数为200个字
          //修改日期
          this.blogs[i].blog_create_date_time = this.myDateFormat(this.blogs[i].blog_create_date_time);
        }
        this.loading = false;
        this.total = res.data.data.total;
        this.count += res.data.data.blogs.length;
        this.msg!=null?this.msg.close():{};
      })
      .catch((err)=>{
        console.error(err);
        this.msg!=null?this.msg.close():{};
        this.isInternetError = true;
        this.msg = MSG('error','网络错误')
      })
    },
    getBlogDetail(blog_id){
      this.$router.push({path:'/blogDetailPanel',query:{blog_id:blog_id}});
    },
    toUserCenter1(uId){
      if (this.$store.state.token == null){
        MSG('warning','尚未登录，请先登录！');
        return false;
      }else{
        if (this.$store.state.user.uid == uId){
          this.$router.push({path:'/userCenterPanel',query:{uId:0}});
        }else{
          this.$router.push({path:'/userCenterPanel',query:{uId:uId}});
        }
      }
    },
    myDateFormat(date){
      let thisDate = Date.parse(date)/1000;
      let nowDate = Date.parse(new Date())/1000;
      let differenceSecond = nowDate - thisDate;
      if (differenceSecond < 60){
        return "刚刚";
      }else if(differenceSecond < (60*60)){
        return parseInt(differenceSecond / 60) + '分钟前';
      }else if(differenceSecond < (60*60*24)){
        return parseInt(differenceSecond / (60*60)) + '小时前';
      }else if(differenceSecond < (60*60*24*5)){
        return parseInt(differenceSecond / (60*60*24)) + '天前';
      }else{
        return moment(date).format('YYYY-MM-DD');
      }
    },
    continueLoadBlogs(){
      if (this.total <= this.count && this.total!=0){
        //已全部加载
      }else{
        if (this.total == 0){
          //默认的五条还没加载完呢，不能重新请求,否则会重复内容
        }else{
          this.getBlogs((this.count+5)/5,5);
        }
      }
    },
    defImg(){
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
  }
}
</script>
<style lang="scss">
  .loadStyle{
    background: rgba(255,255,255,0.8)!important;
    min-width: 6rem!important;
    margin-top: 2rem;
    border-radius: 8px!important;
}
</style>
<style scoped>
  /*上方分割线*/
  .hr_basePanel{
    border:0;
    background-color:#DCDFE6;
    height:1px;
  }
  /*博客列表间的分割线*/
  .hr_basePanel_div{
    border:0;
    background-color:#EBEEF5;
    height:1px;
    width: 94%;
    margin: auto;
  }
  .basePanel{
    background: #FFFFFF;
    min-height: 40rem;
    box-shadow: 0 0 0.5rem #cccccc;/*设置阴影*/
    border-radius: 0.4rem;/*定义圆角*/
    padding: 1rem 1rem 1rem 1rem;
    margin-bottom: 0.8rem;
  }
  p{
    text-align: center;
    margin-top: 1rem;
  }
  /*继续加载按钮*/
  .loadBtn{
    background-image: linear-gradient(to right, #009688 , #00BF88, #009688);
    height: 1.8rem;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    padding: 0.2rem 0.4rem;
    cursor: pointer;
    background-size: 200%;
    transition: 0.5s;
  }
  .loadBtn:hover{
    box-shadow: rgba(0, 150, 136,1) 0px 1px 4px 0px;
    background-position: right;
  }
  /*博客列表主体*/
  .blogMain{
    display: block;
    height: 20rem;
  }
  /*博客列表中封面图的盒子*/
  .blog_mainImgDiv{
    float: right;
    width: 42%;
    height: 90%;
    line-height: 20rem;
  }
  /*博客列表中封面图的配置*/
  .blog_mainImgDiv img{
    width: 96%;
    height: 100%;
    cursor: pointer;
    border-radius: 0.2rem;
    vertical-align: middle
  }

  /*博客盒子信息*/
  .blog_InfoDiv{
    float: left;
    height: 18rem;
    width: 56%;
    margin-right: 2%;
    padding: 1rem 0rem 1rem 0rem;
  }
  /*博客列表-每个博客-标题*/
  .blog_InfoDiv_title{
    font-weight: 900;
    font-size: 1.2rem!important;
    height: 3rem;
    line-height: 3rem;
    cursor: pointer;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 1 !important;
    overflow: hidden !important;
  }
  /*博客列表-每个博客-标题*/
  .blog_InfoDiv_title span{
    width: 1rem;
    background: #e6a23c;
    color: #FFFFFF;
    border-radius: 4px;
    padding: 0rem 0.5rem 0rem 0.5rem;
    margin-right: 0.3rem;
  }
  /*博客列表-每个博客-内容*/
  .blog_InfoDiv_content{
    line-height: 2rem;
    font-size: 1rem;
    height: 10rem;
    /*强制英文数字换行*/
    word-break:break-all;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 5 !important;
    overflow: hidden !important;
  }
  /*博客列表-每个博客-博客信息展示体*/
  .blog_InfoDiv_other{
    display: flex;
    flex-direction:row;
    height: 2rem;
    margin-top: 1rem;
  }
  /*博客列表-每个博客-博客信息展示体-头像盒子*/
  .blog_InfoDiv_other span:nth-child(1){
    margin-right: 0.6rem;
  }
  /*博客列表-每个博客-博客信息展示体-头像盒子-头像
  */
  .blog_InfoDiv_other span:nth-child(1) img{
    height: 3rem;
    border-radius: 100%;
    cursor: pointer;
  }
  /*博客列表-每个博客-博客信息展示体-时间及作者信息盒子*/
  .blog_InfoDiv_other span:nth-child(2){
    display: flex;
    height: 3rem;
    flex-direction: column;
    justify-content: center;
  }
  /*博客列表-每个博客-博客信息展示体-信息盒子-作者名*/
  .blog_InfoDiv_other span:nth-child(2) span:nth-child(1){
    display: flex;
    font-size: 1rem;
    line-height:1.2rem;
    height: 3rem;
    cursor: pointer;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 1 !important;
    overflow: hidden !important;
  }
  /*博客列表-每个博客-博客信息展示体-信息盒子-时间*/
  .blog_InfoDiv_other span:nth-child(2) span:nth-child(2){
    display: flex;
    font-size: 0.8rem;
    height: 3rem;
    flex-direction: column;
    justify-content: center;
    color: #909399;
  }
  /*博客列表-每个博客-博客信息展示体-空格*/
  .blog_InfoDiv_other span:nth-child(3){
    display: flex;
    flex-direction: column;
    height: 3rem;
    justify-content: center;
    flex: 1;
  }
  /*博客列表-每个博客-博客信息展示体-浏览*/
  .blog_InfoDiv_other span:nth-child(4){
    display: flex;
    flex-direction: row;
    height: 3rem;
    justify-content: center;
    align-items: center;
  }
  /*博客列表-每个博客-博客信息展示体-点赞*/
  .blog_InfoDiv_other span:nth-child(5){
    display: flex;
    flex-direction: row;
    height: 3rem;
    justify-content: center;
    align-items: center;
  }
  /*博客列表-每个博客-博客信息展示体-信息盒子-空格*/
  .blog_InfoDiv_other span:nth-child(6){
    display: flex;
    flex-direction: column;
    height: 3rem;
    justify-content: center;
    flex: 1;
  }
  /*头部区域*/
  .blogNotice{
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
  }
  .blogNotice span:nth-child(1){
    min-Width: 2rem;
    font-weight: 900;
  }
  .blogNotice span:nth-child(2){
    flex: 1;
  }
  .blogNotice span:nth-child(3){
    min-Width: 2rem;
  }
  .blogNotice span:nth-child(3) span span{
    font-weight: 900;
    color: rgb(230, 162, 60);
  }
  .totalBlogs{
    font-size: 0.9rem;
    color: #909399;
  }
  /*大型手机端适配*/
  @media screen and (max-width: 850px){
    .basePanel{
      padding: 1rem 0.5rem 1rem 0.5rem;
    }
  }
  /*普遍手机端适配*/
  @media screen and (max-width: 650px){
    .totalBlogs{
      font-size: 0.8rem;
      color: #909399;
    }
    p{
      margin-top: 0;
    }
    /*头部区域*/
    .blogNotice{
      margin-bottom: 0.5rem;
    }
    /*上方分割线*/
    .hr_basePanel{
      margin-bottom: 0.2rem;
    }
    /*博客列表主体*/
    .blogMain{
      width: 96%;
      margin: auto;
      display: block;
      height: 18rem;
      padding-bottom: 0.2rem;
    }
    /*封面浮动到上面*/
    /*博客列表中封面图的盒子*/
    .blog_mainImgDiv{
      float: none;
      width: 100%;
      height: 10rem;
      line-height: 10rem;
    }
    /*博客列表中封面图的配置*/
    .blog_mainImgDiv img{
      width: 100%;
      height: 96%;
      vertical-align: middle
    }
    /*博客盒子信息*/
    .blog_InfoDiv{
      float: none;
      height: 7.4rem;
      width: 100%;
      padding: 0rem 0rem 0rem 0rem;
    }
    /*博客列表-每个博客-标题*/
    .blog_InfoDiv_title{
      font-weight: 900;
      font-size: 1.1rem;
      height: 2rem;
      line-height: 2rem;
      display: -webkit-box !important;
      -webkit-box-orient: vertical !important;
      -webkit-line-clamp: 1 !important;
      overflow: hidden !important;
    }
    /*博客列表-每个博客-内容*/
    .blog_InfoDiv_content{
      line-height: 1rem;
      font-size: 1rem;
      height: 1rem;
      display: -webkit-box !important;
      -webkit-box-orient: vertical !important;
      -webkit-line-clamp: 1 !important;
      overflow: hidden !important;
    }
    /*博客列表-每个博客-博客信息展示体-信息盒子-作者名*/
    .blog_InfoDiv_other span:nth-child(2) span:nth-child(1){
      display: flex;
      font-size: 1rem;
      line-height:1.3rem;
      height: 2rem;
      display: -webkit-box !important;
      -webkit-box-orient: vertical !important;
      -webkit-line-clamp: 1 !important;
      overflow: hidden !important;
    }
    /*博客列表-每个博客-博客信息展示体-信息盒子-时间*/
    .blog_InfoDiv_other span:nth-child(2) span:nth-child(2){
      display: flex;
      font-size: 0.7rem;
      height: 3rem;
      flex-direction: column;
      justify-content: center;
    }
    /*博客列表-每个博客-博客信息展示体-头像盒子-头像*/
    .blog_InfoDiv_other span:nth-child(1) img{
      height: 2.4rem;
    }
    /*博客列表-每个博客-博客信息展示体-时间及作者信息盒子*/
    .blog_InfoDiv_other span:nth-child(2){
      height: 2.4rem;
    }
    /*博客列表-每个博客-博客信息展示体-信息盒子-作者名*/
    .blog_InfoDiv_other span:nth-child(2) span:nth-child(1){
      line-height:1.1rem;
      height: 2.4rem;
    }
    /*博客列表-每个博客-博客信息展示体-信息盒子-时间*/
    .blog_InfoDiv_other span:nth-child(2) span:nth-child(2){
      height: 2.4rem;
    }
    /*博客列表-每个博客-博客信息展示体-浏览*/
    .blog_InfoDiv_other span:nth-child(4){
      display: flex;
      flex-direction: row;
      height: 2.4rem;
      justify-content: center;
      align-items: center;
    }
    /*博客列表-每个博客-博客信息展示体-点赞*/
    .blog_InfoDiv_other span:nth-child(5){
      display: flex;
      flex-direction: row;
      height: 2.4rem;
      justify-content: center;
      align-items: center;
    }
    /*博客列表-每个博客-博客信息展示体-信息盒子-空格*/
    .blog_InfoDiv_other span:nth-child(6){
      flex: 0.2;
    }
  }

</style>
<style lang="scss">
@import "../../store/msg.css";
</style>