import { render, staticRenderFns } from "./updateOldBlog.vue?vue&type=template&id=06329636&scoped=true"
import script from "./updateOldBlog.vue?vue&type=script&lang=js"
export * from "./updateOldBlog.vue?vue&type=script&lang=js"
import style0 from "./updateOldBlog.vue?vue&type=style&index=0&id=06329636&prod&scoped=true&lang=css"
import style1 from "./updateOldBlog.vue?vue&type=style&index=1&id=06329636&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06329636",
  null
  
)

export default component.exports