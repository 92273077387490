<template>
  <div class="searchPhone" :style="isShowSearchPhone?'height:2.4rem':'height:0'">
    <span></span>
    <!--v-model绑定父页（topBar）的内容-->
    <span>
      <transition name="search">
        <input
            v-show="isShowSearchPhone"
            id="phone_search_input"
            @keyup.enter="$parent.toSearchBlogs()"
            v-model="$parent.searchText"
            :placeholder="isShowSearchPhone?'寻你所想，觅你所思..':''"
            :style="isShowSearchPhone?'height:1.4rem':'height:0.4rem;border:none;background:rgba(255,255,255,0)'"
        />
      </transition>
    </span>
    <span></span>
    <span>
      <transition name="search">
        <button
            v-show="isShowSearchPhone"
            @click="$parent.toSearchBlogs()" style="height:1.6rem">
        <i class="fa fa-search"></i>
      </button>
      </transition>
    </span>
    <span></span>
    <span>
      <transition name="search">
        <button
            v-show="isShowSearchPhone"
            @click="$parent.isShowSearchPhone = false" style="height:1.6rem">
        <i class="fa fa-close"></i>
      </button>
      </transition>
    </span>
    <span></span>
  </div>
</template>

<script>
export default {
  name: "phoneSearchComponent",
  props:['isShowSearchPhone'],
  watch: {
    isShowSearchPhone:function (newV,oldV){
      if (newV){
        setTimeout(() =>{
          document.getElementById('phone_search_input').focus()
        },0)
      }
      console.log(oldV)
    }
  }
}
</script>

<style scoped>
/*手机端的搜索框*/
.searchPhone{
  transition: 0.5s;
  z-index: 999;/*将此组件最优先*/
  position: fixed;/*相对浏览器绝对定位*/
  top: 5.2rem;
  background: #FFFFFF;
  box-shadow: 0 0 1rem #cfcfcf;/*设置阴影*/
  color: #777777;/*设置颜色*/
  /*设置下边圆角*/
  border-radius: 0.2rem;
  height: 2.4rem;
  width: 100%;
  /*开启弹性布局*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
/*空白设置*/
.searchPhone span:nth-child(1){
  width: 1rem;
}
/*空白设置*/
.searchPhone span:nth-child(3){
  width: 0.8rem;
}
/*空白设置*/
.searchPhone span:nth-child(5){
  width: 0.2rem;
}
/*空白设置*/
.searchPhone span:nth-child(7){
  width: 1rem;
}
/*搜索框span*/
.searchPhone span:nth-child(2){
  flex: 1;
}
/*搜索框的input框*/
.searchPhone span:nth-child(2) input{
  transition: 0.8s;
  /*边框*/
  border-radius: 0.2rem;
  border: 1px solid #EBEEF5;
  outline: none;
  /*宽高*/
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  /*字体大小及颜色*/
  color: #606266;
  font-size: 1.0rem;
}
.searchPhone span:nth-child(2) input::-webkit-input-placeholder{
  color: #C0C4CC;
}
/*搜索button框*/
.searchPhone span:nth-child(4){
  width: 1.8rem;
}
/*搜索button*/
.searchPhone span:nth-child(4) button{
  width: 100%;
  height: 1.6rem;
  background: #e6e6e6;
  font-weight: 400;
  color: #606266;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  padding: 0.2rem 0.4rem;
}
/*收起button框*/
.searchPhone span:nth-child(6){
  width: 1.8rem;
}
/*收起button*/
.searchPhone span:nth-child(6) button{
  width: 100%;
  height: 1.6rem;
  font-weight: 400;
  background: #e6e6e6;
  color: #606266;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  padding: 0.2rem 0.4rem;
}

.search-enter,.search-leave-to{
  opacity: 0;
}
.search-enter-to,.search-leave{
  opacity: 1;
}
.search-enter-active {
  transition: all 0.5s;
}
.search-leave-active{
  transition: all 0.2s;
}
/*进入手机端模式*/
@media screen and (max-width: 850px){
  /*手机端展区上调*/
  .searchPhone{
    top: 4.2rem!important;
  }
}
</style>