<template>
  <div>
    <!--顶部菜单栏-->
    <top-bar></top-bar>
    <div class="topBarHeight"></div>
    <div class="mainPanel">
      <span>
        <!--主面板，博客详情-->
        <blog-detail-panel-component
          ref="blogDetail"
        ></blog-detail-panel-component>
      </span>
      <span>
        <!--侧面板-->
        <side-panel ref="blogSidePanel"></side-panel>
      </span>
    </div>
  </div>
</template>

<script>
import SidePanel from "../sideBar/sidePanel";
import BlogDetailPanelComponent from "./blogDetailPanelComponent";
import TopBar from "../topBar";
export default {
  name: "blogDetailPanel",
  components: { TopBar, BlogDetailPanelComponent, SidePanel },
};
</script>
<style>
* {
  margin: 0;
}
</style>
<style scoped>
.mainPanel {
  /*此div居中并规划主位置*/
  margin: auto;
  /*开启flex布局*/
  display: flex;
  justify-content: center;
  /*定义宽度*/
  width: 80%;
}
.mainPanel span:nth-child(1) {
  width: 76%;
}
.mainPanel span:nth-child(2) {
  min-width: 300px !important;
  max-width: 300px;
  margin-left: 1rem;
}
.topBarHeight {
  height: 6rem;
}
/*当宽度小于1050px时，删除侧栏*/
@media screen and (max-width: 1050px) {
  .mainPanel span:nth-child(1) {
    flex: 1;
  }
  .mainPanel span:nth-child(2) {
    display: none;
  }
}
@media screen and (max-width: 850px) {
  .mainPanel span:nth-child(1) {
    flex: 1;
  }
  .topBarHeight {
    height: 4.8rem;
  }
  .mainPanel {
    width: 92%;
  }
}
</style>