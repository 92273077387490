<template>
  <el-dialog
    title="反馈意见"
    :visible.sync="feedbackDialogShow"
    width="600px"
    center
    class="dialog_main_feedback"
    @close="close()">
    <div class="mainBody">
      <el-form :model="form" :rules="rules" ref="form" label-width="80px" class="demo-ruleForm">
        <el-alert
            type="warning"
            :closable="false"
            center
            show-icon
            style="margin-bottom: 1rem;font-size: 1rem;"
        >
          <template #title>
            <div><B>功能引导</B></div>
            由于仍处于开发阶段且缺少测试数据，博客存在较多的BUG和逻辑问题，您可以在此提出您发现的任何问题以及您对改系统的完善意见，
            您的反馈将由人工受理，请耐心等待，也欢迎更多的宝贵意见。<br>
            <div style="float: right;margin-top: 0.2rem">“不满足是向上的车轮。 —— 鲁迅”</div>
          </template>
        </el-alert>
        <el-form-item label="反馈内容" prop="feedbackContent">
          <el-input v-model="form.feedbackContent" type="textarea" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
        </el-form-item>
        <el-form-item label="附加图片" prop="feedbackImage">
          <img :src="form.feedbackImage == null?addImage:form.feedbackImage" @click="uploadImg" style="cursor: pointer" alt="" />
          <el-upload class="main_img_upload"
                     :headers="{'Authorization':this.$store.state.token}"
                     :action="'http://'+this.$store.state.ip+':'+this.$store.state.port+'/blogUser/toUploadImg'" accept='image/*,.jpg,.jpeg,.png,.gif' style="text-align: left;display: none"
                     :on-success='(res,file,fileList)=>uploadSuccess2(res,file,fileList)' :show-file-list="false"
                     :on-error='(res,file,fileList)=>uploadError(res,file,fileList)'>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button @click="close" type="info" size="small" icon="el-icon-back">取消</el-button>
        <el-button @click="toReportCommit('form')" type="success" size="small" icon="el-icon-upload2">提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {clearToken, requestToken} from "../../store/request";
import {MSG} from "../../store/msg";

export default {
  props:[],
  name: "feedbackDialog",
  data(){
    return{
      isLoading: false,
      feedbackDialogShow:false,
      addImage: require("../../assets/img/addImage.png"),
      form:{
        feedbackContent:null,
        feedbackImage:null,
      },
      rules:{
        feedbackContent: [
          { required: true, message: '请提交反馈内容', trigger: 'change' }
        ],
      }
    }
  },
  methods:{
    toReportCommit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = false;
          this.$loading({
            background: 'rgba(255,255,255,0.9)',
            text: '正在提交...'
          })
          requestToken({
            url:'/blogFeedback/toAdd',
            method:'post',
            data:this.form
          }).then((res) => {
            if (res.data.code === 200) {
              MSG('success','反馈成功！');
              this.close();
            } else {
              MSG('error',res.data.msg);
              if (res.data.code === 300){
                clearToken();
              }
            }
          })
          .catch((error) => {
            MSG('error','网络错误，反馈失败！');
            console.log("reportError：" + error)
          })
          .finally( _ => {
            this.isLoading = false;
            this.$loading().close()
            console.log(_)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    open(){
      if (this.$store.state.token == null || this.$store.state.token === ''){
        MSG('warning','请先登录！')
        return false
      }
      this.feedbackDialogShow = true;
    },
    close(){
      this.feedbackDialogShow = false;
      this.form = {
        feedbackContent:null,
        feedbackImage:null,
      }
    },
    uploadImg(){
      document.querySelector(".main_img_upload input").click();
    },
    uploadSuccess2(res, f, fl) {
      if (res.stat === 200) {
        MSG('success','图片上传成功！');
        this.form.feedbackImage = res.url;
      } else {
        MSG('error','服务器异常，上传失败！');
        console.log(f+fl);
      }
    },
    uploadError(res, f, fl) {
      MSG('error','图片异常，无法上传！');
      console.log(res+f+fl);
    },
  }
}
</script>

<style lang="scss" scoped>
  .isPE{
    display: none;
  }
  .mainBody{
    .body{
      border: 1px solid #cccccc;
      border-radius: 0.4rem;
      margin-bottom: 0.5rem;
    }
    .btns{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      button{
        flex: 1;
      }
    }
  }
  /deep/ .el-alert__icon.is-big{
    font-size: 1rem;
    width: 1rem;
    line-height: 1rem;
  }
  /deep/ .el-alert .el-alert__description{
    margin: 0;
    font-size: 0.8rem;
  }
  /deep/ .el-alert--warning.is-light{
    width: 90%;
    margin: auto;
  }
  /deep/.el-dialog--center .el-dialog__body{
    padding: 0.2rem 0.8rem 0.5rem 0.8rem!important;
  }
  /deep/.el-descriptions-item{
    line-height: 0.6rem!important;
    height: 0.8rem!important;
  }

  @media screen and (max-width: 600px){
    .isPC{
      display: none;
    }
    .isPE{
      display: block;
    }
    /deep/ .el-dialog{
      width: 90%!important;
    }
    .mainBody{

    }
    .dialog_main_feedback{
      margin-top: -2rem;
    }
  }
</style>