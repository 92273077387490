<template>
  <el-empty :image-size="200">
    <template slot="description">
      <span style="color: #777777" v-if="errorStatus === 420">
        博客不存在或已被删除，<span
          @click="$router.push({ path: '/index' })"
          style="color: #158bf1; cursor: pointer"
          >前往首页</span
        >
      </span>
      <span style="color: #777777" v-if="errorStatus === 430">
        此文被作者设为私密，<span
          @click="$router.push({ path: '/index' })"
          style="color: #158bf1; cursor: pointer"
          >前往首页</span
        >
      </span>
      <span style="color: #777777" v-if="errorStatus === 500">
        请求异常，<span
          @click="$router.go(0)"
          style="color: #158bf1; cursor: pointer"
          >刷新重试</span
        >
      </span>
    </template>
    <template slot="image">
      <img v-if="errorStatus === 420" src="../../assets/img/404-2.png" alt="" />
      <img v-if="errorStatus === 430" src="../../assets/img/403.png" alt="" />
      <img v-if="errorStatus === 500" src="../../assets/img/500.png" alt="" />
    </template>
  </el-empty>
</template>

<script>
export default {
  name: "blogError",
  props: ["errorStatus"],
};
</script>

<style scoped>
</style>