<template>
  <div class="blogDetailPanelComponent">
    <div class="blogNotice">
      <span>
        &ensp;<router-link :to="{ path: '/index' }">首页</router-link>
        &ensp;<i class="fa fa-angle-right"></i>&ensp;发布博客
      </span>
      <span></span>
      <span><router-link :to="{ path: '/index' }" style="font-weight: 500">返回</router-link>&ensp;</span>
    </div>
    <div>
      <div>
        <div
            style="border-bottom: 0px solid #FFFFFF;user-select: none;font-weight: 900;font-size: 1rem;color: #009688;line-height: 2.2rem;border: 1px solid #ccc;text-align:center;border-top-right-radius: 0.5rem;border-top-left-radius: 0.5rem;height: 5rem;">
          <i class="fa fa-paper-plane" style="font-size: 1.1rem"></i>&nbsp;创作博客<br>
          <input v-model="commentForm.title" placeholder="请输入标题..."
                 style="width: 72%;outline: none;border: 1px solid #ccc;border-radius: 8px;line-height: 1.8rem;font-size: 1rem;padding-left: 1rem;padding-right: 1rem;" />
        </div>
        <div>
          <quill-editor ref="quillEditor" v-model="commentForm.content" :options="editorOption"
                        @change="onEditorChange($event)"></quill-editor>
        </div>
        <div
            style="user-select: none;display:flex;justify-content: space-between;font-weight: 900;font-size: 1rem;color: #009688;line-height: 2.5rem;border: 1px solid #ccc;text-align:center;border-bottom-right-radius: 0.5rem;border-bottom-left-radius: 0.5rem;height: 3rem;padding:0.2rem 0.2rem 0 0.2rem;margin-top: 0;">
          <el-button @click="toClickUpLoad()" type="info" icon="el-icon-picture-outline-round" style="background: #409EFF;height: 2.6rem;width: 100%"><span class="hidden_shangchuan">上传</span>封面</el-button>
          <el-button @click="toPublishBlog()" type="info" style="background: #009688;height: 2.6rem;text-align: center;width: 100%" icon="el-icon-check">发表<span
              class="hidden_shangchuan">文章</span></el-button>
        </div>
        <div style="margin-top: 1rem;text-align: center;color: #777777;font-weight: 800;">
          {{temp}}
        </div>
        <div
            style="margin-right: 8%;margin-top: 1rem;text-align: right;color: #777777;font-weight: 800;">
          ————唐·杜甫
        </div>
        <el-upload class="main_img_upload"
                   :headers="{'Authorization':this.$store.state.token}"
                   :action="'http://'+this.$store.state.ip+':'+this.$store.state.port+'/blogUser/toUploadImg'" accept='image/*,.jpg,.jpeg,.png,.gif' style="text-align: left;"
                   :on-success='(res,file,fileList)=>uploadSuccess2(res,file,fileList)' :show-file-list="false"
                   :on-error='(res,file,fileList)=>uploadError(res,file,fileList)'>
        </el-upload>
        <el-upload class="avatar-uploader quill-img"
                   :headers="{'Authorization':this.$store.state.token}"
                   :action="'http://'+this.$store.state.ip+':'+this.$store.state.port+'/blogUser/toUploadImg'" name="file"
                   :show-file-list="false" :on-success='(res,file,fileList)=>uploadSuccess(res,file,fileList)'
                   :on-error='(res,file,fileList)=>uploadError(res,file,fileList)'
                   accept='image/*,.jpg,.jpeg,.png,.gif'></el-upload>
      </div>
    </div>
    <p v-if="blog.blog_id!=null">已经到底了~</p>
  </div>
</template>

<script>
/* eslint-disable */
// import {request} from "../../../../store/request";
import {MSG} from "../../../../store/msg";
import {clearToken, requestToken, requestTokenFile} from "../../../../store/request";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import * as Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import {
  quillEditor
} from 'vue-quill-editor'

export default {
  name: "blogDetailPanelComponent",
  data(){
    return{
      blog:{},
      comments:[],
      msg:null,
      temp:'',
      isLoadingDOM:true,
      emoji: ["😄", "😁", "😆", "😅", "😂", "😘", "😡", "😒", "😭"],
      commentForm: {
        content: this.value,
        title: null,
        img: null,
        uid:null
      },
      editorOption: {
        placeholder: '说些什么吧...',
        // readOnly: true,
        modules: {
          toolbar: {
            container: [
              ['emoji', 'bold', 'italic', 'underline'], //加粗，斜体，下划线，删除线
              ['code-block'], //引用，代码块
              [{
                'header': 1
              }, {
                'header': 2
              }], // 标题，键值对的形式；1、2表示字体大小
              // [{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
              // [{ 'script': 'sub'}, { 'script': 'super' }],   // 上下标
              // [{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
              // [{ 'direction': 'rtl' }],             // 文本方向
              [{
                'size': ['small', false, 'large', 'huge']
              }], // 字体大小
              [{
                'header': [1, 2, 3, 4, 5, 6, false]
              }], //几级标题
              [{
                'color': []
              }, {
                'background': []
              }], // 字体颜色，字体背景颜色
              // [{
              // 	'font': []
              // }], //字体
              [{
                'align': []
              }], //对齐方式
              // ['clean'], //清除字体样式
              ['link','image','video'] //上传图片、上传视频
            ],
            handlers: {
              'image': function(value) {
                if (value) {
                  // 触发input框选择图片文件
                  document.querySelector(".quill-img input").click();
                } else {
                  this.quill.format("image", false);
                }
              }
            }
          },
          "emoji-toolbar": true,
          "emoji-shortname": true,
        }
      },
    }
  },
  created() {
  },
  components: {
    quillEditor
  },
  updated() {
    if (this.isLoadingDOM == true){
      this.isLoadingDOM = false;
      this.listenerForVueQuillEdirotPaste();
    }
    console.log(this.isLoadingDOM)
  },
  mounted() {
    this.toTemp();
  },
  methods:{
    toTemp(){
      this.temp = '文章千古事，得失寸心知';
    },
    listenerForVueQuillEdirotPaste(){
      //  自定义粘贴图片功能
      let quill = this.$refs.quillEditor.quill;
      this.$forceUpdate();
      quill.root.addEventListener(
          "paste",
          (evt) => {
            if (
                evt.clipboardData &&
                evt.clipboardData.files &&
                evt.clipboardData.files.length
            ) {
              evt.preventDefault();
              [].forEach.call(evt.clipboardData.files, (file) => {
                if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                  return;
                }
                //文件上传
                let param = new FormData();
                param.append("file",file);
                requestTokenFile({
                  url:'/blogUser/toUploadImg',
                  method:'post',
                  data:param
                }).then((res)=>{
                  if(res.data.stat == 200){
                    MSG('success','上传成功');
                    // 获取光标所在位置
                    let quill1 = this.$refs.quillEditor.quill;
                    let length = quill1.getSelection().index;
                    // 插入图片  res.url为服务器返回的图片地址
                    quill1.insertEmbed(length, "image", res.data.url);
                    // 调整光标到最后
                    quill1.setSelection(length + 1);
                  }else{
                    MSG('error','上传失败');
                    if (res.data.code == 300){
                      clearToken();
                    }
                  }
                })
                    .catch((error) => {
                      MSG('error','网络错误，无法访问');
                      console.log("update_blog_error:" + error)
                    })
              });
            }
          },
          false
      );
    },
    onEditorChange($event) {
      //监听富文本操作
      console.log($event)
      this.$emit("input", this.commentForm.content)
    },
    toClickUpLoad(){
      document.querySelector(".main_img_upload input").click();
    },
    toPublishBlog() {
      if (this.commentForm.title == '' || this.commentForm.title == null) {
        MSG('error','请为博客拟定标题！');
        return false;
      }
      if (this.commentForm.content == '' || this.commentForm.content == null) {
        MSG('error','文章内容不能为空！');
        return false;
      }
      if (this.commentForm.img == '' || this.commentForm.img == null) {
        MSG('error','请上传一张封面！');
        return false;
      }
      this.commentForm.uid = this.$store.state.user.uid;
      requestToken({
        url:'/blogBlog/toCreateBlog',
        method:'post',
        data:{
          form:this.commentForm
        }
      }).then((res)=>{
        if(res.data.code == 200){
          MSG('success','发布成功！');
          this.$router.push({path:'/index'});
        }else{
          MSG('error',res.data.msg);
          if (res.data.code == 300){
            clearToken();
          }
        }
      })
      .catch((error) => {
        MSG('error','网络错误，无法访问');
        console.log("publish_blog_error:" + error)
      })
    },
    uploadSuccess(res, f, fl) {
      let quill = this.$refs.quillEditor.quill;
      if (res.stat == 200) {
        MSG('success','图片上传成功！');
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片  res.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", res.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        MSG('error','服务器异常，上传失败！');
        console.log(f+fl);
      }
    },
    uploadSuccess2(res, f, fl) {
      let quill = this.$refs.quillEditor.quill;
      if (res.stat == 200) {
        MSG('success','图片上传成功！');
        this.commentForm.img = res.url;
      } else {
        MSG('error','服务器异常，上传失败！');
        console.log(f+fl+quill);
      }
    },
    uploadError(res, f, fl) {
      MSG('error','图片异常，无法上传！');
      console.log(res+f+fl);
    },
  }
}
</script>
<style scoped>
  /*整体样式*/
  .blogDetailPanelComponent{
    min-height: 40rem;
    background: #FFFFFF;
    box-shadow: 0 0 0.5rem #cccccc;/*设置阴影*/
    border-radius: 0.4rem;/*定义圆角*/
    padding: 1rem 1rem 1rem 1rem;
    margin-bottom: 0.8rem;
  }
  p{
    margin-top: 0.5rem;
    text-align: center;
  }
  /*头部区域*/
  .blogNotice{
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
  }
  .blogNotice span:nth-child(1){
    min-Width: 2rem;
  }
  /*面包屑，索引*/
  a:-webkit-any-link{
    font-weight: 900;
    text-decoration:none;
    color: #000000;
  }
  .blogNotice span:nth-child(2){
    flex: 1;
  }
  .blogNotice span:nth-child(3){
    min-Width: 2rem;
  }
  .blogNotice span:nth-child(3) span span{
    font-weight: 900;
    color: rgb(230, 162, 60);
  }

  input::-webkit-input-placeholder {
    color: #ccc;
    font-size: 1rem;
  }
  input::-moz-input-placeholder {
    color: #ccc;
  }
  input::-ms-input-placeholder {
    color: #ccc;
  }

  /deep/ .ql-editor {
    min-height: 16rem;
    max-height: 18rem;
    word-break:break-all;
  }
  @media screen and (max-width: 850px) {
    .hidden_shangchuan {
      display: none;
    }
  }
</style>
<style lang="scss">
  @import "../../../../store/msg.css";
</style>