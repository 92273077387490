<template>
  <div>
    <div class="blogPanelAll" v-if="!isLoading && total > 0">
      <div class="blogPanel" v-for="(blog, i) in blogs" :key="i">
        <div>
          <img
            :src="blog.blog_main_img"
            @click="getBlogDetail(blog.blog_id)"
            @error="defImg"
          />
        </div>
        <div>
          <span @click="getBlogDetail(blog.blog_id)">
            <button type="button" class="myTag1">
              {{ (index - 1) * 5 + i + 1 }}
            </button>
            <button
              type="button"
              class="myTag"
              v-if="blog.blog_status == 1"
              style="margin-left: 0.2rem"
            >
              隐藏
            </button>
            <button
              type="button"
              class="myTagRed"
              v-if="blog.blog_status == 2"
              style="margin-left: 0.2rem"
            >
              私密
            </button>
            {{ blog.blog_title }}
          </span>
        </div>
        <div>
          <span>{{ blog.blog_create_date_time | dateFormat }}</span>
        </div>
        <div>
          <span>
            <i class="fa fa-eye"></i><span>{{ blog.blog_view_num }}</span
            >&ensp; <i class="fa fa-thumbs-o-up"></i
            ><span>{{ blog.like_num }}</span>
          </span>
        </div>
      </div>
      <div
        class="pageHelper"
        v-show="(loading && total == 0) || (!loading && total != 0)"
      >
        <span>
          <button v-if="index > 1" class="jumpBtn" @click="toBeforePage()">
            <p class="isHidden">上一页</p>
            <p class="isNoHidden"><i class="fa fa-chevron-up"></i></p>
          </button>
          <button v-else class="notAllowedBtn">首页</button>
        </span>
        <span>
          <el-input
            placeholder="搜索"
            v-model="searchText"
            @keyup.enter="getBlogs(1, singlNum)"
            clearable
          />
          <button @click="getBlogs(1, singlNum)">
            <p class="isHidden">搜索</p>
            <p class="isNoHidden"><i class="fa fa-search"></i></p>
          </button>
        </span>
        <span>
          <button
            v-if="(index - 1) * 5 + num < total"
            class="jumpBtn"
            @click="toNextPage()"
          >
            <p class="isHidden">下一页</p>
            <p class="isNoHidden"><i class="fa fa-chevron-down"></i></p>
          </button>
          <button v-else class="notAllowedBtn">末页</button>
        </span>
      </div>
    </div>
    <!--加载中...-->
    <div v-if="isLoading">
      <p style="text-align: left; margin-top: 0.6rem">
        加载中...<i class="el-icon-loading"></i>
      </p>
      <!--      <div class="box"></div>-->
      <!--      <div class="box-text">加载中...<i class="el-icon-loading"></i></div>-->
    </div>
    <!--博客列表为空-->
    <div v-if="!isLoading && total == 0">
      <el-empty :image-size="200">
        <template slot="description">
          <span style="color: #777777">
            搜索结果为空，尝试<span
              @click="
                searchText = null;
                getBlogs(1, singlNum);
              "
              style="color: #158bf1; cursor: pointer"
              >刷新</span
            >
          </span>
        </template>
        <template slot="image"
          ><img src="../../../../assets/img/noData.png"
        /></template>
      </el-empty>
    </div>
    <!--对方设置了访问权限-->
    <div v-show="false">
      <el-empty description="对方限制了访问权限"></el-empty>
    </div>
  </div>
</template>

<script>
import { MSG } from "../../../../store/msg";
import moment from "moment";
import Vue from "vue";
import { clearToken, requestToken } from "../../../../store/request";
//需要自行引入
Vue.filter("dateFormat", function (dateStr, pattern = "YYYY-MM-DD HH:mm") {
  return moment(dateStr).format(pattern);
});
export default {
  name: "userInfoComponentSecond",
  data() {
    return {
      searchText: null,
      uId: null,
      blogs: [],
      index: 1,
      num: 0,
      total: 0,
      singlNum: 5,
      msg: null,
      isLoading: false,
      defaultImg: require("../../../../assets/img/404.png"),
    };
  },
  created() {
    this.uId =
      this.$parent.uId == null || this.$parent.uId == this.$store.state.user.uid
        ? this.$parent.$parent.$parent.uId == null ||
          this.$parent.$parent.$parent.uId == this.$store.state.user.uid
          ? null
          : this.$parent.$parent.$parent.uId
        : this.$parent.uId;
    this.getBlogs(1, this.singlNum);
  },
  methods: {
    getBlogs(index, num) {
      this.isLoading = true;
      requestToken({
        url: "/blogBlog/getSearchBlogJson",
        method: "post",
        data: {
          searchText: this.searchText == null ? "" : this.searchText,
          pageNum: index,
          num: num,
          uId: this.uId == null ? this.$store.state.user.uid : this.uId,
          type: this.uId == null ? 1 : 2,
        },
      })
        .then((res) => {
          this.isLoading = false;
          if (res.data.code == 200) {
            //让这一页标签给一个index
            this.$parent.$parent.$parent.blogNums = res.data.data.total;
            //正常走逻辑
            this.blogs = res.data.data.blogs;
            this.index = res.data.data.index;
            this.num = res.data.data.num;
            this.total = res.data.data.total;
          } else {
            MSG("error", res.data.msg);
            if (res.data.code == 300) {
              clearToken();
            }
          }
        })
        .catch((err) => {
          console.error(err);
          this.isLoading = false;
          this.msg = MSG("error", "网络错误，加载失败");
        });
    },
    getBlogDetail(blog_id) {
      this.$router.push({
        path: "/blogDetailPanel",
        query: { blog_id: blog_id },
      });
    },
    toBeforePage() {
      this.getBlogs(this.index - 1, this.singlNum);
    },
    toNextPage() {
      this.getBlogs(this.index + 1, this.singlNum);
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
  },
};
</script>

<style scoped>
/deep/ span {
  border: none !important;
}
/deep/ div {
  margin-top: 0rem !important;
}
.blogPanelAll {
  margin-top: -0.2rem !important;
  min-height: 23.4rem;
  display: block !important;
}
.blogPanel {
  display: flex;
  flex-direction: row;
  height: 4rem;
  line-height: 2rem !important;
  border-bottom: 1px solid rgb(220, 223, 230);
}
.blogPanel div:nth-child(1) {
  flex: 0.8;
  display: flex;
  align-items: center;
}
.blogPanel div:nth-child(1) img {
  width: 100%;
  height: 90%;
  cursor: pointer;
}
.blogPanel div:nth-child(2) {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blogPanel div:nth-child(2) span {
  cursor: pointer;
  width: 80% !important;
  line-height: 2rem;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
  overflow: hidden !important;
}
.blogPanel div:nth-child(3) {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blogPanel div:nth-child(4) {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blogPanel div:nth-child(4) span:nth-child(1) i {
  margin-right: 0.2rem;
  color: #777777;
}
.blogPanel div:nth-child(4) span:nth-child(1) span {
  color: #777777;
}
.myTag {
  cursor: pointer;
  height: 1.2rem;
  background-color: rgba(138, 43, 226, 0.1);
  border-color: #d9ecff;
  padding: 0 0.4rem;
  font-size: 12px;
  color: #8a2be2;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}
.myTagRed {
  cursor: pointer;
  height: 1.2rem;
  background-color: rgba(245, 108, 108, 0.2);
  border-color: #d9ecff;
  padding: 0 0.4rem;
  font-size: 12px;
  color: rgb(245, 108, 108);
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}
.myTag1 {
  cursor: pointer;
  height: 1.2rem;
  background-color: rgb(230, 162, 60);
  border-color: rgba(230, 162, 60, 0.1);
  padding: 0 0.4rem;
  font-size: 12px;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}
.jumpBtn {
  background: #009688;
  height: 1.8rem;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
}
.jumpBtn:hover {
  box-shadow: rgba(0, 150, 136, 1) 0px 1px 4px 0px;
}
.notAllowedBtn {
  background: #777777;
  height: 1.8rem;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
  cursor: not-allowed;
}
.notAllowedBtn:hover {
  box-shadow: rgba(119, 119, 119, 1) 0px 1px 4px 0px;
}
.pageHelper {
  display: flex;
  flex-direction: row;
  height: 2rem;
  align-items: center;
  margin-top: 0.4rem !important;
}
.pageHelper span:nth-child(2) {
  flex: 1;
  display: flex;
  justify-content: center;
}
/deep/.el-input__inner {
  margin-left: 0.5rem;
  height: 1.8rem;
  line-height: 1.8rem;
  border-radius: 4px;
}
/deep/.el-input__icon {
  line-height: 1.8rem;
}
/*搜索按钮*/
.pageHelper span:nth-child(2) button:nth-child(2) {
  margin: 0 0.5rem 0 0.8rem;
  width: 3rem;
  align-self: center;
  height: 1.8rem;
  background: #1e9fff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
}
.pageHelper span:nth-child(2) button:nth-child(3):hover {
  box-shadow: rgba(30, 159, 255, 1) 0px 1px 4px 0px;
}
/*遮罩层*/
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  opacity: 0.7;
}
.box-text {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  color: #1e9fff;
  font-size: 1.2rem;
  text-align: center;
}
.isNoHidden {
  display: none;
}
@media screen and (max-width: 600px) {
  .isHidden {
    display: none;
  }
  .isNoHidden {
    display: block;
  }
  .blogPanelAll {
    width: 100% !important;
    margin-top: -0.4rem !important;
  }
  /*遮罩层*/
  .box {
    display: none !important;
  }
  .box-text {
    display: none !important;
  }
  /*列表*/
  .blogPanel div:nth-child(1) {
    display: none;
  }
  .blogPanel div:nth-child(2) {
    flex: 5;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
  }
  .blogPanel div:nth-child(2) span {
    cursor: pointer;
    width: 100% !important;
    line-height: 2rem;
    text-align: left;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2 !important;
    overflow: hidden !important;
  }
  .blogPanel div:nth-child(3) {
    display: none;
  }
  .blogPanel div:nth-child(4) {
    display: none;
  }
  .blogPanel div:nth-child(4) i:nth-child(3) {
    display: none;
  }
  .blogPanel div:nth-child(4) span:nth-child(4) {
    display: none;
  }
  /*分页*/
  .pageHelper {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    height: 2rem;
    align-items: center;
  }
  .pageHelper span:nth-child(1) {
    width: 2.6rem !important;
  }
  .pageHelper span:nth-child(1) button {
    width: 2.6rem !important;
  }
  .pageHelper span:nth-child(3) {
    width: 2.6rem !important;
  }
  .pageHelper span:nth-child(3) button {
    width: 2.6rem !important;
  }
  .pageHelper span:nth-child(2) {
    flex: 3;
    display: flex;
    justify-content: center;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    margin-left: 0;
  }
  .pageHelper span:nth-child(2) input {
    width: 100%;
    height: 1.8rem;
    outline: 1px solid #1e9fff;
    border: none;
    border-radius: 4px;
    padding: 0 0.5rem;
  }
  /*搜索按钮*/
  .pageHelper span:nth-child(2) button:nth-child(2) {
    margin-left: 0.8rem;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0);
    color: #1e9fff;
    width: 1.4rem;
    font-size: 0.9rem;
    position: relative;
    text-align: center;
  }
}
</style>