<template>
  <div class="areaTotal">
    <div>——  <B>地区详情</B>  ——</div>
    <div class="isPE" style="font-size: 0.8rem">T:现有,H:治愈,D:死亡</div>
    <el-input
        placeholder="请输入地名以查询"
        v-model="filterText">
    </el-input>
    <el-tree
        class="isPC"
        ref="tree"
        :data="areas"
        :filter-node-method="filterNode"
        :props="defaultProps"
        @node-click="handleNodeClick"
    ></el-tree>
    <el-tree
        class="isPE"
        ref="tree"
        :data="areas"
        :filter-node-method="filterNode"
        :props="defaultPropsPE"
        @node-click="handleNodeClick"
    >
    </el-tree>
    <span v-show="false">https://www.jianshu.com/p/1be862aaef4e</span>
  </div>
</template>

<script>
export default {
  name: "AreaAllCard",
  props: ['areas'],
  data() {
    return {
      filterText: '',
      defaultProps: {
        children: 'children',
        //label:'label'
        label: function (data, node) {
          console.log(data, node);
          let ret = ''
          ret = data.name +
              '  [现有' + (data.total.confirm - data.total.dead - data.total.heal) + '(+' + data.today.confirm +
              ')  累计' + (data.total.confirm) +
              '  治愈' + (data.total.heal) +
              '  死亡' + (data.total.dead) +
              ']'
          return ret;
        }
      },
      defaultPropsPE: {
        children: 'children',
        //label:'label'
        label: function (data, node) {
          console.log(data, node);
          let ret = ''
          ret = (data.name === '未明确地区'?'未知':data.name === '境外输入人员'?'境外输入':data.name)
              +
              '[T' + (data.total.confirm - data.total.dead - data.total.heal) + '(+' + data.today.confirm +
              ') H' + ((data.total.heal) > 1000 ? (parseInt(data.total.heal / 1000))+'k' : (data.total.heal)) +
              ' D' + (data.total.dead) +
              ']'
          return ret;
        }
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    handleNodeClick(data) {
      console.log(data);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    rubbish(obj){
      console.log(obj)
    }
  },
}
</script>

<style lang="scss" scoped>
  .isPE{
    display: none;
  }
  .areaTotal{
    width: 590px;
    margin: auto;
    div{
      text-align: center;
      margin-bottom: 0.4rem;
    }
  }
  @media screen and (max-width: 600px){
    .isPE{
      display: block;
    }
    .isPC{
      display: none;
    }
    .areaTotal{
      width: 100%!important;
    }
  }
</style>