var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MainComponent"},[_c('div',{staticClass:"blogNotice"},[_c('span',[_vm._v("  "),_c('router-link',{attrs:{"to":{ path: '/index' }}},[_vm._v("首页")]),_vm._v("  "),_c('i',{staticClass:"fa fa-angle-right"}),_vm._v(" 在线聊天 ")],1),_c('span'),_c('span',{staticStyle:{"font-weight":"500","cursor":"pointer"},on:{"click":function($event){return _vm.toBack()}}},[_vm._v("返回 ")])]),_c('hr',{staticStyle:{"border":"0","background-color":"#dcdfe6","height":"1px"}}),_c('div',{staticClass:"chat-box"},[_c('header',[_c('span',{style:(_vm.isOnline == 1 ? 'color:#409EFF' : 'color:#909399')},[_vm._v("("+_vm._s(_vm.targetName)+") "+_vm._s(_vm.isOnline == 1 ? "在线" : "离线"))]),_c('i',{staticClass:"fa fa-circle",style:(_vm.isOnline == 1
            ? 'margin-left:0.4rem;color:#67c23a;font-size:0.5rem'
            : 'margin-left:0.4rem;color:#F56C6C;font-size:0.5rem')})]),_c('header',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isOnline || _vm.subscribe != 3),expression:"!isOnline || subscribe != 3"}]},[(!_vm.isOnline && _vm.subscribe == 3)?_c('span',[_vm._v("对方离线，上线后将看到您的消息")]):_vm._e(),(_vm.subscribe != 3)?_c('span',[_c('i',{staticClass:"fa fa-warning"}),_vm._v(" 对方和您不是好友，请谨慎交流")]):_vm._e()]),_c('div',{ref:"msg-box",class:_vm.openEmoji ? 'msg-box2' : 'msg-box'},[_c('div',{staticStyle:{"text-align":"center","color":"#909399","cursor":"pointer","margin":"0.5rem 0 0.5rem 0","font-size":"0.8rem"},on:{"click":_vm.getMoreList}},[(!_vm.isLoading)?_c('u',[_vm._v(_vm._s(_vm.haveHistory ? "加载历史记录" : "没有更多了"))]):_c('span',[_vm._v("请求中.."),_c('i',{staticClass:"el-icon-loading"})])]),_vm._l((_vm.list),function(i,index){return _c('div',{key:index},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            index == 0
              ? true
              : i.sendTime - _vm.list[index - 1].sendTime >= 60 * 5
          ),expression:"\n            index == 0\n              ? true\n              : i.sendTime - list[index - 1].sendTime >= 60 * 5\n          "}],staticClass:"time-show"},[_vm._v(" "+_vm._s(_vm._f("unixToStr")(i.sendTime))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(i.msgType > 0),expression:"i.msgType > 0"}],staticClass:"msg-notice"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("msgNotice")((i.userId + "_" + i.msgType + "_" + i.content)))+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(i.msgType === 0),expression:"i.msgType === 0"}],staticClass:"msg",style:(i.userId == _vm.userId ? 'flex-direction:row-reverse' : '')},[_c('div',{staticClass:"user-head"},[_c('img',{attrs:{"src":i.avatar,"title":i.username},on:{"click":function($event){return _vm.toUserCenter(i.userId)}}})]),_c('div',{staticClass:"user-msg"},[(i.msgDetailType == 0)?_c('span',{class:i.userId == _vm.userId ? 'right' : 'left',style:(i.userId == _vm.userId ? ' float: right;' : '')},[_vm._v(_vm._s(i.content))]):(i.msgDetailType == 1)?_c('span',{class:i.userId == _vm.userId ? 'right' : 'left',style:(i.userId == _vm.userId
                  ? ' float: right;padding:0.3rem 0.3rem;cursor:pointer'
                  : 'padding:0.2rem 0.2rem;cursor:pointer'),on:{"click":function($event){return _vm.showBigImg(i.content)}}},[_c('img',{attrs:{"src":i.content}})]):_c('span',{class:i.userId == _vm.userId ? 'right' : 'left',style:(i.userId == _vm.userId ? ' float: right;' : '')},[_vm._v(_vm._s(i.content))])])])])})],2),_c('div',{class:_vm.openEmoji ? 'tool-box2' : 'tool-box'},[_c('span',{on:{"click":function($event){return _vm.toOpenOrCloseEmoji()}}},[_c('i',{staticClass:"fa fa-smile-o"})]),_c('span',{on:{"click":function($event){return _vm.uploadImg()}}},[_c('i',{staticClass:"fa fa-image"})]),_c('span',{on:{"click":function($event){return _vm.toBlowUpPlane()}}},[_c('i',{staticClass:"iconfont"},[_vm._v("")])]),_c('el-upload',{staticClass:"main_img_upload",staticStyle:{"text-align":"left"},attrs:{"headers":{ Authorization: this.$store.state.token },"action":'http://' +
          this.$store.state.ip +
          ':' +
          this.$store.state.port +
          '/blogUser/toUploadImg',"accept":"image/*,.jpg,.jpeg,.png,.gif","on-success":function (res, file, fileList) { return _vm.uploadSuccess2(res, file, fileList); },"show-file-list":false,"on-error":function (res, file, fileList) { return _vm.uploadError(res, file, fileList); }}})],1),_c('div',{class:_vm.openEmoji || _vm.openTools ? 'input-box2' : 'input-box'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contentText),expression:"contentText"}],ref:"sendMsg",attrs:{"id":"input","type":"text"},domProps:{"value":(_vm.contentText)},on:{"focus":function($event){return _vm.openKeyboard()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendText()},"input":function($event){if($event.target.composing){ return; }_vm.contentText=$event.target.value}}}),_c('div',{staticClass:"input-emoji",on:{"click":_vm.toOpenOrCloseEmoji}},[_c('i',{class:_vm.openEmoji ? 'fa fa-keyboard-o' : 'fa fa-smile-o',style:(_vm.openEmoji ? 'font-size:1.5rem' : '')})]),_c('div',{staticClass:"input-tools",on:{"click":_vm.toOpenOrCloseTools}},[_c('i',{staticClass:"el-icon-circle-plus-outline"})]),_c('div',{staticClass:"btn",class:( _obj = {}, _obj['btn-active'] = _vm.contentText, _obj ),on:{"click":function($event){return _vm.sendText()}}},[_vm._v(" 发送 ")])]),_c('div',[_c('div',{staticClass:"emoji"},[_c('VEmojiPicker',{directives:[{name:"show",rawName:"v-show",value:(_vm.openEmoji),expression:"openEmoji"}],on:{"select":_vm.selectEmoji}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.openTools),expression:"openTools"}],staticClass:"tools"},[_c('span',[_c('span',{on:{"click":function($event){return _vm.uploadImg()}}},[_c('i',{staticClass:"fa fa-image"})])]),_c('span',[_c('span',{on:{"click":function($event){return _vm.toBlowUpPlane()}}},[_c('i',{staticClass:"iconfont"},[_vm._v("")])])]),_vm._m(0),_vm._m(1),_vm._m(2)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('span',[_c('i',{staticClass:"fa fa-ban"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('span',[_c('i',{staticClass:"fa fa-ban"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('span',[_c('i',{staticClass:"fa fa-ban"})])])}]

export { render, staticRenderFns }