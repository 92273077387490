<template>
  <div class="login_body">
    <span></span>
    <span>
      <!--网站LOGO-->
      <span>
        <img src="../../assets/icon/logo.png">
      </span>
      <!--注册表单-->
      <span class="login-form">
        <span>用户注册</span>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" style="width: 90%;">
          <!--手机号码-->
          <el-form-item class="login-form-item" prop="username">
            手机号码
            <el-input v-model="ruleForm.username"></el-input>
          </el-form-item>
          <!--验证码-->
          <el-form-item class="login-form-item" prop="checkcode">
              <span>
                <div style="float: left">验证码</div>
                <div style="float: right"><a @click="errorGetCheckCode" style="color:#007bff;cursor: pointer">无法获取验证码？</a></div>
              </span>
            <el-input v-model="ruleForm.checkcode"></el-input>
            <button v-if="!getPhoneCodeLoading" type="button" @click="toGetPhoneCode()"
                    :class="[!canGetPhoneCode?btnDisabled:'']">{{ canGetPhoneCode ? '获取验证码' : second + '秒后获取' }}</button>
            <button v-else type="button" style="cursor: not-allowed">请求中&ensp;<i class="el-icon-loading"></i></button>
          </el-form-item>
          <!--登录密码-->
          <el-form-item class="login-form-item" prop="password">
            <span>
                <div style="float: left">密码</div>
                <div style="float: right"><a @click="toRandomPassword" style="color:#007bff;cursor: pointer">随机密码</a></div>
            </span>
            <el-input v-model="ruleForm.password" :type="pwType"></el-input>
            <button type="button" @click="toChangePasswordView()">
              {{ pwType === 'text' ? '隐藏' : '显示' }}
            </button>
          </el-form-item>
          <el-form-item class="login_btn_form">
            <el-button class="login_btn" type="primary" @click="submitForm('ruleForm')">注&ensp;&ensp;册</el-button>
          </el-form-item>
          <div style="text-align: center;font-size: 0.9rem;user-select: none">
                <span style="color: #777777;font-size: 0.9rem;font-weight: 500">已有账号？</span>
                <a style="color:#007bff;cursor: pointer" @click="toLoginPage()">前往登录</a>
          </div>
       </el-form>
      </span>
    </span>
    <span></span>
  </div>
</template>

<script>
import {request} from "@/store/request";
import {MSG} from "../../store/msg";

export default {
  name: "regist",
  data() {
    return {
      pwType: 'password',
      ruleForm: {
        username: null,
        password: null,
        checkcode: null,
      },
      shortSMS: {
        checkcode: null,
      },
      btnDisabled: 'btnDisabled',
      //获取验证码↓↓↓↓↓↓
      canGetPhoneCode: true,
      second: 60,
      timer: null,
      isLoading: false,
      msf: null,
      getPhoneCodeLoading: false,
      //获取验证码↑↑↑↑↑↑
      rules: {
        username: [
          {required: true, message: '请输入手机号', trigger: 'change'}
        ],
        checkcode: [
          {required: true, message: '请输入验证码', trigger: 'change'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'change'}
        ]
      }
    };
  },
  created() {
    this.judgeCode();
  },
  mounted() {
    //监听enter并登录
    document.addEventListener('keydown', this.toSubmitRegist)
  },
  methods: {
    /**
     * 防止多次点击请求
     * @param e
     */
    toSubmitRegist(e) {
      var key = window.event.keyCode ? window.event.keyCode : window.event.which
      if (key === 13 && this.msg == null) {
        if (!this.isLoading) {
          this.submitForm('ruleForm');
        } else {
          this.toAlertHold();
        }
        e.preventDefault()
      }
    },
    /**
     * 防止浏览器卡顿时
     * 多次点击
     */
    toAlertHold() {
      this.msg = this.$alert('操作过于频繁，请稍后', '警告', {
        showConfirmButton: false,
        showClose: true,
        callback: action => {
          if (action === 'confirm') {
            console.log('confirm')
          }
          this.msg = null;
        }
      });
    },
    /**
     * 更改密码显示状态
     */
    toChangePasswordView() {
      if (this.pwType === 'text') {
        this.pwType = 'password';
      } else {
        this.pwType = 'text';
      }
    },
    /**
     * 跳转至登录页面
     */
    toLoginPage() {
      this.$router.push({path: '/login', query: {route: this.$route.fullPath}})
    },
    /**
     * 随机生成密码
     */
    toRandomPassword(){
      let abc = 'abcdefghijklmnopqrstuvwxyz'
      let num = '0123456789'
      let str = ''
      for (let i = 0; i < 4; i++) {
        let rand = parseInt(Math.random() * 1000) % 26;
        str += abc.substr(rand,1)
      }
      for (let i = 0; i < 4; i++) {
        let rand = parseInt(Math.random() * 100) % 10;
        str += num.substr(rand,1)
      }
      this.$alert('生成密码：'+str+'，请牢记','提示',{
        confirmButtonText: '我记住了',
      })
      this.ruleForm.password = str
      this.pwType = 'text'
    },
    /**
     * 无法获取验证码
     */
    errorGetCheckCode(){
      this.$alert('请联系站长，邮箱985231980@qq.com','抱歉',{
        confirmButtonText: '好的',
      })
    },
    /**
     * 提交注册表单
     * @param formName
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //加载登录...
          this.isLoading = true;
          this.$loading({
            background: 'rgba(255,255,255,0.9)',
            text: '正在注册...'
          })
          request({
            url: "/blogUser/register",
            method: 'post',
            data: {
              confirmCode: this.shortSMS.checkcode,
              phoneNum: this.ruleForm.username,
              checkCode: this.ruleForm.checkcode,
              password: this.ruleForm.password,
            }
          }).then((res) => {
            this.isLoading = false;
            this.$loading().close()
            if (res.data.code === 200) {
              //弹框
              MSG('success', res.data.msg);
              this.$confirm('账号已成功注册', '注册成功', {
                confirmButtonText: '前往登录',
                cancelButtonText: '留在此页',
                callback: action => {
                  if (action === 'confirm') {
                    this.$router.push({path: '/login'});
                  }
                }
              });
            } else {
              MSG('error', res.data.msg);
            }
          }).catch(err => {
            //服务器接口异常，请求失败
            this.isLoading = false;
            this.$loading().close()
            console.log(err)
            MSG('error', '服务器异常，请稍后重试');
          })
        } else {
          return false;
        }
      });
    },
    /**
     * 重置表单
     * @param formName
     */
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //获取验证码重要js----------------------↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
    /**
     * 获取手机验证码
     * @returns {boolean}
     */
    toGetPhoneCode() {
      let that = this;
      if (that.canGetPhoneCode) {
        //操作发送验证码
        //初步验证电话号码合法性
        if ((/^[1][3,4,5,7,8,9][0-9]{9}$/).test(that.ruleForm.username)) {
          if (this.canGetPhoneCode) {
            this.getPhoneCodeLoading = true;
            request({
              url: "/blogUser/sendShortSMS",
              method: 'post',
              data: {
                username: this.ruleForm.username,
              }
            }).then((res) => {
              if (res.data.code === 200) {
                //成功---------------
                this.shortSMS.checkcode = res.data.data.checkcode;
                MSG('success', res.data.msg);
                this.canGetPhoneCode = false;
                this.getPhoneCodeLoading = false;
              } else {
                MSG('error', res.data.msg);
                this.canGetPhoneCode = true;
                this.getPhoneCodeLoading = false;
              }
            }).catch(err => {
              //服务器接口异常，请求失败
              console.log(err)
              this.canGetPhoneCode = true;
              this.getPhoneCodeLoading = false;
              MSG('error', '服务器异常，请稍后重试');
            })
          }
        } else {
          MSG('warning', '请输入正确的手机号！');
          return false;
        }
        //成功再继续执行，否则return
        let interval = window.setInterval(function () {
          that.setStorage(that.second);
          if (that.second-- <= 0) {
            that.second = 60;
            that.canGetPhoneCode = true;
            window.clearInterval(interval);
          }
        }, 1000);
      }
    },
    /**
     * 存储持久层
     * @param parm
     */
    setStorage(parm) {
      localStorage.setItem("dalay", parm);
      localStorage.setItem("_time", new Date().getTime());
    },
    /**
     * 从持久层获取
     * @returns {{}}
     */
    getStorage() {
      let localDelay = {};
      localDelay.delay = localStorage.getItem("dalay");
      localDelay.sec = localStorage.getItem("_time");
      return localDelay;
    },
    /**
     * 校验-验证码是否对应
     */
    judgeCode() {
      let that = this;
      let localDelay = that.getStorage();
      let secTime = parseInt(
          (new Date().getTime() - localDelay.sec) / 1000
      );
      console.log(localDelay);
      if (secTime > localDelay.delay) {
        that.canGetPhoneCode = true;
        console.log("已过期");
      } else {
        that.canGetPhoneCode = false;
        let _delay = localDelay.delay - secTime;
        that.second = _delay;
        that.timer = setInterval(function () {
          if (_delay > 1) {
            _delay--;
            that.setStorage(_delay);
            that.second = _delay;
            that.canGetPhoneCode = false;
          } else {
            // 此处赋值时为了让浏览器打开的时候,直接就显示剩余的时间
            that.canGetPhoneCode = true;
            window.clearInterval(that.timer);

          }
        }, 1000);
      }
    },
    //获取验证码重要js----------------------↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
  }
}
</script>

<style scoped>
/*整体布局*/
.login_body {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f9fb;
  height: 100%;
  width: 100%;
}

/*均匀分配登陆主体左右侧距*/
.login_body span:nth-child(1) {
  flex: 1;
}

.login_body span:nth-child(3) {
  flex: 1;
}

/*表单部分设置纵向弹性布局*/
.login_body span:nth-child(2) {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*logo*/
.login_body span:nth-child(2) span:nth-child(1) img {
  width: 5rem;
  border-radius: 100%;
}

/*表单部分*/
.login-form {
  margin-top: 2rem;
  background: #FFFFFF;
  box-shadow: 0 0 0.6rem #EBEEF5;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.login-form span:nth-child(1) {
  font-size: 1.4rem;
  font-weight: 900;
}

/deep/ .el-input__inner {
  width: 100%
}

/deep/ .el-form-item__content {
  line-height: 2rem;
}

.login-form-item {
  height: 4rem;
}

.login-form-item span {
  font-weight: 500 !important;
  font-size: 0.9rem !important;
}

/*将button搁置在输入框里面*/
.login-form-item button {
  cursor: pointer;
  position: absolute;
  top: 2.5rem;
  right: 0.6rem;
  background-color: #409eff;
  border: none;
  border-radius: 4px;
  color: #FFFFFF;
  height: 1.6rem;
  font-size: 1rem;
  outline: none;
}

/*登录按钮*/
.login_btn_form {
  text-align: center;
}

.login_btn {
  display: block;
  width: 100%;
  margin-top: 1rem;
  font-size: 1.2rem;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #158bf1 , #15cfff, #158bf1);
  color: #fff;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  background-size: 200%;
  transition: 0.5s;
}
.login_btn:hover {
  background-position: right;
}

/*禁用按钮*/
.btnDisabled {
  background: #777777 !important;
  cursor: not-allowed !important;
}

/*手机端适配*/
@media screen and (max-width: 500px) {
  /*logo*/
  .login_body span:nth-child(2) span:nth-child(1) img {
    width: 4rem;
    border-radius: 100%;
  }

  /*表单部分设置纵向弹性布局*/
  .login_body span:nth-child(2) {
    width: 94%;
  }

  /*表单部分*/
  .login-form {
    margin-top: 1rem;
    background: #FFFFFF;
    box-shadow: 0 0 0.6rem #EBEEF5;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
</style>
<style>
@media screen and (max-width: 500px) {
  .el-message-box {
    display: inline-block;
    width: 90%;
    padding-bottom: 10px;
    vertical-align: middle;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    font-size: 18px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    text-align: left;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}
</style>
