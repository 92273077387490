<template>
  <div class="main_body">
    <!--对家-->
    <div class="enemyPoker">
      <ul v-for="(poker, index) in enemyPokers" :key="index">
        <li
          :style="
            !isEnemyShow
              ? 'background:linear-gradient(45deg, #3498DB 50%, #2980B9 50%); background-size:0.9rem 0.9rem;'
              : ''
          "
        >
          <div
            v-show="isEnemyShow"
            class="enemyPokerItem"
            :style="
              poker.c === '♦' || poker.c === '♥' || poker.c === '1'
                ? 'color:red'
                : 'color:black'
            "
          >
            <div>
              {{ poker.n === "1" ? "大" : poker.n === "0" ? "小" : poker.n }}
            </div>
            <div>{{ poker.c === "1" || poker.c === "0" ? "王" : poker.c }}</div>
          </div>
          <div
            v-show="!isEnemyShow"
            style="background: #000"
            class="enemyPokerItemDefault"
          >
            <div></div>
          </div>
        </li>
      </ul>
    </div>
    <!--出牌/判牌/结算区-->
    <div class="msgBox">
      <div class="userInfo1">
        <img v-show="isDiZhu == null" :src="targetAvatar" />
        <div class="inner_info">
          <div>
            {{
              isDiZhu == null
                ? enemyName
                : !isDiZhu
                ? "地主(" + holdScore + "分)"
                : "农民"
            }}<i v-if="!isDiZhu" class="iconfont">&#xe60e;</i
            ><i v-else class="iconfont">&#xe86f;</i>
          </div>
          <div>剩{{ enemyPokers.length }}张</div>
        </div>
      </div>
      <div class="diPai" v-show="handShow">
        底牌
        <div
          v-for="(poker, index) in hand"
          :key="index"
          :style="
            poker.c === '♦' || poker.c === '♥' || poker.c === '1'
              ? 'color:red'
              : 'color:black'
          "
        >
          <div
            class="__son"
            :style="
              index !== 2
                ? 'background:rgba(255, 153, 0,0.3)'
                : 'background:rgba(52, 152, 219,0.2)'
            "
          >
            <div class="shape"></div>
            <div class="label">
              <i v-if="index !== 2" class="iconfont">&#xe60e;</i
              ><i v-else class="iconfont">&#xe86f;</i>
            </div>
            <div>
              {{ poker.n === "1" ? "大" : poker.n === "0" ? "小" : poker.n }}
            </div>
            <div>{{ poker.c === "1" || poker.c === "0" ? "王" : poker.c }}</div>
          </div>
        </div>
      </div>
      <div class="diPai1" style="text-align: right">
        Demo测试开发版本<br />仅支持六张以内逻辑判断
      </div>
      <div class="enemyMsg" v-if="!isGameOver">
        <span v-if="!isYourRound" class="timer">
          <div>{{ second }}</div>
        </span>
        <span v-else>{{
          enemyMsgType === 0
            ? ""
            : enemyMsgType === 1
            ? "压上"
            : enemyMsgType === null
            ? ""
            : "不要"
        }}</span>
      </div>
      <div class="lastPokerArea">
        <ul v-for="(poker, index) in lastPoker" :key="index">
          <li>
            <div
              class="myPokerItem"
              :style="
                poker.c === '♦' || poker.c === '♥' || poker.c === '1'
                  ? 'color:red'
                  : 'color:black'
              "
            >
              <div>
                {{ poker.n === "1" ? "大" : poker.n === "0" ? "小" : poker.n }}
              </div>
              <div>
                {{ poker.c === "1" || poker.c === "0" ? "王" : poker.c }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="yourMsg" v-if="!isGameOver">
        <span v-if="isYourRound" class="timer">
          <div :style="second <= 10 ? 'color:#ee3131' : ''">{{ second }}</div>
        </span>
        <span v-else>{{
          yourMsgType === 0
            ? ""
            : yourMsgType === 1
            ? "压上"
            : yourMsgType === null
            ? ""
            : "不要"
        }}</span>
      </div>
      <div class="chooseBtn" v-if="isYourRound && !isGameOver">
        <button
          v-show="lastPoker.length === 0 && !isHold"
          @click="callScore(-1)"
        >
          不叫
        </button>
        <button
          v-show="lastPoker.length === 0 && !isHold && holdScore <= 0"
          @click="callScore(1)"
        >
          一分
        </button>
        <button
          v-show="lastPoker.length === 0 && !isHold && holdScore <= 1"
          @click="callScore(2)"
        >
          二分
        </button>
        <button
          v-show="lastPoker.length === 0 && !isHold && holdScore <= 2"
          @click="callScore(3)"
        >
          三分
        </button>
        <button v-show="lastPoker.length > 0 && isHold" @click="cancelSend()">
          不要
        </button>
        <button v-show="isHold" @click="sendPoker()">出牌</button>
      </div>
      <div class="userInfo2">
        <img v-show="isDiZhu == null" :src="handleAvatar" />
        <div class="inner_info">
          <div>
            {{
              isDiZhu == null
                ? userName
                : isDiZhu
                ? "地主(" + holdScore + "分)"
                : "农民"
            }}<i v-if="isDiZhu" class="iconfont">&#xe60e;</i
            ><i v-else class="iconfont">&#xe86f;</i>
          </div>
          <div>剩{{ yourPokers.length }}张</div>
        </div>
      </div>
    </div>
    <!--我家-->
    <div class="myPoker">
      <ul v-for="(poker, index) in yourPokers" :key="index">
        <li
          @click="selectPoker(index)"
          :style="poker.s === 1 ? 'margin-top:-0.8rem' : ''"
        >
          <div
            class="myPokerItem"
            :style="
              poker.c === '♦' || poker.c === '♥' || poker.c === '1'
                ? 'color:red'
                : 'color:black'
            "
          >
            <div>
              {{ poker.n === "1" ? "大" : poker.n === "0" ? "小" : poker.n }}
            </div>
            <div>{{ poker.c === "1" || poker.c === "0" ? "王" : poker.c }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { MSG } from "../../../../../store/msg";

export default {
  name: "pokerDdzGamePanel",
  props: [
    "targetId",
    "userId",
    "userName",
    "enemyName",
    "handleAvatar",
    "targetAvatar",
  ],
  data() {
    return {
      isEnemyShow: false, //对方是否明牌
      yourPokers: [],
      enemyPokers: [],
      hand: [],
      lastPoker: [],
      yourMsgType: null,
      enemyMsgType: null,
      isYourRound: null,
      isHold: false, //地主是否分配完成
      holdScore: 0, //目前叫分
      callNum: 0, //叫分次数
      isGameOver: false,
      handShow: false, //是否展示底牌
      isDiZhu: null, //是地主true还是农民false
      second: 25,
      timer1: null,
    };
  },
  mounted() {
    this.init();
  },
  destroyed() {
    clearInterval(this.timer1);
  },
  methods: {
    init(data) {
      this.second = 25;
      clearInterval(this.timer1);
      this.timer1 = setInterval(() => {
        if (this.second >= 1) {
          this.second--;
        } else {
          this.forceSendPoker();
          clearInterval(this.timer1);
        }
      }, 1000);
      this.yourPokers = this.userId > this.targetId ? data.userA : data.userB;
      this.enemyPokers = this.userId < this.targetId ? data.userA : data.userB;
      this.hand = data.hand;
    },
    sendPoker() {
      let sendPoker = [];
      for (let i = 0; i < this.yourPokers.length; i++) {
        if (this.yourPokers[i].s === 1) {
          sendPoker.push(this.yourPokers[i]);
        }
      }
      if (sendPoker.length === 0) {
        //没出牌
        MSG("warning", "请先选牌");
        return false;
      }
      let obj = {
        sendPoker: sendPoker,
        lastPoker: this.lastPoker,
        type: 2,
        lastNum: this.yourPokers.length - sendPoker.length,
      };
      this.$emit("sendMsg", obj);
    },
    callScore(score) {
      let obj = {
        type: -1,
        score: score,
        callNum: this.callNum + 1,
      };
      this.$emit("sendMsg", obj);
      clearInterval(this.timer1);
    },
    callBackScore(data, isYour) {
      this.second = 25;
      clearInterval(this.timer1);
      this.timer1 = setInterval(() => {
        if (this.second >= 1) {
          this.second--;
        } else {
          this.forceSendPoker();
          clearInterval(this.timer1);
        }
      }, 1000);
      let score = data.score;
      this.callNum += data.callNum;
      if (isYour) {
        //你叫好了
        if (score >= 3) {
          //叫最高分，结束叫分阶段，把底牌分配，不转移出牌权限
          this.isDiZhu = true;
          this.holdScore = 3;
          this.isHold = true;
          this.isYourRound = true;
          this.pokerCollection(true);
        } else if (score >= 0) {
          this.holdScore = score;
          this.isYourRound = false;
        } else {
          //-1：不叫
          if (this.callNum > 1) {
            //都没叫牌或者上家叫过分了，最低一分成交发给对方
            this.holdScore = data.score <= 1 ? 1 : data.score;
            this.isDiZhu = false;
            this.isHold = true;
            this.pokerCollection(false);
          }
          this.isYourRound = false;
        }
      } else {
        //对方叫好了
        if (score >= 3) {
          //叫最高分，结束叫分阶段，把底牌分配，不转移出牌权限
          this.holdScore = 3;
          this.isDiZhu = false;
          this.isHold = true;
          this.isYourRound = false;
          this.pokerCollection(false);
        } else if (score >= 0) {
          this.holdScore = score;
          this.isYourRound = true;
        } else {
          //-1：不叫
          if (this.callNum > 1) {
            //都没叫牌或者上家叫过分了，最低一分成交发给对方
            this.holdScore = data.score <= 1 ? 1 : data.score;
            this.isDiZhu = true;
            this.isHold = true;
            this.pokerCollection(true);
          }
          this.isYourRound = true;
        }
      }
    },
    pokerCollection(isYour) {
      let sort = [
        "1",
        "0",
        "2",
        "A",
        "K",
        "Q",
        "J",
        "10",
        "9",
        "8",
        "7",
        "6",
        "5",
        "4",
        "3",
      ];
      this.handShow = true;
      if (isYour) {
        //地主是你，底牌前两个给你，后一个给对方
        for (let i = 0; i < this.hand.length - 1; i++) {
          this.$set(this.yourPokers, this.yourPokers.length, this.hand[i]);
        }
        //排序
        let temp = [];
        for (let i = 0; i < sort.length; i++) {
          for (let j = 0; j < this.yourPokers.length; j++) {
            if (this.yourPokers[j].n === sort[i]) {
              temp.push(this.yourPokers[j]);
            }
          }
        }
        //给农民
        this.$set(this.enemyPokers, this.enemyPokers.length, this.hand[2]);
        //排序
        let temp1 = [];
        for (let i = 0; i < sort.length; i++) {
          for (let j = 0; j < this.enemyPokers.length; j++) {
            if (this.enemyPokers[j].n === sort[i]) {
              temp1.push(this.enemyPokers[j]);
            }
          }
        }
        this.yourPokers = temp;
        this.enemyPokers = temp1;
      } else {
        //底牌给对面
        for (let i = 0; i < this.hand.length - 1; i++) {
          this.$set(this.enemyPokers, this.enemyPokers.length, this.hand[i]);
        }
        //排序
        let temp = [];
        for (let i = 0; i < sort.length; i++) {
          for (let j = 0; j < this.enemyPokers.length; j++) {
            if (this.enemyPokers[j].n === sort[i]) {
              temp.push(this.enemyPokers[j]);
            }
          }
        }

        this.$set(this.yourPokers, this.yourPokers.length, this.hand[2]);
        //排序
        let temp1 = [];
        for (let i = 0; i < sort.length; i++) {
          for (let j = 0; j < this.yourPokers.length; j++) {
            if (this.yourPokers[j].n === sort[i]) {
              temp1.push(this.yourPokers[j]);
            }
          }
        }
        this.enemyPokers = temp;
        this.yourPokers = temp1;
      }
      this.$forceUpdate();
    },
    cancelSend() {
      let obj = {
        sendPoker: [],
        lastPoker: this.lastPoker,
        type: 2,
        lastNum: this.yourPokers.length - this.lastPoker.length,
      };
      this.$emit("sendMsg", obj);
    },
    selectPoker(index) {
      this.yourPokers[index].s = this.yourPokers[index].s === 0 ? 1 : 0;
    },
    forceSendPoker() {
      //强制出牌的方法
      if (this.isYourRound) {
        if (this.isHold) {
          if (this.lastPoker.length === 0) {
            //出牌阶段，出最后一张即可
            let sendPoker = [];
            sendPoker.push(this.yourPokers[this.yourPokers.length - 1]);
            let obj = {
              sendPoker: sendPoker,
              lastPoker: this.lastPoker,
              type: 2,
              lastNum: this.yourPokers.length - sendPoker.length,
            };
            console.log(obj);
            this.$emit("sendMsg", obj);
          } else {
            //过牌
            let obj = {
              sendPoker: [],
              lastPoker: this.lastPoker,
              type: 2,
              lastNum: this.yourPokers.length - this.lastPoker.length,
            };
            this.$emit("sendMsg", obj);
          }
        } else {
          //叫分阶段，直接不叫
          this.callScore(-1);
        }
      }
    },
    //出牌回调
    sendPokers(data, isYour) {
      if (isYour) {
        if (data.code === 0) {
          this.isYourRound = false;
          this.second = 25;
          clearInterval(this.timer1);
          this.timer1 = setInterval(() => {
            if (this.second >= 1) {
              this.second--;
            } else {
              this.forceSendPoker();
              clearInterval(this.timer1);
            }
          }, 1000);
          if (data.pokerType <= 1) {
            //出牌/压牌成功
            //赢了，告知
            if (data.isWin) {
              clearInterval(this.timer1);
              this.isEnemyShow = true; //展示对方剩余牌
              this.isGameOver = true; //不展示出牌
              this.$confirm("你赢了！", "胜利", {
                confirmButtonText: "离开",
                cancelButtonText: "留在此页",
                callback: (action) => {
                  if (action === "confirm") {
                    this.$emit("toBack");
                  }
                },
              });
            }
            this.lastPoker = data.lastPoker;
            for (let i = 0; i < this.yourPokers.length; i++) {
              for (let j = 0; j < this.lastPoker.length; j++) {
                if (
                  this.yourPokers[i].n === this.lastPoker[j].n &&
                  this.yourPokers[i].c === this.lastPoker[j].c
                ) {
                  //移除
                  this.yourPokers.splice(i, 1);
                }
              }
            }
            this.yourMsgType = data.pokerType;
          } else if (data.pokerType === 2) {
            //过牌
            this.yourMsgType = data.pokerType;
            this.lastPoker = [];
          }
          this.$forceUpdate();
        } else {
          MSG("warning", "出牌不合法");
          //不合法/失败
        }
      } else {
        if (data.code === 0) {
          this.isYourRound = true;
          this.second = 25;
          clearInterval(this.timer1);
          this.timer1 = setInterval(() => {
            if (this.second >= 1) {
              this.second--;
            } else {
              this.forceSendPoker();
              clearInterval(this.timer1);
            }
          }, 1000);
          if (data.pokerType <= 1) {
            //出牌/压牌成功
            //输了，告知
            if (data.isWin) {
              clearInterval(this.timer1);
              this.isEnemyShow = true; //展示对方剩余牌
              this.isGameOver = true; //不展示出牌
              this.$confirm("你输了！", "败北", {
                confirmButtonText: "离开",
                cancelButtonText: "留在此页",
                callback: (action) => {
                  if (action === "confirm") {
                    this.$emit("toBack");
                  }
                },
              });
            }
            this.lastPoker = data.lastPoker;
            for (let i = 0; i < this.enemyPokers.length; i++) {
              for (let j = 0; j < this.lastPoker.length; j++) {
                if (
                  this.enemyPokers[i].n === this.lastPoker[j].n &&
                  this.enemyPokers[i].c === this.lastPoker[j].c
                ) {
                  //移除
                  this.enemyPokers.splice(i, 1);
                }
              }
            }
            this.enemyMsgType = data.pokerType;
          } else if (data.pokerType === 2) {
            //过牌
            this.enemyMsgType = data.pokerType;
            this.lastPoker = [];
          }
          this.$forceUpdate();
        }
      }
    },
  },
};
</script>

<style scoped>
/*遮罩层*/
.box {
  position: absolute;
  z-index: 998;
  top: 0;
  bottom: -16rem;
  left: 0;
  right: 0;
  background: #ffffff;
  opacity: 0.7;
}

.box-text {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  color: #1e9fff;
  font-size: 1.2rem;
  text-align: center;
}
</style>
<style lang="scss" scoped>
.main_body {
  height: 30rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  .enemyPoker {
    height: 7rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;

    ul {
      position: relative;
      padding-inline-start: 4%;
    }

    ul li {
      position: absolute;
      width: 4rem;
      height: 5.6rem;
      top: 0.5rem;
      right: -2rem;
      background: #f2f6fc;
      color: #000;
      border: 1px solid #dcdfe6;
      border-radius: 0.4rem;
      list-style: none;
      cursor: pointer;
      user-select: none;

      .enemyPokerItem {
        margin-top: 0.1rem;
        margin-left: 0.2rem;
        line-height: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 0.8rem;
      }
    }
  }

  .msgBox {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .diPai {
      position: absolute;
      top: 2.8rem;
      right: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .__son {
        position: relative;
        height: 3rem;
        width: 1.8rem;
        border-radius: 0.2rem;
        .shape {
          position: absolute;
          z-index: 998;
          bottom: 0;
          right: 0;
          width: 0;
          height: 0;
          border-bottom: 1.5rem solid #ff9900;
          border-left: 1.5rem solid transparent;
        }
        .label {
          position: absolute;
          z-index: 998;
          bottom: 0;
          right: -0.05rem;
          text-align: right;
          font-size: 0.6rem !important;
          transform: rotate(-45deg);
          color: #ffffff;
        }
        div {
          width: 1.2rem;
          height: 0.8rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
    .diPai1 {
      position: absolute;
      top: 6.5rem;
      color: #e6a23c;
      right: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .enemyMsg,
    .yourMsg,
    .chooseBtn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .timer {
      margin-bottom: 0.2rem;
      background: #909399;
      border-radius: 100%;
      height: 2rem;
      width: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #fafafa;
      font-weight: 900;
    }
    .chooseBtn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      button {
        border: none;
        border-radius: 10rem;
        padding: 0.4rem;
        width: 3rem;
        color: #ffffff;
        cursor: pointer;
        font-weight: bold;
        letter-spacing: 0.1rem;
      }
      button:nth-child(1) {
        background: #909399;
        margin-right: 1rem;
      }
      button:nth-child(2) {
        background: #3498db;
        margin-right: 1rem;
      }
      button:nth-child(3) {
        background: #3498db;
        margin-right: 1rem;
      }
      button:nth-child(4) {
        background: #3498db;
      }
      button:nth-child(5) {
        background: #909399;
        margin-right: 1rem;
      }
      button:nth-child(6) {
        background: #009688;
      }
    }
    .lastPokerArea {
      height: 7rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      ul {
        position: relative;
        padding-inline-start: 3%;
      }
      ul li {
        position: absolute;
        width: 4rem;
        height: 5.6rem;
        top: 0.5rem;
        right: -2rem;
        background: #f2f6fc;
        color: #000;
        border: 1px solid #dcdfe6;
        border-radius: 0.4rem;
        list-style: none;
        cursor: pointer;
        user-select: none;

        .myPokerItem {
          margin-top: 0.1rem;
          margin-left: 0.2rem;
          line-height: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 0.8rem;
        }
      }
    }
  }

  .userInfo1 {
    top: 0;
  }
  .userInfo2 {
    bottom: 0;
  }
  .userInfo1,
  .userInfo2 {
    z-index: -1;
    right: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    img {
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 100%;
    }
    .inner_info {
      display: flex;
      flex-direction: column;
      height: 1.8rem;
      margin-left: 0.5rem;
      div {
        height: 1rem;
        text-align: center;
        line-height: 1rem;
      }
      div:nth-child(1) {
        font-size: 0.8rem;
        font-weight: bold;
        color: #777777;
      }
      div:nth-child(2) {
        font-size: 0.8rem;
      }
    }
  }

  .myPoker {
    height: 7rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;

    ul {
      position: relative;
      padding-inline-start: 4%;
    }

    ul li {
      position: absolute;
      width: 4rem;
      height: 5.6rem;
      top: 0.5rem;
      right: -2rem;
      background: #f2f6fc;
      color: #000;
      border: 1px solid #dcdfe6;
      border-radius: 0.4rem;
      list-style: none;
      cursor: pointer;
      user-select: none;

      .myPokerItem {
        margin-top: 0.1rem;
        margin-left: 0.2rem;
        line-height: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 0.8rem;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .userInfo1,
    .userInfo2 {
      .inner_info {
        margin-left: 0;
      }
    }
  }
  @media screen and (max-height: 550px) {
    .msgBox {
      flex: 0.4;
      .diPai {
        top: 2.3rem;
      }
      .diPai1 {
        top: 0;
        left: 0;
        z-index: -1;
        font-size: 0.8rem !important;
        text-align: left !important;
        justify-content: flex-start !important;
      }
    }
  }
}
</style>
