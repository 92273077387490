<template>
  <div class="bottom_Icp_bottom">
    <span>Copyright © 2022 万彻小站 | 个人博客</span>
    <span>
      <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
        <img src="../../../assets/icon/beian.png" /><p>蒙ICP备2020004664号-2</p>
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: "bottomIcpIndex"
}
</script>
<style lang="scss" scoped>
  .bottom_Icp_bottom{
    display: flex;
    flex-direction: column;
    height: 3.2rem;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    line-height: 1.4rem;
    background: rgba(254, 254, 254,0.8);
    color: #909399;
    padding:0.2rem 0 0.2rem 0;
    span:nth-child(2) a{
      color: #909399;
      text-decoration:none;
      transition: 0.5s;
      display: flex;
      flex-direction: row;
      justify-content: center;
      img{
        width: 1rem;
        height: 1rem;
        margin-top: 0.15rem;
        margin-right: 0.1rem;
      }
    }
    span:nth-child(2) a:hover{
      color: #37a1f9;
    }
  }
</style>