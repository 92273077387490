<template>
  <div class="login_body">
    <span></span>
    <span>
      <!--登录LOGO-->
      <span>
        <img src="../../assets/icon/logo.png">
      </span>
      <!--登录表单-->
      <span class="login-form">
        <span>用户登录</span>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" style="width: 90%;">
         <!--手机号码-->
          <el-form-item class="login-form-item" prop="username">
            手机号码
            <el-input v-model="ruleForm.username"></el-input>
          </el-form-item>
          <!--登录密码-->
          <el-form-item class="login-form-item" prop="password">
            <span>
                <div style="float: left">密码</div>
                <div style="float: right"><a @click="forgetPassword" style="color:#007bff;cursor: pointer">忘记密码？</a></div>
            </span>
            <el-input v-model="ruleForm.password" :type="pwType"></el-input>
            <button type="button" @click="toChangePasswordView()">
              {{ pwType === 'text' ? '隐藏' : '显示' }}
            </button>
          </el-form-item>
          <el-form-item class="login_btn_form">
            <el-button class="login_btn" type="primary" @click="submitForm('ruleForm')">登&ensp;&ensp;录</el-button>
          </el-form-item>
          <div style="text-align: center;font-size: 0.9rem;user-select: none;">
            <div style="color: #777777;float: left;font-size: 0.9rem;font-weight: 500;cursor: pointer" @click="toBack()">返回</div>
            <div style="color:#007bff;cursor: pointer;float: right" @click="toRegistPage()">注册</div>
            <div style="clear:both;"></div>
          </div>
       </el-form>
      </span>
    </span>
    <span></span>
  </div>
</template>

<script>
import {request} from "@/store/request";
import {MSG} from "../../store/msg";
import {returnBack} from "../../store/request";

export default {
  name: "login",
  data() {
    return {
      //倒计时
      countdown: '5',
      myTimer: null,
      //密码框类型（显示隐藏属性：password or text）
      pwType: 'password',
      isLoading: false,
      //弹出框载体
      msg: null,
      //表单属性
      ruleForm: {
        username: '',
        password: '',
        // checkcode: ''
      },
      //表单验证属性
      rules: {
        username: [
          {required: true, message: '请输入手机号', trigger: 'change'}
        ],
        // checkcode: [
        //   { required: true, message: '请输入验证码', trigger: 'change' }
        // ],
        password: [
          {required: true, message: '请输入密码', trigger: 'change'}
        ]
      }
    };
  },
  mounted() {
    //监听enter并登录
    document.addEventListener('keydown', this.toSubmitLogin)
  },
  methods: {
    /**
     * 点击登录按钮
     * 防止多次点击
     * @param e
     */
    toSubmitLogin(e) {
      var key = window.event.keyCode ? window.event.keyCode : window.event.which
      if (key === 13 && this.msg == null) {
        if (!this.isLoading) {
          this.submitForm('ruleForm');
        } else {
          this.toAlertHold();
        }
        e.preventDefault()
      }
    },
    /**
     * 在浏览器卡顿时
     * 防止多次点击提示警告
     */
    toAlertHold() {
      this.msg = this.$alert('操作过于频繁，请稍后', '警告', {
        showConfirmButton: false,
        showClose: true,
        callback: action => {
          if (action === 'confirm') {
            console.log('confirm')
          }
          this.msg = null;
        }
      });
    },
    /**
     * 修改密码框类型
     */
    toChangePasswordView() {
      if (this.pwType === 'text') {
        this.pwType = 'password';
      } else {
        this.pwType = 'text';
      }
    },
    /**
     * 忘记密码
     */
    forgetPassword(){
      this.$alert('请联系站长，邮箱985231980@qq.com','抱歉',{
        confirmButtonText: '好的',
      })
    },
    /**
     * 返回上一页
     */
    toBack(){
     returnBack()
    },
    /**
     * 跳转注册
     */
    toRegistPage() {
      this.$router.push({path: '/regist', query: {route: this.$route.fullPath}})
    },
    /**
     * 提交表单，验证登录
     * @param formName
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //加载登录...
          this.isLoading = true;
          this.$loading({
            background: 'rgba(255,255,255,0.9)',
            text: '正在登陆...'
          })
          //请求
          request({
            url: "/blogUser/login",
            method: 'post',
            data: {
              username: this.ruleForm.username,
              password: this.ruleForm.password
            }
          }).then((res) => {
            //关闭加载
            this.isLoading = false;
            this.$loading().close()
            if (res.data.code === 200) {
              //成功---------------
              //将信息存储持久层，防止关闭浏览器获取不到
              localStorage.setItem('token', res.data.data.token);
              localStorage.setItem('user', JSON.stringify(res.data.data.userInfo));
              //将持久层信息拿到store，取值方便
              this.$store.state.token = localStorage.getItem('token');
              this.$store.state.user = JSON.parse(localStorage.getItem('user'))
              //console调试信息--如不需要可以删除
              console.log(res.data.data.token)
              console.log(this.$store.state.token)
              console.log(localStorage.getItem('token'))
              //弹框
              MSG('success', res.data.msg)
              //跳转上一路由
              let jump = this.$route.query.route != null ? this.$route.query.route : '/index';
              if (jump.startsWith('/regist')) {
                this.$router.push({path: '/index'});
              } else {
                this.$router.replace({path: jump});
              }
            } else {
              MSG('error', res.data.msg)
            }
          }).catch(err => {
            //服务器接口异常，请求失败
            console.log(err)
            this.isLoading = false;
            this.$loading().close()
            MSG('error', '网络错误');
          })
        } else {
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style scoped>
/*整体布局*/
.login_body {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f9fb;
  height: 100%;
  width: 100%;
}

/*均匀分配登陆主体左右侧距*/
.login_body span:nth-child(1) {
  flex: 1;
}

.login_body span:nth-child(3) {
  flex: 1;
}

/*表单部分设置纵向弹性布局*/
.login_body span:nth-child(2) {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*logo*/
.login_body span:nth-child(2) span:nth-child(1) img {
  width: 5rem;
  border-radius: 100%;
}

/*表单部分*/
.login-form {
  margin-top: 2rem;
  background: #FFFFFF;
  box-shadow: 0 0 0.6rem #EBEEF5;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.login-form span:nth-child(1) {
  font-size: 1.4rem;
  font-weight: 900;
}

/deep/ .el-input__inner {
  width: 100%
}

/deep/ .el-form-item__content {
  line-height: 2rem;
}

.login-form-item {
  height: 4rem;
}

.login-form-item span {
  font-weight: 500 !important;
  font-size: 0.9rem !important;
}

/*将button搁置在输入框里面*/
.login-form-item button {
  cursor: pointer;
  position: absolute;
  top: 2.5rem;
  right: 0.6rem;
  background-color: #409eff;
  border: none;
  border-radius: 4px;
  color: #FFFFFF;
  height: 1.6rem;
  font-size: 1rem;
  outline: none;
}

/*登录按钮*/
.login_btn_form {
  text-align: center;
}

.login_btn {
  display: block;
  width: 100%;
  margin-top: 1rem;
  font-size: 1.2rem;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #158bf1 , #15cfff, #158bf1);
  color: #fff;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  background-size: 200%;
  transition: 0.5s;
}
.login_btn:hover {
  background-position: right;
}

/*手机端适配*/
@media screen and (max-width: 500px) {
  /*logo*/
  .login_body span:nth-child(2) span:nth-child(1) img {
    width: 4rem;
    border-radius: 100%;
  }

  /*表单部分设置纵向弹性布局*/
  .login_body span:nth-child(2) {
    width: 94%;
  }

  /*表单部分*/
  .login-form {
    margin-top: 1rem;
    background: #FFFFFF;
    box-shadow: 0 0 0.6rem #EBEEF5;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
</style>
<style lang="scss">
@import "../../store/msg.css";
</style>
