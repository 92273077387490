<template>
  <el-page-header title="返回" @back="$emit('toBack')" style="margin-bottom: 0.5rem">
    <template #content>
      <div style="font-size: 1rem;line-height: 1.4rem">
        <span v-if="isCenter">个人中心</span>
        <span v-else>
            <span v-if="checkCh(user.uname) <= 0">用户***{{user.utel.substring(user.utel.length - 4)}}</span>
            <span v-else-if="checkCh(user.uname) <= 8">{{user.uname}}</span>
            <span v-else>{{subStr(user.uname)}}</span>的资料
          </span>
      </div>
    </template>
  </el-page-header>
</template>

<script>
export default {
  name: "userPageHeader",
  props: ['user','isCenter'],
  methods:{
    checkCh(str){
      if(str === '' || str == null){
        return 0;
      }
      var len = 0;
      for(var i = 0; i < str.length; i++){
        if(str.charCodeAt(i) > 255){
          len += 2;
        }else{
          len++;
        }
      }
      return len;
    },
  }
}
</script>

<style scoped>

</style>