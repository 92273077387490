<template>
  <div
    id="app"
    style="
      background-image: url('http://www.yixiaoxi.com/wp-content/themes/9IPHP-master/inc/theme-options/images/pattern/large/light_noise_diagonal.png');
    "
  >
    <router-view :key="$router.currentRoute.fullPath"></router-view>
    <bottom-icp-index></bottom-icp-index>
  </div>
</template>
<script>
import store from "@/store";
import BottomIcpIndex from "./components/publicComponent/bottomICP/bottomIcpIndex";
export default {
  name: "app",
  components: { BottomIcpIndex },
  store,
  created() {
    this.$store.state.token = localStorage.getItem("token");
    this.$store.state.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {
    window.addEventListener("unload", this.saveState);
  },
  methods: {
    saveState() {
      sessionStorage.setItem("state", JSON.stringify(this.$store.state));
    },
  },
};
</script>

<style>
* {
  margin: 0;
  /*强制中英文换行*/
  word-break: break-all;
}
html,
body,
#app {
  /* 这里的百分百也没用了 */
  /* height: 100%; */
  /* 这里的scroll不能加，会影响页面内scroll */
  /* overflow-y: auto; */
}
/*强制body的滚动条宽度为0*/
::-webkit-scrollbar {
  width: 0;
}
@font-face {
  font-family: "iconfont"; /* Project id 3157013 */
  src: url("//at.alicdn.com/t/font_3157013_7exqiwulpg8.woff2?t=1648907575895")
      format("woff2"),
    url("//at.alicdn.com/t/font_3157013_7exqiwulpg8.woff?t=1648907575895")
      format("woff"),
    url("//at.alicdn.com/t/font_3157013_7exqiwulpg8.ttf?t=1648907575895")
      format("truetype");
}
.iconfont {
  font-family: "iconfont", serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
</style>
