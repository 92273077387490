<template>
  <div class="MainComponent">
    <div class="blogNotice">
      <span>
        &ensp;<router-link :to="{ path: '/index' }">首页</router-link>
        &ensp;<i class="fa fa-angle-right"></i>&ensp;搜索用户
      </span>
      <span></span>
      <span @click="toBack()" style="font-weight: 500;cursor: pointer">返回&ensp;</span>
    </div>
    <hr style="border:0;background-color:#DCDFE6;height:1px;">
    <div class="searchBody">
      <!--搜索框-->
      <div class="search-input">
        <input placeholder="搜一搜" @keyup.enter="index=1;toSearchUsers(index)" @input="isSearching = true;" v-model="searchText">
        <button @click="index=1;toSearchUsers(index)">搜索</button>
      </div>
      <!--搜索结果-->
      <div class="search-result-list-father">
        <div v-show="!isLoading && total!=0" v-for="(user,ind) in users" :key="ind" class="search-result-list">
          <div class="search-result">
            <!--头像，姓名，手机号-->
            <div class="search-result-left">
              <!--头像-->
              <div class="search-result-left-img" @click="toUserCenter(user.uid)">
                <img v-if="user.uavatar!=null" :src="user.uavatar">
                <img v-else src="../../../../assets/icon/user.png">
              </div>
              <!--姓名、手机号-->
              <div class="search-result-left-info">
              <span>
                <span @click="toUserCenter(user.uid)">{{user.uname!=null&&user.uname!=""?user.uname:'用户'+user.utel.substring(user.utel.length - 4)}}</span>
                <button v-show="$store.state.user!=null" :style="(user.follow==0&&user.fans==0&&$store.state.user!=null&&user.uid!=$store.state.user.uid)?'display:none':''">
                  {{ (user.follow==0&&user.fans==1)?'粉丝':(user.follow==1&&user.fans==0)?'您的关注':(user.follow==1&&user.fans==1)?'朋友':'我'}}
                </button>
              </span>
                <span class="search-result-left-phone">{{ user.utel }}</span>
              </div>
            </div>
            <!--关注、取关-->
            <button @click="user.follow===0?toStarUser(user.uid,ind):toUnStarUser(user.uid,ind)" class="search-result-btn1" :style="($store.state.user!=null&&user.uid===$store.state.user.uid)?'display:none':(user.follow===1)?'color:#303133;background:#FFFFFF;border:1px solid #C0C4CC;':''">
              <span class="pcShow">{{user.follow===0?'+关注':'已关注'}}</span>
              <span class="peShow"><i v-if="user.follow===0" class="fa fa-user-plus"></i><i v-if="user.follow===1" class="fa fa-check"></i></span>
            </button>
            <!--发消息-->
            <button @click="toChat(user.uid,user.utel,user.uname==null||user.uname==''?'用户***'+user.utel.substring(user.utel.length - 4):user.uname,(user.follow===1&&user.fans===1?3:user.follow===1&&user.fans===0?1:user.follow===0&&user.fans===1?2:0))" class="search-result-btn2" :style="($store.state.user!=null&&user.uid===$store.state.user.uid)?'display:none':''">
              <span class="pcShow">发消息</span>
              <span class="peShow"><i class="fa fa-comments-o"></i></span>
            </button>
          </div>
        </div>
        <p v-if="!isLoading && total === 0 && searchText!=null && searchText!=='' && !isSearching">
          <el-empty :image-size="200">
            <template slot="description">
              <span style="color: #777777">
                搜索结果为空，再试试吧
              </span>
            </template>
            <template slot="image"><img src="../../../../assets/img/noData.png"/></template>
          </el-empty>
        </p>
        <p v-if="!isLoading && total === 0 && (searchText==null || searchText==='' || isSearching)">
          <el-empty :image-size="200">
            <template slot="description">
              <span style="color: #777777">
                请输入姓名或手机号检索<br>（必须是完整手机号）
              </span>
            </template>
            <template slot="image"><img src="../../../../assets/img/write.png"/></template>
          </el-empty>
        </p>
        <p v-if="isLoading">
          <el-empty description="正在加载，请稍后..." :image-size="200">
            <template slot="image"><img src="../../../../assets/img/pic_wait.png"/></template>
          </el-empty>
        </p>
      </div>
      <!--分页-->
      <div class="page-info" v-show="!isLoading && total > 0">
        <!--上一页-->
        <div class="upon-page">
          <button @click="uponPage" :style="index===1?'cursor: not-allowed;background:#777777':''">
            <span class="pcShow">{{index===1?'首页':'上一页'}}</span>
            <span class="peShow">{{index===1?'首页':''}}<i v-show="index>1" class="fa fa-chevron-up"></i></span>
          </button>
        </div>
        <!--页码-->
        <div class="page-data">
          <span>{{index}}/{{pages}}页</span>
        </div>
        <!--下一页-->
        <div class="next-page">
          <button @click="nextPage" :style="index===pages?'cursor: not-allowed;background:#777777':''">
            <span class="pcShow">{{index===pages?'末页':'下一页'}}</span>
            <span class="peShow">{{index===pages?'末页':''}}<i v-show="index<pages" class="fa fa-chevron-down"></i></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {request, requestToken} from "../../../../store/request";
import {MSG} from "../../../../store/msg";

export default {
  name: "searchUserPart",
  data(){
    return{
      searchText:null,
      users:[],
      pages:0,
      total:0,
      index:1,
      isLoading:false,
      isSearching:true,
    }
  },
  methods:{
    text(){
      alert(1)
    },
    /**
     * 返回上一页
     */
    toBack(){
      this.$router.go(-1);
    },
    toUserCenter(uId){
      if (this.$store.state.token == null){
        MSG('error','尚未登录，请先登录！');
        return false;
      }else{
        if (this.$store.state.user.uid == uId){
          this.$router.push({path:'/userCenterPanel',query:{uId:0}});
        }else{
          this.$router.push({path:'/userCenterPanel',query:{uId:uId}});
        }
      }
    },
    toChat(targetId,targetPhone,targetName,subscribe){
      if (this.$store.state.token == null){
        MSG('error','尚未登录，请先登录');
        return;
      }
      let user = this.$store.state.user;
      let handleId = user.uid;
      let handleName = user.uname==null||user.uname==""?'用户***'+user.utel.substring(user.utel.length - 4):user.uname;
      let handlePhone = user.utel;
      this.$router.push({
        path:'/socketChatPanel',
        query:{
          handleId:handleId,
          handlePhone:handlePhone.substring(handlePhone.length - 3),
          handleName:handleName,
          targetId:targetId,
          targetPhone:targetPhone.substring(targetPhone.length - 3),
          targetName:targetName,
          subscribe:subscribe,
        }
      })
    },
    toSearchUsers(index){
      this.isLoading = true;
      this.isSearching = true;
      if (this.searchText===""||this.searchText==null){
        this.isLoading = false;
        return false;
      }
      request({
        url: "/blogUser/searchUsers",
        method: 'post',
        data:{
          userId:this.$store.state.user==null?0:this.$store.state.user.uid,
          searchText:this.searchText,
          index:index
        }
      })
      .then((res)=>{
        this.isLoading = false;
        this.isSearching = false;
        if (res.data.code === 200){
          this.users = res.data.data.users;
          this.total = res.data.data.total;
          this.pages = res.data.data.pages;
        }else{
          MSG('error',res.data.msg);
        }
      })
      .catch((err)=>{
        console.error(err);
        MSG('error','网络错误')
      })
    },
    nextPage(){
      if (this.index<this.pages){
        this.index++;
        this.toSearchUsers(this.index);
      }
    },
    uponPage(){
      if (this.index > 1){
        this.index--;
        this.toSearchUsers(this.index);
      }
    },
    /**
     * 关注用户
     * @param targetUid 目标用户id
     */
    toStarUser(targetUid,ind){
      if (this.$store.state.token == null){
        MSG('error','您未登录，请先登录');
        return false;
      }
      requestToken({
        url:'/blogUser/toStarUser',
        method:'post',
        data:{
          handleId:this.$store.state.user.uid,
          targetId:targetUid
        }
      }).then((res)=>{
        if (res.data.code == 200){
          MSG('success','关注成功');
          this.users[ind].follow = 1;
        }else{
          MSG('error',res.data.msg);
        }
      }).catch((err)=>{
        MSG('error','网络错误');
        console.log(err);
      })
    },
    /**
     * 取关用户
     * @param targetUid 目标用户id
     */
    toUnStarUser(targetUid,ind){
      if (this.$store.state.token == null){
        MSG('error','您未登录，请先登录');
        return false;
      }
      requestToken({
        url:'/blogUser/toUnStarUser',
        method:'post',
        data:{
          handleId:this.$store.state.user.uid,
          targetId:targetUid
        }
      }).then((res)=>{
        if (res.data.code == 200){
          MSG('success','取关成功');
          this.users[ind].follow = 0
        }else{
          MSG('error',res.data.msg);
        }
      }).catch((err)=>{
        MSG('error','网络错误');
        console.log(err);
      })
    },
  }
}
</script>
<style scoped>
/*整体样式*/
.MainComponent{
  min-height: 40rem;
  background: #FFFFFF;
  box-shadow: 0 0 0.5rem #cccccc;/*设置阴影*/
  border-radius: 0.4rem;/*定义圆角*/
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 0.8rem;
}
/*头部区域*/
.blogNotice{
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}
.blogNotice span:nth-child(1){
  min-Width: 2rem;
}
/*面包屑，索引*/
a:-webkit-any-link{
  font-weight: 900;
  text-decoration:none;
  color: #000000;
}
.blogNotice span:nth-child(2){
  flex: 1;
}
.blogNotice span:nth-child(3){
  min-Width: 2rem;
}
.blogNotice span:nth-child(3) span span{
  font-weight: 900;
  color: rgb(230, 162, 60);
}
@media screen and (max-width: 600px){
}
</style>
<style lang="scss" scoped>
  .searchBody{
    margin:1.4rem 1rem 1rem 1rem;
  }
  /*搜索div*/
  .search-input{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    input{
      flex: 1;
      background: #EBEEF5;
      border: none;
      color: #606266;
      outline: none;
      height: 1.2rem;
      display: inline-block;
      font-size: 1.1rem;
      width: 100%;
      padding: 0.5rem;
      margin-right: 0.4rem;
      border-radius: 0.2rem;
    }
    button{
      width: 5rem;
      height: 2rem;
      background: #409eff;
      cursor: pointer;
      border: none;
      color: #FFFFFF;
      font-size: 1rem;
      border-radius: 4px;
      letter-spacing: 0.2rem;
    }
    button:hover{
      box-shadow: rgb(0, 150, 136) 0px 1px 4px 0px;
    }
  }
  /*搜索结果集div*/
  .search-result-list-father{
    min-height: 19rem;
  }
  .search-result-list{
    margin-bottom: 0.5rem;
    //信息体
    .search-result{
      display: flex;
      flex-direction: row;
      //左侧信息
      .search-result-left{
        flex: 1;
        display: flex;
        flex-direction: row;
        .search-result-left-img{
          margin-right: 0.5rem;
          img{
            cursor: pointer;
            width: 3rem;
            height: 3rem;
            border-radius: 100%;
          }
        }
        //姓名、手机号
        .search-result-left-info{
          display: flex;
          flex-direction: column;
          justify-content: center!important;
          height: 3rem;
          line-height: 1.5rem;
          span:nth-child(1){
            cursor: pointer;
            font-weight: bold;
            button{
              margin-left: 0.2rem;
              height: 1.2rem;
              background-color: #909399;
              border-color: #d9ecff;
              padding: 0 0.4rem;
              font-size: 12px;
              color: #FFFFFF;
              border-width: 1px;
              border-style: solid;
              border-radius: 4px;
              box-sizing: border-box;
              white-space: nowrap;
            }
          }
          span:nth-child(2){
            color: #777777;
          }
        }
      }
      //右侧按钮1
      .search-result-btn1{
        width: 4rem;
        height: 2rem;
        background: #F56C6C;
        cursor: pointer;
        border: none;
        color: #FFFFFF;
        font-size: 0.9rem;
        border-radius: 4px;
        letter-spacing: 0.1rem;
        margin-right: 0.2rem;
      }
      //右侧按钮2
      .search-result-btn2{
        width: 4rem;
        height: 2rem;
        background: #E4E7ED;
        cursor: pointer;
        border: none;
        color: #303133;
        font-size: 0.9rem;
        border-radius: 4px;
        letter-spacing: 0.1rem;
      }
    }
  }
  //分页
  .page-info{
    margin-top: 1rem;
    display: flex;
    .upon-page{
      width: 4rem;
      button{
        width: 4rem;
        height: 1.8rem;
        background: #409eff;
        cursor: pointer;
        border: none;
        color: #FFFFFF;
        font-size: 0.9rem;
        border-radius: 4px;
        letter-spacing: 0.1rem;
        margin-right: 0.2rem;
      }
    }
    .page-data{
      flex: 1;
      text-align: center;
      height: 1.8rem;
      line-height: 1.8rem;
    }
    .next-page{
      width: 4rem;
      button{
        width: 4rem;
        height: 1.8rem;
        background: #409eff;
        cursor: pointer;
        border: none;
        color: #FFFFFF;
        font-size: 0.9rem;
        border-radius: 4px;
        letter-spacing: 0.1rem;
        margin-right: 0.2rem;
      }
    }
  }

  .peShow{
    display: none;
  }
  .pcShow{
    display: block;
  }
  @media screen and (max-width: 600px){
    .searchBody{
      margin:0.8rem 0.2rem 0.2rem 0.2rem!important;
    }
    /*搜索div*/
    .search-input{
      margin-bottom: 1.2rem;
      input{
        height: 1.1rem;
        font-size: 0.9rem;
      }
      button{
        width: 3rem;
        height: 1.8rem;
        font-size: 0.8rem;
      }
    }
    /*搜索结果集div*/
    .search-result-list-father{
      min-height: 16rem;
    }
    .search-result-list{
      //信息体
      .search-result{
        //左侧信息
        .search-result-left{
          .search-result-left-img{
            margin-right: 0.3rem;
            img{
              width: 2.5rem;
              height: 2.5rem;
            }
          }
          //姓名、手机号
          .search-result-left-info{
            font-size: 0.8rem;
            height: 2.5rem;
            line-height: 1.2;
            span:nth-child(1){
              button{
                margin-left: 0.1rem;
                height: 1rem;
                background-color: #909399;
                padding: 0 0.2rem;
                color: #FFFFFF;
                font-size: 0.6rem;
              }
            }
          }
        }
        //右侧按钮1
        .search-result-btn1{
          width: 2rem;
          height: 1.8rem;
        }
        //右侧按钮2
        .search-result-btn2{
          width: 2rem;
          height: 1.8rem;
          background: #E4E7ED;
          cursor: pointer;
          border: none;
          color: #303133;
          font-size: 0.9rem;
          border-radius: 4px;
          letter-spacing: 0.1rem;
        }
      }
    }
    //分页
    .page-info{
      .upon-page{
        width: 3rem;
        button{
          width: 3rem;
        }
      }
      .page-data{
        font-size: 0.9rem;
        height: 1.8rem;
        line-height: 1.8rem;
      }
      .next-page{
        width: 3rem;
        button{
          width: 3rem;
        }
      }
    }


    .peShow{
      display: block;
    }
    .pcShow{
      display: none;
    }
  }
</style>
