<template>
  <div>
    <!--顶部菜单栏-->
    <top-bar></top-bar>
    <div class="topBarHeight"></div>
    <div class="mainPanel">
    <span>
      <sars-cov-part></sars-cov-part>
    </span>
      <span>
      <!--侧面板-->
      <side-panel></side-panel>
    </span>
    </div>
  </div>
</template>

<script>
import TopBar from "../topBar";
import SidePanel from "../sideBar/sidePanel";
import SarsCovPart from "./components/sarsCovPart";
export default {
  name: "sarsCovPanel",
  components: {SarsCovPart, SidePanel, TopBar},
  data(){
    return{
    }
  },
  created() {
  },
  methods:{

  }
}
</script>
<style>
*{
  margin: 0;
}
</style>
<style scoped>
.mainPanel{
  /*此div居中并规划主位置*/
  margin: auto;
  /*开启flex布局*/
  display: flex;
  justify-content: center;
  /*定义宽度*/
  width: 80%;
}
.mainPanel span:nth-child(1){
  flex: 1;
}
.mainPanel span:nth-child(2){
  width: 300px;
  margin-left: 1rem;
}
.topBarHeight{
  height: 6rem;
}
/*当宽度小于1050px时，删除侧栏*/
@media screen and (max-width: 1050px){
  .mainPanel span:nth-child(2){
    display: none;
  }
}
@media screen and (max-width: 850px){
  .topBarHeight{
    height: 4.8rem;
  }
  .mainPanel{
    width: 92%;
  }
}
</style>