<template>
  <div class="main_body">
    <!--您的飞机面板-->
    <div class="your_panel">
      <!--工具展示栏-->
      <div class="your_tool_bar">
        <div @click="pencil = 0" v-show="pencil > -2">
          <i style="color: #393D49;" class="iconfont">&#xe705;</i>
          <div v-show="pencil===0"><i class="fa fa-check completeIcon"></i></div>
        </div>
        <div @click="pencil = 1" v-show="pencil > -2">
          <i style="color: #F56C6C;" class="iconfont">&#xe63a;</i>
          <div v-show="pencil===1"><i class="fa fa-check completeIcon"></i></div>
        </div>
        <div style="flex: 1;"></div>
        <div style="font-size: 1rem">
          雷x15
        </div>
      </div>
      <!--飞机格子-->
      <div class="your_block">
        <div>
          <div v-for="(i,index) in game_range_y+1" :key="index">{{index===0?'':index}}</div>
        </div>
        <div v-for="(your_canvas_x,index) in your_canvas" :key="index">
          <div>{{index+1 | getChar}}</div>
          <div v-for="(your_canvas_y,index2) in your_canvas_x" :key="index2">
            <div @click="pencil>=0?pointDot(index,index2):''">
              <template v-if="your_canvas_y.isShow === 0">
                <span v-if="your_canvas_y.flag === 0" style="background: #C0C4CC"></span>
                <span v-if="your_canvas_y.flag === 1" style="background: #C0C4CC"><i style="color: #F56C6C;font-size: 1.4rem" class="iconfont">&#xe63a;</i></span>
              </template>
              <template v-if="your_canvas_y.isShow === 1">
                <span v-if="your_canvas_y.position === -1" style="background: #FFFFFF;">
                  <i style="font-size: 1.6rem" class="iconfont">&#xe610;</i>
                </span>
                <span v-if="your_canvas_y.position === 0" style="background: #FFFFFF;"></span>
                <span v-if="your_canvas_y.position >= 1" style="background: rgba(95, 184, 120,0.6);font-weight: bold">
                  <span :style="your_canvas_y.position<3?'color:#FFFFFF':your_canvas_y.position<4?'color:#FF5722':'color:red'">{{your_canvas_y.position}}</span>
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MSG} from "../../../../../store/msg";
import Vue from "_vue@2.6.14@vue";
Vue.filter('getChar', function (value) {
  let str = 'ABCDEFGHIJ';
  return str.substr(value-1,1)
});
export default {
  name: "minesweeperGamePanel",
  data(){
    return{
      //画笔，0扫雷，1标记/取消标记
      pencil:0,
      game_range_x:10,
      game_range_y:10,
      your_canvas:'',
      yourScore:{
        kill:0,
        hurt:0,
        empty:0,
      },
    }
  },
  mounted() {
    this.init();
  },
  methods:{
    init(){
      //初始化面板
      let obj = {
        //position：0空，-1炸弹，1-8计数
        position:0,
        //flag：是否插旗：0未插旗，1插旗
        flag:0,
        //是否扫开，1是，0否
        isShow:0,
      }
      this.your_canvas = new Array();
      for(let i = 0 ; i < this.game_range_x ; i++){
        this.your_canvas[i] = new Array();
        for(let j = 0 ; j < this.game_range_y ; j++){
          this.your_canvas[i][j] = obj;
        }
      }
      //埋雷
      let n = 0;
      while (n<15){
        let x = 0;
        let y = 0;
        let res = false;
        while (!res){
          x = Math.floor(Math.random()*10);
          y = Math.floor(Math.random()*10);
          //看这个位置的isShow是否是0
          if (this.your_canvas[x][y].position !== -1){
            res = true;
            this.pencil = -1;
            this.pointDot(x,y);
          }
        }
        n++;
      }
      //填充每个方块的数字
      for(let i = 0 ; i < this.game_range_x ; i++){
        for(let j = 0 ; j < this.game_range_y ; j++){
          let obj = JSON.parse(JSON.stringify(this.your_canvas[i][j]));
          if (obj.position !== -1){
            //不是炸弹再继续判断
            let sum = 0;
            if (i === 0 && j!==0 && j!==this.game_range_y-1){
              //代表第一行排除首尾
              sum += this.your_canvas[i+1][j-1].position===-1?1:0;
              sum += this.your_canvas[i+1][j+1].position===-1?1:0;
              sum += this.your_canvas[i][j-1].position===-1?1:0;
              sum += this.your_canvas[i][j+1].position===-1?1:0;
              sum += this.your_canvas[i+1][j].position===-1?1:0;
            }
            if (i === this.game_range_x-1 && j!==0 && j!==this.game_range_y-1){
              //代表最后一行排除首尾
              sum += this.your_canvas[i-1][j-1].position===-1?1:0;
              sum += this.your_canvas[i-1][j+1].position===-1?1:0;
              sum += this.your_canvas[i][j-1].position===-1?1:0;
              sum += this.your_canvas[i][j+1].position===-1?1:0;
              sum += this.your_canvas[i-1][j].position===-1?1:0;
            }
            if (j === this.game_range_y-1 && i!==0 && i!==this.game_range_x-1){
              //代表右侧第一行排除首尾
              sum += this.your_canvas[i-1][j-1].position===-1?1:0;
              sum += this.your_canvas[i+1][j-1].position===-1?1:0;
              sum += this.your_canvas[i][j-1].position===-1?1:0;
              sum += this.your_canvas[i-1][j].position===-1?1:0;
              sum += this.your_canvas[i+1][j].position===-1?1:0;
            }
            if (j === 0 && i!==0 && i!==this.game_range_x-1){
              //代表左侧第一行排除首尾
              sum += this.your_canvas[i+1][j+1].position===-1?1:0;
              sum += this.your_canvas[i-1][j+1].position===-1?1:0;
              sum += this.your_canvas[i][j+1].position===-1?1:0;
              sum += this.your_canvas[i-1][j].position===-1?1:0;
              sum += this.your_canvas[i+1][j].position===-1?1:0;
            }
            if (i === 0 && j === 0){
              //左上角
              sum += this.your_canvas[i+1][j+1].position===-1?1:0;
              sum += this.your_canvas[i][j+1].position===-1?1:0;
              sum += this.your_canvas[i+1][j].position===-1?1:0;
            }
            if (i === 0 && j === this.game_range_y-1){
              //右上角
              sum += this.your_canvas[i+1][j-1].position===-1?1:0;
              sum += this.your_canvas[i][j-1].position===-1?1:0;
              sum += this.your_canvas[i+1][j].position===-1?1:0;
            }
            if (i === this.game_range_x-1 && j === 0){
              //左下角
              sum += this.your_canvas[i-1][j+1].position===-1?1:0;
              sum += this.your_canvas[i][j+1].position===-1?1:0;
              sum += this.your_canvas[i-1][j].position===-1?1:0;
            }
            if (i === this.game_range_x-1 && j === this.game_range_y-1){
              //右下角
              sum += this.your_canvas[i-1][j-1].position===-1?1:0;
              sum += this.your_canvas[i][j-1].position===-1?1:0;
              sum += this.your_canvas[i-1][j].position===-1?1:0;
            }
            if(i > 0 && j > 0 && i < this.game_range_x-1 && j < this.game_range_y-1){
              sum += this.your_canvas[i-1][j-1].position===-1?1:0;
              sum += this.your_canvas[i+1][j-1].position===-1?1:0;
              sum += this.your_canvas[i+1][j+1].position===-1?1:0;
              sum += this.your_canvas[i-1][j+1].position===-1?1:0;
              sum += this.your_canvas[i][j-1].position===-1?1:0;
              sum += this.your_canvas[i][j+1].position===-1?1:0;
              sum += this.your_canvas[i-1][j].position===-1?1:0;
              sum += this.your_canvas[i+1][j].position===-1?1:0;
            }
            obj.position = sum;
            this.your_canvas[i][j] = obj;
          }
        }
      }
      this.$forceUpdate();
      this.pencil = 0;
    },
    putDataByTypeAndXY(type,x,y,data){
      //类型：1 isFlag，2 is Show
      //data:修改的值
      //我方飞机
      if (type === 1){
        //修改flag
        let obj = JSON.parse(JSON.stringify(this.your_canvas[x][y]));
        obj.isFlag = data;
        this.your_canvas[x][y] = obj;
      }else if (type === 2){
        //修改isShow
        let obj = JSON.parse(JSON.stringify(this.your_canvas[x][y]));
        obj.isShow = data;
        this.enemy_canvas[x][y] = obj;
      }
    },
    gameOver(data){
      this.$confirm(data==='defeat'?'很遗憾，扫到雷了':'再接再厉', data==='defeat'?'你输了':'你赢了', {
        confirmButtonText: '离开',
        cancelButtonText:'再看看',
        callback: action => {
          if (action == 'confirm'){
            this.$emit("toBack");
          }else{
            this.pencil = -2;
          }
        }
      });
    },
    pointDot(x,y){
      //获取目标块
      let obj = JSON.parse(JSON.stringify(this.your_canvas[x][y]));
      if (this.pencil === -1){
        //埋雷阶段
        obj.position = -1;
      }else if (this.pencil === 0 && obj.flag === 0){
        //将目标块点亮
        if (obj.isShow === 1){
          //已经炸过了，不允许再炸
          MSG('warning','这个区域已经扫过了');
          return false;
        }
        obj.isShow = 1;
        if (obj.position === 0){
          //把附近空位全部炸出来
          this.showEmplty(x,y)
        }else if(obj.position === -1){
          //输了
          this.gameOver('defeat');
        }
      }else if(this.pencil === 1 && obj.isShow === 0){
        obj.flag = obj.flag===0?1:0;
      }
      this.your_canvas[x][y] = obj;
      //判断赢了吗
      let noShow = 0;
      for(let i = 0 ; i < this.game_range_x ; i++){
        for(let j = 0 ; j < this.game_range_y ; j++){
          if (this.your_canvas[i][j].isShow===0){
            noShow ++;
          }
        }
      }
      if (noShow <= 15){
        this.gameOver('victory');
      }
      this.$forceUpdate();
    },
    showEmplty(i,j){
      if (i === 0 && j!==0 && j!==this.game_range_y-1){
        //代表第一行排除首尾
        for (let k = 0; k <= 1; k++) {
          for (let l = -1; l <= 1; l++) {
            if (k!==0 || l !== 0){
              let x = i+k;
              let y = j+l;
              let obj = JSON.parse(JSON.stringify(this.your_canvas[x][y]));
              if (obj.isShow ===0 && obj.position === 0){
                //还是0，递归
                obj.isShow = 1;
                this.your_canvas[i+k][j+l] = obj;
                this.showEmplty(i+k,j+l);
              }else{
                obj.isShow = 1;
              }
              this.your_canvas[i+k][j+l] = obj;
            }
          }
        }
      }
      if (i === this.game_range_x-1 && j!==0 && j!==this.game_range_y-1){
        //代表最后一行排除首尾
        for (let k = -1; k <= 0; k++) {
          for (let l = -1; l <= 1; l++) {
            if (k!==0 || l !== 0){
              let obj = JSON.parse(JSON.stringify(this.your_canvas[i+k][j+l]));
              if (obj.isShow ===0 && obj.position === 0){
                //还是0，递归
                obj.isShow = 1;
                this.your_canvas[i+k][j+l] = obj;
                this.showEmplty(i+k,j+l);
              }else{
                obj.isShow = 1;
              }
              this.your_canvas[i+k][j+l] = obj;
            }
          }
        }
      }
      if (j === this.game_range_y-1 && i!==0 && i!==this.game_range_x-1){
        //代表右侧第一行排除首尾
        for (let k = -1; k <= 1; k++) {
          for (let l = -1; l <= 0; l++) {
            if (k!==0 || l !== 0){
              let obj = JSON.parse(JSON.stringify(this.your_canvas[i+k][j+l]));
              if (obj.isShow ===0 && obj.position === 0){
                //还是0，递归
                obj.isShow = 1;
                this.your_canvas[i+k][j+l] = obj;
                this.showEmplty(i+k,j+l);
              }else{
                obj.isShow = 1;
              }
              this.your_canvas[i+k][j+l] = obj;
            }
          }
        }
      }
      if (j === 0 && i!==0 && i!==this.game_range_x-1){
        //代表左侧第一行排除首尾
        for (let k = -1; k <= 1; k++) {
          for (let l = 0; l <= 1; l++) {
            if (k!==0 || l !== 0){
              let obj = JSON.parse(JSON.stringify(this.your_canvas[i+k][j+l]));
              if (obj.isShow ===0 && obj.position === 0){
                //还是0，递归
                obj.isShow = 1;
                this.your_canvas[i+k][j+l] = obj;
                this.showEmplty(i+k,j+l);
              }else{
                obj.isShow = 1;
              }
              this.your_canvas[i+k][j+l] = obj;
            }
          }
        }
      }
      if (i === 0 && j === 0){
        //左上角
        for (let k = 0; k <= 1; k++) {
          for (let l = 0; l <= 1; l++) {
            if (k!==0 || l !== 0){
              let obj = JSON.parse(JSON.stringify(this.your_canvas[i+k][j+l]));
              if (obj.isShow ===0 && obj.position === 0){
                //还是0，递归
                obj.isShow = 1;
                this.your_canvas[i+k][j+l] = obj;
                this.showEmplty(i+k,j+l);
              }else{
                obj.isShow = 1;
              }
              this.your_canvas[i+k][j+l] = obj;
            }
          }
        }
      }
      if (i === 0 && j === this.game_range_y-1){
        //右上角
        for (let k = 0; k <= 1; k++) {
          for (let l = -1; l <= 0; l++) {
            if (k!==0 || l !== 0){
              let obj = JSON.parse(JSON.stringify(this.your_canvas[i+k][j+l]));
              if (obj.isShow ===0 && obj.position === 0){
                //还是0，递归
                obj.isShow = 1;
                this.your_canvas[i+k][j+l] = obj;
                this.showEmplty(i+k,j+l);
              }else{
                obj.isShow = 1;
              }
              this.your_canvas[i+k][j+l] = obj;
            }
          }
        }
      }
      if (i === this.game_range_x-1 && j === 0){
        //左下角
        for (let k = -1; k <= 0; k++) {
          for (let l = 0; l <= 1; l++) {
            if (k!==0 || l !== 0){
              let obj = JSON.parse(JSON.stringify(this.your_canvas[i+k][j+l]));
              if (obj.isShow ===0 && obj.position === 0){
                //还是0，递归
                obj.isShow = 1;
                this.your_canvas[i+k][j+l] = obj;
                this.showEmplty(i+k,j+l);
              }else{
                obj.isShow = 1;
              }
              this.your_canvas[i+k][j+l] = obj;
            }
          }
        }
      }
      if (i === this.game_range_x-1 && j === this.game_range_y-1){
        //右下角
        for (let k = -1; k <= 0; k++) {
          for (let l = -1; l <= 0; l++) {
            if (k!==0 || l !== 0){
              let obj = JSON.parse(JSON.stringify(this.your_canvas[i+k][j+l]));
              if (obj.isShow ===0 && obj.position === 0){
                //还是0，递归
                obj.isShow = 1;
                this.your_canvas[i+k][j+l] = obj;
                this.showEmplty(i+k,j+l);
              }else{
                obj.isShow = 1;
              }
              this.your_canvas[i+k][j+l] = obj;
            }
          }
        }
      }
      if(i > 0 && j > 0 && i < this.game_range_x-1 && j < this.game_range_y-1){
        //中间区域
        for (let k = -1; k <= 1; k++) {
          for (let l = -1; l <= 1; l++) {
            if (k!==0 || l !== 0){
              let obj = JSON.parse(JSON.stringify(this.your_canvas[i+k][j+l]));
              if (obj.isShow ===0 && obj.position === 0){
                //还是0，递归
                obj.isShow = 1;
                this.your_canvas[i+k][j+l] = obj;
                this.showEmplty(i+k,j+l);
              }else{
                obj.isShow = 1;
              }
              this.your_canvas[i+k][j+l] = obj;
            }
          }
        }
      }
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped>
/*遮罩层*/
.box{
  position:absolute;
  z-index: 998;
  top:0;
  bottom:-16rem;
  left:0;
  right:0;
  background:#FFFFFF;
  opacity:0.7;
}.box-text{
   position:absolute;
   top:35%;
   left:0;
   right:0;
   color: #1E9FFF;
   font-size: 1.2rem;
   text-align: center;
 }
</style>
<style lang="scss" scoped>
.main_body{
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  .your_panel{
    //飞机操作格子
    .your_tool_bar{
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.3rem;
      height: 1.8rem;
      div{
        cursor: pointer;
        min-width: 1.5rem;
        text-align: center;
        div{
          position: absolute;
          z-index: 997;
          top: 0;
          font-size: 1.6rem;
          color: blueviolet;
        }
      }
    }
    .your_block{
      //行
      div{
        display: flex;
        flex-direction: row;
        height: 1.8rem;
        //列（具体块）
        div{
          background: #EBEEF5;
          width: 1.8rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          div{
            width: 90%;
            height: 90%;
            cursor: pointer;
            span{
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
.yourLastBoom{
  border: 2px dashed red;
  margin-bottom: 0.05rem;
}
</style>
