<template>
  <el-dialog
    title="游戏详情"
    :visible.sync="centerDialogVisible"
    width="600px"
    center
    @close="$emit('closeDialog')">
    <div class="mainBody">
      <!--介绍框-->
      <div class="infoBox">
        <!--第一行-->
        <div class="main">
          <div class="icon"><i class="iconfont" v-html="game.iconfont"></i></div>
          <div class="text">
            <!--名称-->
            <div>{{game.gameName}}</div>
            <!--标签-->
            <div>{{game.memberType===1?'单人':'双人'}}</div>
          </div>
        </div>
        <!--第二行-->
        <div class="info">
          <div>{{game.info}}</div>
        </div>
      </div>
      <!--用户搜索框-->
      <div class="userBox">
        <template v-if="game.status === 2">
          <div class="noGame">
            <button>敬请期待</button>
          </div>
        </template>
        <template v-if="game.status !== 2 && game.memberType===1">
          <div class="beginGame">
            <button @click="toStartGame(1,gameId)">开始游戏</button>
          </div>
        </template>
        <template v-if="game.status !== 2 && game.memberType===2">
          <div class="beginGame2">
            <!--提示语-->
            <p>选择一位用户开始游戏</p>
            <div class="userList">
              <div class="searchBox">
                <input v-model="searchText" @keyup.enter="toSearchUsers(1)"/>
                <button @click="toSearchUsers(1)"><span class="isPC">搜索</span><span class="isPE"><i class="iconfont">&#xe694;</i></span></button>
              </div>
              <div class="listBox">
                <div v-for="(user,index) in users" :key="index">
                  <div class="item">
                    <img v-if="user.uavatar!==null&&user.uavatar!==''" :src="user.uavatar">
                    <img v-else src="../../../../assets/icon/user.png">
                    <span>
                      {{user.uname!==null&&user.uname!==''?user.uname:'用户***'+user.utel.substring(user.utel.length-4)}}&nbsp;
                      <span v-show="user.follow!==0&&user.fans!==0" style="font-size: 0.8rem;color: #FF5722">{{ (user.follow===0&&user.fans===1)?'粉丝':(user.follow===1&&user.fans===0)?'您的关注':(user.follow===1&&user.fans===1)?'朋友':'我'}}</span>
                      <span v-show="user.uid === $store.state.user.uid" style="font-size: 0.8rem;color: #009688">我</span>
                    </span>
                    <button v-show="user.uid !== $store.state.user.uid" @click="toStartGame(2,gameId,index)">创建连接</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {clearToken, request, requestToken} from "../../../../store/request";
import {MSG} from "../../../../store/msg";

export default {
  props:['centerDialogVisible','gameId'],
  name: "gameDetailDialog",
  data(){
    return{
      game:null,
      users:[],
      searchText:null,
      total:0,
      pages:0,
    }
  },
  methods:{
    getGameDetail(gameId){
      requestToken({
        url: "/blogGameCenter/getGameById",
        method: 'post',
        data:{
          gameId:gameId
        }
      }).then((res)=>{
        if (res.data.code === 200) {
          this.game = res.data.data.game;
          if (this.game.memberType === 2){
            this.getFriendList();
          }
        } else {
          MSG('error',res.data.msg);
          if (res.data.code === 300){
            clearToken();
          }
        }
      }).catch((error) => {
        MSG('error','网络错误，操作失败！');
        console.log("error:" + error)
      })
    },
    /**
     * 开始游戏
     * @param type 游戏类型，1：单人，2：双人
     * @param gameId 游戏id
     * @param userIndex
     * @Param userIndex 用户在列表的编号
     */
    toStartGame(type,gameId,userIndex){
      if (type === 1){
        let handleUser = this.$store.state.user;
        this.$router.push({
          path:this.game.url,
          query:{
            handleId:handleUser.uid,
            handlePhone:handleUser.utel.substring(handleUser.utel.length - 3),
            handleName:handleUser.uname==null||handleUser.uname===''?'用户***'+handleUser.utel.substring(handleUser.utel.length-4):handleUser.uname,
          }
        })
      }else if(type === 2){
        let handleUser = this.$store.state.user;
        let targetUser = this.users[userIndex];
        this.$router.push({
          path:this.game.url,
          query:{
            handleId:handleUser.uid,
            handleAvatar:handleUser.uavatar,
            handlePhone:handleUser.utel.substring(handleUser.utel.length - 3),
            handleName:handleUser.uname==null||handleUser.uname===''?'用户***'+handleUser.utel.substring(handleUser.utel.length-4):handleUser.uname,
            targetId:targetUser.uid,
            targetAvatar:targetUser.uavatar,
            targetPhone:targetUser.utel.substring(targetUser.utel.length - 3),
            targetName:targetUser.uname==null||targetUser.uname===''?'用户***'+targetUser.utel.substring(targetUser.utel.length-4):targetUser.uname,
            subscribe:0,//0无关系，1关注，2粉丝，3朋友
          }
        })
      }
    },
    toSearchUsers(index){
      this.isLoading = true;
      this.isSearching = true;
      if (this.searchText===""||this.searchText==null){
        this.isLoading = false;
        return false;
      }
      request({
        url: "/blogUser/searchUsers",
        method: 'post',
        data:{
          userId:this.$store.state.user==null?0:this.$store.state.user.uid,
          searchText:this.searchText,
          index:index
        }
      })
      .then((res)=>{
        this.isLoading = false;
        this.isSearching = false;
        if (res.data.code === 200){
          this.users = res.data.data.users;
          this.total = res.data.data.total;
          this.pages = res.data.data.pages;
        }else{
          MSG('error',res.data.msg);
        }
      })
      .catch((err)=>{
        console.error(err);
        MSG('error','网络错误')
      })
    },
    getFriendList(){
      requestToken({
        url:'/blogUser/getFriendList',
        method:'post',
      }).then((res)=>{
        if (res.data.code === 200){
          this.users = res.data.data.friends;
        }else{
          MSG('error',res.data.msg);
          if (res.data.code === 300){
            clearToken();
          }
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.isPE{
  display: none;
}
  .mainBody{
    .infoBox{
      .main{
        margin: 0 0 0 1rem;
        height: 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon{
          font-size: 2.5rem;
          width: 3rem;
          margin-right: 0.5rem;
        }
        .text{
          div:nth-child(1){
            font-size: 1.2rem;
            font-weight: bold;
          }
          div:nth-child(2){
            font-size: 0.8rem;
            color: #ff9900;
          }
        }
      }
      .info{
        margin: 0.5rem 1rem 0.5rem 1rem;
        padding: 0.2rem 0.3rem 0.2rem 0.3rem;
        border-top: 1px solid #C0C4CC;
        border-bottom: 1px solid #C0C4CC;
      }
    }
    .userBox{
      .noGame{
        margin-top: 1rem;
        height: 2.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        button{
          width: 8rem;
          height: 2.3rem;
          background: #ff9900;
          cursor: not-allowed;
          border: none;
          color: #FFFFFF;
          font-size: 1.1rem;
          border-radius: 10rem;
          letter-spacing: 0.2rem;
        }
      }
      .beginGame{
        margin-top: 1rem;
        height: 2.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        button{
          width: 8rem;
          height: 2.3rem;
          background: #009688;
          cursor: pointer;
          border: none;
          color: #FFFFFF;
          font-size: 1.1rem;
          border-radius: 10rem;
          letter-spacing: 0.2rem;
        }
      }
      .beginGame2{
        p{
          height: 1.5rem;
          text-align: center;
          color: #ff9900;
        }
        .userList{
          margin: 0.5rem 1rem 0.5rem 1rem;
          .searchBox{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.5rem;
            input{
              background: #EBEEF5;
              border: none;
              color: #606266;
              outline: none;
              height: 1.2rem;
              display: inline-block;
              font-size: 1.1rem;
              width: 90%;
              min-width: 8rem;
              padding: 0.5rem;
              margin-right: 0.4rem;
              border-radius: 0.2rem;
            }
            button{
              width: 4rem;
              height: 2rem;
              background: #409eff;
              cursor: pointer;
              border: none;
              color: #FFFFFF;
              font-size: 1rem;
              border-radius: 4px;
              letter-spacing: 0.1rem;
            }
          }
          .listBox{
            .item{
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 3rem;
              border-bottom: 1px solid #C0C4CC;
              img{
                height: 2.5rem;
                border-radius: 100%;
                margin-right: 0.5rem;
              }
              span{
                flex: 1;
              }
              button{
                width: 5.6rem;
                height: 2rem;
                background: #009688;
                cursor: pointer;
                border: none;
                color: #FFFFFF;
                font-size: 0.9rem;
                border-radius: 10rem;
                letter-spacing: 0.1rem;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px){
    .isPC{
      display: none;
    }
    .isPE{
      display: block;
    }
    /deep/ .el-dialog{
      width: 90%!important;
    }
    .mainBody{
      .infoBox{
        .main{
          margin: 0;
          height: 2.4rem;
          .icon{
            margin-left: 8%;
            font-size: 2rem;
            width: 2.4rem;
          }
          .text{
            div:nth-child(1){
              font-size: 1rem;
            }
          }
        }
        .info{
          margin: 0 0 0.3rem 0;
          padding: 0.2rem 0.3rem 0.2rem 0.3rem;
        }
      }
      .userBox{
        .noGame{
          height: 2.5rem;
        }
        .beginGame{
          height: 2.5rem;
        }
        .beginGame2{
          p{
            height: 1.4rem;
          }
          .userList{
            margin: 0;
            .searchBox{
              margin-bottom: 0.2rem;
              input{
                height: 1rem;
              }
              button{
                width: 2rem;
              }
            }
            .listBox{
              .item{
                height: 2.6rem;
                img{
                  height: 2rem;
                  margin-right: 0.3rem;
                }
                span{
                  font-size: 0.9rem;
                }
                button{
                  max-width: 4.7rem;
                  height: 2rem;
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
</style>