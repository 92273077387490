<template>
  <el-empty description="精彩即将呈现" :image-size="200">
    <template slot="image"><img src="../../assets/img/pic_wait.png"/></template>
  </el-empty>
</template>

<script>
export default {
  name: "loadingComponent"
}
</script>

<style scoped>

</style>