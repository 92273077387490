<template>
  <div class="sideClock">
    <div class="mainPanel">
      <span><clock :time="time"></clock></span>
      <span>{{ nowDate }}</span>
      <span>欢 迎 访 问</span>
    </div>
  </div>
</template>

<script>
import Clock from 'vue-clock2';
export default {
  components: { Clock },
  name: "sideClock",
  data () {
    return {
      time:'',
      nowDate:''
    }
  },
  mounted(){
    this.currentTime();
  },
  // 销毁定时器
  beforeDestroy() {
    if (this.formatDate) {
      clearInterval(this.formatDate); // 在Vue实例销毁前，清除时间定时器
    }
  },
  methods: {
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute} ${weekArr[week]}`;
      this.time = `${hour}:${minute}:${second}`;
    }
  }
}
</script>

<style scoped>
  .sideClock{
    background: #FFFFFF;
    height: 16rem;
    box-shadow: 0 0 0.5rem #cccccc;/*设置阴影*/
    border-radius: 0.4rem;/*定义圆角*/
    padding: 1rem 1rem 1rem 1rem;
  }
  .mainPanel{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mainPanel span:nth-child(1){
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    flex: 1;
  }
  .mainPanel span:nth-child(1) p{
    z-index: 10;
    position: absolute;
    top: 220px;
    right: 18%;
    border: 2px solid #cfcfcf;
    border-radius: 6px;
    color: #777777;
    font-size: 0.8rem;
    padding: 1px 2px;
  }
  .mainPanel span:nth-child(2){
    height: 2.4rem;
    line-height: 2.4rem;
    font-size: 1.1rem;
  }
  .mainPanel span:nth-child(3){
    height: 2.4rem;
    line-height: 2.4rem;
  }
</style>
<style>
  /*时钟样式更改*/

  /*最外层边框*/
  .clock{
    border: 0.2rem solid #909399!important;
  }
  /*显示数字*/
  .clock .hour>span>i{
    color: #606266;
  }
</style>