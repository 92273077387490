<template>
  <div class="blogDetailPanelComponent">
    <div class="blogNotice">
      <span>
        &ensp;<router-link :to="{ path: '/index' }">首页</router-link> &ensp;<i
          class="fa fa-angle-right"
        ></i>
        <router-link
          :to="{
            path: '/blogDetailPanel',
            query: { blog_id: this.$route.query.blogId },
          }"
          >&ensp;博客详情</router-link
        >
        &ensp;<i class="fa fa-angle-right"></i>&ensp;编辑
      </span>
      <span></span>
      <span @click="toBack()" style="font-weight: 500; cursor: pointer"
        >返回&ensp;</span
      >
    </div>
    <div v-if="blog.blog_id != null && !loading">
      <div>
        <div
          style="
            border-bottom: 0px solid #ffffff;
            user-select: none;
            font-weight: 900;
            font-size: 1rem;
            color: #009688;
            line-height: 2.2rem;
            border: 1px solid #ccc;
            text-align: center;
            border-top-right-radius: 0.5rem;
            border-top-left-radius: 0.5rem;
            height: 5rem;
          "
        >
          <i class="fa fa-pencil-square" style="font-size: 1.1rem"></i
          >&nbsp;编辑博客<br />
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 0 3% 0 3%;
            "
          >
            <input
              v-model="commentForm.title"
              placeholder="请输入标题..."
              style="
                flex: 1;
                outline: none;
                border: 1px solid #ccc;
                border-radius: 8px;
                line-height: 1.8rem;
                font-size: 1rem;
                padding-left: 0.3rem;
                padding-right: 0.3rem;
              "
            />
            <div style="width: 60px">
              <button
                class="nav-btn"
                v-if="blog.blog_json_nav != undifined"
                @click="navEditor = true"
              >
                导航
              </button>
            </div>
          </div>
        </div>
        <div>
          <quill-editor
            ref="quillEditor"
            v-model="commentForm.content"
            :options="editorOption"
            @change="onEditorChange($event)"
          ></quill-editor>
        </div>
        <div
          style="
            user-select: none;
            display: flex;
            justify-content: space-between;
            font-weight: 900;
            font-size: 1rem;
            color: #009688;
            line-height: 2.5rem;
            border: 1px solid #ccc;
            text-align: center;
            border-bottom-right-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
            height: 3rem;
            padding: 0.2rem 0.2rem 0 0.2rem;
            margin-top: 0;
          "
        >
          <el-button
            @click="toClickUpLoad()"
            type="info"
            icon="el-icon-picture-outline-round"
            style="background: #409eff; height: 2.6rem; width: 100%"
            ><span class="hidden_shangchuan">上传</span>封面</el-button
          >
          <el-button
            @click="toPublishBlog()"
            type="info"
            style="
              background: #009688;
              height: 2.6rem;
              text-align: center;
              width: 100%;
            "
            icon="el-icon-check"
            >保存<span class="hidden_shangchuan">文章</span></el-button
          >
        </div>
        <div
          style="
            margin-top: 1rem;
            text-align: center;
            color: #777777;
            font-weight: 800;
          "
        >
          文章千古事，得失寸心知
        </div>
        <div
          style="
            margin-right: 8%;
            margin-top: 1rem;
            text-align: right;
            color: #777777;
            font-weight: 800;
          "
        >
          ————唐·杜甫
        </div>
        <el-upload
          class="main_img_upload"
          :headers="{ Authorization: this.$store.state.token }"
          :action="
            'http://' +
            this.$store.state.ip +
            ':' +
            this.$store.state.port +
            '/blogUser/toUploadImg'
          "
          accept="image/*,.jpg,.jpeg,.png,.gif"
          style="text-align: left"
          :on-success="
            (res, file, fileList) => uploadSuccess2(res, file, fileList)
          "
          :show-file-list="false"
          :on-error="(res, file, fileList) => uploadError(res, file, fileList)"
        >
        </el-upload>
        <el-upload
          class="avatar-uploader quill-img"
          :headers="{ Authorization: this.$store.state.token }"
          :action="
            'http://' +
            this.$store.state.ip +
            ':' +
            this.$store.state.port +
            '/blogUser/toUploadImg'
          "
          name="file"
          :show-file-list="false"
          :on-success="
            (res, file, fileList) => uploadSuccess(res, file, fileList)
          "
          :on-error="(res, file, fileList) => uploadError(res, file, fileList)"
          accept="image/*,.jpg,.jpeg,.png,.gif"
        ></el-upload>
      </div>
    </div>
    <p
      v-if="blog.blog_id == null && !loading"
      style="color: #ea605c; margin-top: 3rem"
    >
      <el-empty :image-size="200">
        <template slot="description">
          <span style="color: #777777">
            博客不存在或已被删除，<span
              @click="$router.push({ path: '/index' })"
              style="color: #158bf1; cursor: pointer"
              >前往首页</span
            >
          </span>
        </template>
        <template slot="image"
          ><img src="../../../../assets/img/404-2.png"
        /></template>
      </el-empty>
    </p>
    <nav-editor
      :navs="blog.blog_json_nav"
      :dialogVisible="navEditor"
      :isLoading="isLoadingNavEditor"
      @close="closeNavEditor"
      @update="updateNav"
    ></nav-editor>
  </div>
</template>

<script>
/* eslint-disable */
// import {request} from "../../../../store/request";
import { MSG } from "../../../../store/msg";
import {
  clearToken,
  request,
  requestToken,
  requestTokenFile,
} from "../../../../store/request";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import * as Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import { quillEditor } from "vue-quill-editor";
import NavEditor from "./navEditor.vue";

export default {
  name: "blogDetailPanelComponent",
  data() {
    return {
      blog: {},
      comments: [],
      msg: null,
      loading: false,
      isLoadingDOM: true,
      isLoadingNavEditor: false,
      navEditor: false,
      emoji: ["😄", "😁", "😆", "😅", "😂", "😘", "😡", "😒", "😭"],
      commentForm: {
        content: this.value,
        title: null,
        img: null,
        uid: null,
      },
      editorOption: {
        placeholder: "说些什么吧...",
        // readOnly: true,
        modules: {
          toolbar: {
            container: [
              ["emoji", "bold", "italic", "underline"], //加粗，斜体，下划线，删除线
              ["code-block"], //引用，代码块
              [
                {
                  header: 1,
                },
                {
                  header: 2,
                },
              ], // 标题，键值对的形式；1、2表示字体大小
              // [{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
              // [{ 'script': 'sub'}, { 'script': 'super' }],   // 上下标
              // [{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
              // [{ 'direction': 'rtl' }],             // 文本方向
              [
                {
                  size: ["small", false, "large", "huge"],
                },
              ], // 字体大小
              [
                {
                  header: [1, 2, 3, 4, 5, 6, false],
                },
              ], //几级标题
              [
                {
                  color: [],
                },
                {
                  background: [],
                },
              ], // 字体颜色，字体背景颜色
              // [{
              // 	'font': []
              // }], //字体
              [
                {
                  align: [],
                },
              ], //对齐方式
              // ['clean'], //清除字体样式
              ["link", "image", "video"], //上传图片、上传视频
            ],
            handlers: {
              image: function (value) {
                if (value) {
                  // 触发input框选择图片文件
                  document.querySelector(".quill-img input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
          "emoji-toolbar": true,
          "emoji-shortname": true,
        },
      },
    };
  },
  created() {
    this.getBlogById();
  },
  components: {
    quillEditor,
    NavEditor,
  },
  updated() {
    if (this.isLoadingDOM == true) {
      this.isLoadingDOM = false;
      this.listenerForVueQuillEdirotPaste();
    }
  },
  mounted() {
    //监听ctrl+s并修改
    document.addEventListener("keydown", this.saveContent);
    this.timer = setInterval(() => {
      this.save("timer");
    }, 10 * 1000);
  },
  beforeDestroy() {
    //监听ctrl+s并修改
    this.toPublishBlog(1);
    document.removeEventListener("keydown", this.saveContent);
    clearInterval(this.timer);
  },
  methods: {
    listenerForVueQuillEdirotPaste() {
      //  自定义粘贴图片功能
      let quill = this.$refs.quillEditor.quill;
      this.$forceUpdate();
      quill.root.addEventListener(
        "paste",
        (evt) => {
          if (
            evt.clipboardData &&
            evt.clipboardData.files &&
            evt.clipboardData.files.length
          ) {
            evt.preventDefault();
            [].forEach.call(evt.clipboardData.files, (file) => {
              if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                return;
              }
              //文件上传
              let param = new FormData();
              param.append("file", file);
              requestTokenFile({
                url: "/blogUser/toUploadImg",
                method: "post",
                data: param,
              })
                .then((res) => {
                  if (res.data.stat == 200) {
                    MSG("success", "上传成功");
                    // 获取光标所在位置
                    let quill1 = this.$refs.quillEditor.quill;
                    let length = quill1.getSelection().index;
                    // 插入图片  res.url为服务器返回的图片地址
                    quill1.insertEmbed(length, "image", res.data.url);
                    // 调整光标到最后
                    quill1.setSelection(length + 1);
                  } else {
                    MSG("error", "上传失败");
                    if (res.data.code == 300) {
                      clearToken();
                    }
                  }
                })
                .catch((error) => {
                  MSG("error", "网络错误，无法访问");
                  console.log("update_blog_error:" + error);
                });
            });
          }
        },
        false
      );
    },
    getBlogById() {
      //加载
      this.loading = true;
      this.msg = MSG("load", "加载中");
      let blogId = this.$route.query.blogId;
      request({
        url: "/blogBlog/getBlogByBlogId",
        method: "post",
        data: {
          blogId: blogId,
          //这个接口不需要token，但是需要验证
          token:
            this.$store.state.token == null || this.$store.state.token == ""
              ? "-1"
              : this.$store.state.token,
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.blog = res.data.data.blog;
            this.commentForm.content = this.blog.blog_content;
            this.commentForm.img = this.blog.blog_main_img;
            this.commentForm.title = this.blog.blog_title;
            this.commentForm.uid = this.blog.blog_author_id;
            if(!this.blog.blog_json_nav){
              this.blog.blog_json_nav = []
            }
          } else {
            MSG("error", res.data.msg);
          }
          this.msg.close();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.msg.close();
          this.loading = false;
        });
    },
    //监听ctrl+s并修改
    saveContent(e) {
      var key = window.event.keyCode
        ? window.event.keyCode
        : window.event.which;
      if (key === 83 && e.ctrlKey) {
        //保存博客
        this.toPublishBlog(0);
        e.preventDefault();
      }
    },
    /**
     * 返回上一页
     */
    toBack() {
      this.$router.push({ path: this.$route.query.route });
    },
    onEditorChange($event) {
      //监听富文本操作
      console.log($event);
      this.$emit("input", this.commentForm.content);
    },
    toClickUpLoad() {
      document.querySelector(".main_img_upload input").click();
    },
    closeNavEditor() {
      this.navEditor = false;
    },
    updateNav(obj) {
      this.isLoadingNavEditor = true;
      this.blog.blog_json_nav = obj;
      console.log(this.blog.blog_json_nav);
      requestToken({
        url: "/blogBlog/editBlogNav",
        method: "post",
        data: {
          blogJsonNav: obj,
          blogId: this.blog.blog_id,
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            MSG("success", res.data.msg);
            this.blog.blog_json_nav = JSON.parse(JSON.stringify(obj));
          } else {
            MSG("error", res.data.msg);
            if (res.data.code == 300) {
              clearToken();
            }
          }
          this.isLoadingNavEditor = false;
        })
        .catch((error) => {
          this.isLoadingNavEditor = false;
          MSG("error", "网络错误，无法访问");
          console.log("update_blog_error:" + error);
        });
    },
    toPublishBlog(type) {
      if (this.commentForm.title == "" || this.commentForm.title == null) {
        MSG("error", "请为博客拟定标题！");
        return false;
      }
      if (this.commentForm.content == "" || this.commentForm.content == null) {
        MSG("error", "文章内容不能为空！");
        return false;
      }
      if (this.commentForm.img == "" || this.commentForm.img == null) {
        MSG("error", "请上传一张封面！");
        return false;
      }
      this.commentForm.uid = this.$store.state.user.uid;
      requestToken({
        url: "/blogBlog/toUpdateBlog",
        method: "post",
        data: {
          form: this.commentForm,
          blogId: this.blog.blog_id,
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            MSG("success", type == 0 ? "保存成功！" : "已自动保存！");
            this.blog.blog_content = this.commentForm.content;
            this.blog.blog_main_img = this.commentForm.img;
            this.blog.blog_title = this.commentForm.title;
          } else {
            MSG("error", res.data.msg);
            if (res.data.code == 300) {
              clearToken();
            }
          }
        })
        .catch((error) => {
          MSG("error", "网络错误，无法访问");
          console.log("update_blog_error:" + error);
        });
    },
    uploadSuccess(res, f, fl) {
      let quill = this.$refs.quillEditor.quill;
      if (res.stat == 200) {
        MSG("success", "图片上传成功！");
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片  res.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", res.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        MSG("error", "服务器异常，上传失败！");
        console.log(f + fl);
      }
    },
    uploadSuccess2(res, f, fl) {
      let quill = this.$refs.quillEditor.quill;
      if (res.stat == 200) {
        MSG("success", "图片上传成功！");
        this.commentForm.img = res.url;
      } else {
        MSG("error", "服务器异常，上传失败！");
        console.log(f + fl + quill);
      }
    },
    uploadError(res, f, fl) {
      MSG("error", "图片异常，无法上传！");
      console.log(res + f + fl);
    },
  },
};
</script>
<style scoped>
/*整体样式*/
.blogDetailPanelComponent {
  min-height: 40rem;
  background: #ffffff;
  box-shadow: 0 0 0.5rem #cccccc; /*设置阴影*/
  border-radius: 0.4rem; /*定义圆角*/
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 0.8rem;
}
p {
  margin-top: 0.5rem;
  text-align: center;
}
/*头部区域*/
.blogNotice {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  user-select: none;
}
.blogNotice span:nth-child(1) {
  min-width: 2rem;
}
/*面包屑，索引*/
a:-webkit-any-link {
  font-weight: 900;
  text-decoration: none;
  color: #000000;
}
.blogNotice span:nth-child(2) {
  flex: 1;
}
.blogNotice span:nth-child(3) {
  min-width: 2rem;
}
.blogNotice span:nth-child(3) span span {
  font-weight: 900;
  color: rgb(230, 162, 60);
}

input::-webkit-input-placeholder {
  color: #ccc;
  font-size: 1rem;
}
input::-moz-input-placeholder {
  color: #ccc;
}
input::-ms-input-placeholder {
  color: #ccc;
}

/deep/ .ql-editor {
  min-height: 16rem;
  max-height: 18rem;
  word-break: break-all;
}

.nav-btn {
  display: block;
  width: 100%;
  height: 2rem;
  margin-left: 0.2rem;
  outline: none;
  border: none;
  color: #fff;
  background: #158bf1;
  border-radius: 8px;
  cursor: pointer;
}
@media screen and (max-width: 850px) {
  .hidden_shangchuan {
    display: none;
  }
}
</style>
<style lang="scss">
@import "../../../../store/msg.css";
</style>