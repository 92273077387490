<template>
  <el-dialog
    title="举报信息"
    :visible.sync="reportDialogShow"
    width="600px"
    center
    @close="close()">
    <div class="mainBody">
      <div class="body">
        <el-descriptions :column="2" style="margin-left: 10px">
          <template #title>
            <div style="font-weight: normal;"><span>具体内容</span></div>
          </template>
          <el-descriptions-item label="用户昵称">{{ form.commentUserName==null?'':form.commentUserName }}</el-descriptions-item>
          <el-descriptions-item label="举报类别">{{ form.reportType===0?'用户':form.reportType===1?'博客':'评论' }}</el-descriptions-item>
        </el-descriptions>
        <div style="margin-left: 10px">
          <span>{{ form.reportType === 0 ? '用户电话' : form.reportType === 1 ? '博客标题' : '评论内容' }}：</span>
          <span style="color: #ff9900">{{ form.content }}</span>
        </div>
      </div>
      <el-form :model="form" :rules="rules" ref="form" label-width="0" class="demo-ruleForm">
        <div>举报原因</div>
        <el-form-item label="" prop="reportReason">
          <el-input v-model="form.reportReason" type="textarea" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button @click="close" type="info" size="small" icon="el-icon-back">取消</el-button>
        <el-button @click="toReportCommit('form')" type="danger" size="small" icon="el-icon-upload2">提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {clearToken, requestToken} from "../../../../store/request";
import {MSG} from "../../../../store/msg";

export default {
  props:[],
  name: "reportDialog",
  data(){
    return{
      reportDialogShow:false,
      form:{
        targetId:null,
        commentUserName:null,//只做页面显示
        content:null,//只做页面显示
        reportReason:null,
        reportType:null,
        handleUserId:this.$store.state.user.uid,
      },
      rules:{
        reportReason: [
          { required: true, message: '请说明原因', trigger: 'change' }
        ],
      }
    }
  },
  methods:{
    toReportCommit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          requestToken({
            url:'/blogReport/toReport',
            method:'post',
            data:this.form
          }).then((res) => {
            if (res.data.code === 200) {
              MSG('success','举报成功！');
              this.close();
            } else {
              MSG('error',res.data.msg);
              if (res.data.code === 300){
                clearToken();
              }
            }
          })
          .catch((error) => {
            MSG('error','网络错误，举报失败！');
            console.log("reportError：" + error)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    open(targetId,commentUserName,content,type){
      if (this.$store.state.token == null || this.$store.state.token === ''){
        MSG('warning','请先登录！')
        return false
      }
      this.form.targetId = targetId;
      this.form.commentUserName = commentUserName;
      this.form.content = content;
      this.form.reportType = type;
      this.reportDialogShow = true;
    },
    close(){
      this.reportDialogShow = false;
      this.form = {
        targetId:null,
        commentUserName:null,
        content:null,
        reportReason:null,
        reportType:null,
        handleUserId:this.$store.state.user.uid,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .isPE{
    display: none;
  }
  .mainBody{
    .body{
      border: 1px solid #cccccc;
      border-radius: 0.4rem;
      margin-bottom: 0.5rem;
    }
    .btns{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      button{
        flex: 1;
      }
    }
  }
  /deep/.el-dialog--center .el-dialog__body{
    padding: 0.2rem 0.8rem 0.5rem 0.8rem!important;
  }
  /deep/.el-descriptions-item{
    line-height: 0.6rem!important;
    height: 0.8rem!important;
  }

  @media screen and (max-width: 600px){
    .isPC{
      display: none;
    }
    .isPE{
      display: block;
    }
    /deep/ .el-dialog{
      width: 90%!important;
    }
    .mainBody{

    }
  }
</style>