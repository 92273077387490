<template>
  <div class="MainComponent">
    <div class="blogNotice">
      <span>
        &ensp;<router-link :to="{ path: '/index' }">首页</router-link>
        &ensp;<i class="fa fa-angle-right"></i>&ensp;娱乐中心
      </span>
      <span></span>
      <span @click="toBack()" style="font-weight: 500;cursor: pointer">返回&ensp;</span>
    </div>
    <hr style="border:0;background-color:#DCDFE6;height:1px;">
    <!--总体-->
    <div class="mainBody">
      <!--全部、单、双选择器-->
      <div class="choose">
        <div>
          <el-radio-group v-model="gameMemberType" @change="toChangeMember" size="small">
            <el-radio-button label="0">全部</el-radio-button>
            <el-radio-button label="1">单人</el-radio-button>
            <el-radio-button label="2">双人</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <!--搜索框-->
      <div class="search">
        <input v-model="searchText" @keyup.enter="getGameList(1,5,false)"/>
        <button @click="getGameList(1,5,false)"><span class="isPC">搜索</span><span class="isPE"><i class="iconfont">&#xe694;</i></span></button>
      </div>
      <!--列表-->
      <div class="list" v-show="gameList.length > 0 && !isLoading">
        <div v-for="(gameItem,index) in gameList" :key="index">
          <div class="item" @click="openGameDetail(gameItem.gameId);">
            <div class="li">
              <!--主icon-->
              <div class="icon"><i class="iconfont" v-html="gameItem.iconfont"></i></div>
              <!--描述-->
              <div class="text">
                <!--名称-->
                <div>{{gameItem.gameName}}</div>
                <!--简介-->
                <div>{{gameItem.info}}</div>
              </div>
            </div>
            <div class="shape"></div>
            <div class="label">{{gameItem.memberType===1?'单人':'双人'}}</div>
          </div>
        </div>
      </div>
      <!--结果为空-->
      <div v-show="!isLoading && gameList.length === 0">
        <el-empty :image-size="200">
          <template slot="description">
              <span style="color: #777777">
                搜索结果为空，尝试<span @click="$router.go(0)" style="color: #158bf1;cursor: pointer">刷新</span>
              </span>
          </template>
          <template slot="image"><img src="../../../../assets/img/noData.png"/></template>
        </el-empty>
      </div>
      <!--加载-->
      <div v-show="isLoading">
        <el-empty description="精彩即将呈现" :image-size="200">
          <template slot="image"><img src="../../../../assets/img/pic_wait.png"/></template>
        </el-empty>
      </div>
      <!--结果-->
      <div class="result" v-show="gameList.length > 0 && !isLoading">
        <button @click="getGameList(pages + 1,5,true);" v-show="total > gameList.length">剩余{{total - gameList.length}}条，点击加载</button>
        <p v-show="total === gameList.length">已全部加载{{total}}条</p>
      </div>
      <!--dialog弹出层-->
      <game-detail-dialog ref="child" :centerDialogVisible="dialogVisible" @closeDialog="dialogVisible = false"></game-detail-dialog>
    </div>
  </div>
</template>

<script>
import GameDetailDialog from "./gameDetailDialog";
import {clearToken, requestToken} from "../../../../store/request";
import {MSG} from "../../../../store/msg";
export default {
  name: "gameCenterPart",
  components: {GameDetailDialog},
  data(){
    return{
      gameList:[],
      searchText:null,
      gameMemberType:'0',//默认选择器（全部、单人、双人）
      dialogVisible:false,//详情显示
      isLoading:true,
      total:0,
      pages:1,
    }
  },
  created() {
    this.getGameList(1,5,false);
  },
  methods:{
    /**
     * 返回上一页
     */
    toBack(){
      this.$router.go(-1);
    },
    getGameList(index,num,isContinue){
      this.isLoading = true;
      requestToken({
        url: "/blogGameCenter/getGameList",
        method: 'post',
        data:{
          searchText:this.searchText==null?'':this.searchText,
          pageNum:index,
          num:num,
          memberType:this.gameMemberType
        }
      }).then((res)=>{
        this.isLoading = false;
        if (res.data.code === 200) {
          if (isContinue === true){
            for (let i = 0; i < res.data.data.list.length; i++) {
              this.$set(this.gameList,this.gameList.length,res.data.data.list[i]);
            }
          }else{
            this.gameList = res.data.data.list;
          }
          this.total = res.data.data.total;
          this.pages = res.data.data.index;
        } else {
          MSG('error',res.data.msg);
          if (res.data.code === 300){
            clearToken();
          }
        }
      }).catch((error) => {
        this.isLoading = false;
        MSG('error','网络错误，操作失败！');
        console.log("error:" + error)
      })
    },
    toChangeMember(){
      this.getGameList(1,5)
    },
    openGameDetail(gameId){
      this.$refs.child.getGameDetail(gameId);
      this.dialogVisible = true;
    },
  }
}
</script>
<style scoped>
/*整体样式*/
.MainComponent{
  min-height: 40rem;
  background: #FFFFFF;
  box-shadow: 0 0 0.5rem #cccccc;/*设置阴影*/
  border-radius: 0.4rem;/*定义圆角*/
  padding: 1rem 1rem 1rem 1rem;
}
/*头部区域*/
.blogNotice{
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}
.blogNotice span:nth-child(1){
  min-Width: 2rem;
}
/*面包屑，索引*/
a:-webkit-any-link{
  font-weight: 900;
  text-decoration:none;
  color: #000000;
}
.blogNotice span:nth-child(2){
  flex: 1;
}
.blogNotice span:nth-child(3){
  min-Width: 2rem;
}
.blogNotice span:nth-child(3) span span{
  font-weight: 900;
  color: rgb(230, 162, 60);
}
</style>

<style lang="scss" scoped>
.isPE{
  display: none;
}
.mainBody{
  .choose{
    margin: 0.8rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    div{
    }
  }
  .search{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.8rem;
    input{
      background: #EBEEF5;
      border: none;
      color: #606266;
      outline: none;
      height: 1.2rem;
      display: inline-block;
      font-size: 1.1rem;
      width: 60%;
      min-width: 8rem;
      padding: 0.5rem;
      margin-right: 0.4rem;
      border-radius: 0.2rem;
    }
    button{
      width: 4rem;
      height: 2rem;
      background: #409eff;
      cursor: pointer;
      border: none;
      color: #FFFFFF;
      font-size: 1rem;
      border-radius: 4px;
      letter-spacing: 0.1rem;
    }
  }
  .list{
    margin: auto;
    width: 90%;
    background: #EBEEF5;
    padding: 0.5rem 0.5rem 0.1rem 0.5rem;
    border-radius: 0.4rem;
    .item{
      position: relative;
      background: #FFFFFF;
      margin-bottom: 0.4rem;
      border-radius: 0.4rem;
      cursor: pointer;
      .li{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 4rem;
        .icon{
          width: 4rem;
          min-width: 4rem;
          height: 4rem;
          margin-right: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 3rem;
        }
        .text{
          display: flex;
          flex-direction: column;
          div:nth-child(1){
            font-weight: bold;
            font-size: 1rem;
            line-height: 1.5rem;
            height: 1.5rem;
          }
          div:nth-child(2){
            font-size: 0.9rem;
            color: #909399;
            display: -webkit-box !important;
            -webkit-box-orient: vertical !important;
            -webkit-line-clamp: 1 !important;
            overflow: hidden !important;
            margin-right: 3rem;
          }
        }
      }
      .shape{
        position: absolute;
        z-index: 998;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-top: 2.4rem solid #ff9900;
        border-left: 3rem solid transparent;
      }
      .label{
        position: absolute;
        z-index: 998;
        top: 0.1rem;
        right: 0.1rem;
        font-size: 0.8rem;
        color: #FFFFFF;
      }
    }
  }
  .result{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.5rem;
    button{
      background: #009688;
      height: 1.8rem;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;
      padding: 0.2rem 0.4rem;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 600px){
  .isPC{
    display: none;
  }
  .isPE{
    display: block;
  }
  .mainBody{
    .choose{
      height: 1.2rem;
    }
    .search{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.6rem;
      input{
        flex: 1;
      }
      button{
        width: 2rem;
      }
    }
    .list{
      width: 95%;
    }
  }
}
</style>
