import axios from 'axios';
import store from "@/store/index";
import router from "../router"

let ip = store.state.ip;
let port = store.state.port;
let prefix = "http://" + ip + ":" + port + "/";

//普通的请求--不需要token
export function request(config) {
    const instance = axios.create({
        baseURL: prefix,
        timeout: 100000
    });
    return instance(config);
}

//需要token的请求
export function requestToken(config) {
    config.headers = {
        'Authorization': store.state.token
    };
    const instance = axios.create({
        baseURL: prefix,
        timeout: 100000
    });
    return instance(config);
}

//文件上传请求的方法
export function requestTokenFile(config) {
    config.headers = {
        'Authorization': store.state.token,
        'Content-Type': 'multipart/form-data'
    };
    const instance = axios.create({
        baseURL: prefix,
        timeout: 100000,
    });
    return instance(config);
}

//清除Token
export function clearToken() {
    store.state.token = null;
    store.state.user = null;
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    router.push('/index');
}

//返回上层路由
export function returnBack(){
    console.log(window.history.length)
    if (window.history.length <= 1){
        //若没有历史，replace回主页
        router.replace({path: '/'})
    }else{
        router.back()
    }
}



