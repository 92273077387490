import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});
const store = new Vuex.Store({
  state: sessionStorage.getItem("state")
    ? JSON.parse(sessionStorage.getItem("state"))
    : {
        user: {},
        token: null,
        // ip: "localhost",
        ip: "vanchor.top",
        port: "8081",
        searchText: null,
        searchType: null,
        viewBlogId: null,
        isHaveRound: null,
        game_level: 0,
      },
  plugins: [vuexLocal.plugin],
});

// 使用 export default 封装，让外部可以访问
export default store;
