<template>
  <div>
    <!--顶部菜单栏-->
    <top-bar ref="topBar" @goUserCenter="toUserCenter"></top-bar>
    <div class="topBarHeight"></div>
    <div class="mainPanel">
    <span>
      <!--主面板，新建博客（id为0），编辑（other）-->
      <user-info-component ref="userInfoMainPanel"></user-info-component>
    </span>
      <span>
      <!--侧面板-->
      <side-panel></side-panel>
    </span>
    </div>
  </div>
</template>

<script>
import TopBar from "../topBar";
import SidePanel from "../sideBar/sidePanel";
import userInfoComponent from "./components/userInfoComponent";
export default {
  name: "blogDetailPanel",
  components: {SidePanel, TopBar,userInfoComponent},
  data(){
    return{
      uId:null,
      isCenter:true,
    }
  },
  created() {
    this.judgeUserId();
  },
  methods:{
    judgeUserId(){
      if (this.$route.query.uId == 0){
        this.uId = this.$store.state.user.uid
      }else{
        this.uId = this.$route.query.uId;
        this.isCenter = false;
      }
    },
    toUserCenter(data){
      this.uId = data;
      this.judgeUserId();
      this.$refs.userInfoMainPanel.isCenter = true;
      this.$refs.userInfoMainPanel.uId = this.$store.state.uid;
      this.$refs.userInfoMainPanel.$refs.blogDetails.uId = null;
      this.$refs.userInfoMainPanel.$refs.blogDetails.getBlogs(1,5);
      this.$refs.userInfoMainPanel.$refs.blogDetails1.uId = null;
      this.$refs.userInfoMainPanel.$refs.blogDetails1.getBlogs(1,5);
    }
  }
}
</script>
<style>
*{
  margin: 0;
}
</style>
<style scoped>
.mainPanel{
  /*此div居中并规划主位置*/
  margin: auto;
  /*开启flex布局*/
  display: flex;
  justify-content: center;
  /*定义宽度*/
  width: 80%;
}
.mainPanel span:nth-child(1){
  flex: 1;
}
.mainPanel span:nth-child(2){
  width: 300px;
  margin-left: 1rem;
}
.topBarHeight{
  height: 6rem;
}
/*当宽度小于1050px时，删除侧栏*/
@media screen and (max-width: 1050px){
  .mainPanel span:nth-child(2){
    display: none;
  }
}
@media screen and (max-width: 850px){
  .topBarHeight{
    height: 4.8rem;
  }
  .mainPanel{
    width: 92%;
  }
}
</style>