<template>
  <div>
    <!--基础topBar展区-->
    <div class="baseSize">
      <!--1    LOGO-->
      <span @click="toJumpIndex">
        <img src="../../assets/icon/logo.png">
        <table>
          <tr><td>万彻小站</td></tr>
          <tr><td>vanchor.top</td></tr>
        </table>
      </span>
      <!--2    空白-->
      <span></span>
      <!--3    首页-->
      <span @click="toJumpIndex">首页</span>
      <!--4    个人中心-->
      <span @click="toUserCenter()">个人中心</span>
      <!--5    空白-->
      <span></span>
      <!--6    搜索框-->
      <span><input placeholder="  寻你所想，觅你所思..." v-model="searchText" @keyup.enter="toSearchBlogs();"/></span>
      <!--7    搜索按钮与下拉更多-->
      <span>
      <span @click="toSearchBlogs();">搜索</span>&ensp;&ensp;
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          更多&nbsp;<i class="fa fa-angle-down"></i>
        </span>
        <!--pc中部下拉-->
        <el-dropdown-menu slot="dropdown" class="dropSpan">
          <el-dropdown-item v-if="$store.state.token != null">
            <span class="dropSpanSon1" @click="toNewBlog(0)"><i class="fa fa-paper-plane"></i>发布博客</span>
          </el-dropdown-item>
          <el-dropdown-item v-if="$store.state.token != null">
            <span class="dropSpanSon2" @click="toUserCenter();"><i class="fa fa-clock-o"></i>我的博客</span>
          </el-dropdown-item>
          <el-dropdown-item v-if="$store.state.token != null">
            <span class="dropSpanSon7" @click="toGameCenter();"><i class="iconfont">&#xe618;</i>娱乐中心</span>
          </el-dropdown-item>
          <el-dropdown-item>
            <span class="dropSpanSon6" @click="toSearchUser();"><i class="fa fa-user-circle-o"></i>搜索用户</span>
          </el-dropdown-item>
          <el-dropdown-item>
            <span class="dropSpanSon8" @click="toSarsCov()"><i class="iconfont">&#xe60a;</i>新冠疫情</span>
          </el-dropdown-item>
          <el-dropdown-item>
            <span class="dropSpanSon3" @click="$router.go(0);"><i class="fa fa-refresh"></i>刷新列表</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </span>
      <!--8    空白-->
      <span></span>
      <!--9    登录信息-->
      <span id="logOutTool">
      <template v-if="$store.state.token != null">
        <!--pc右侧下拉-->
        <el-menu mode="vertical">
          <el-submenu index="1">
            <template slot="title">
              <img
                  :src="$store.state.user.uavatar!=''&&$store.state.user.uavatar!=null ? $store.state.user.uavatar:defaultAvatar">
              <span>
                {{ ($store.state.user.uname != '' && $store.state.user.uname != null) ? $store.state.user.uname : '用户***' + $store.state.user.utel.substring($store.state.user.utel.length - 4) }}
              </span>
              <i class="fa fa-angle-down"></i>
            </template>
            <el-menu-item index="2-1" @click="toUserCenter()">个人中心</el-menu-item>
            <el-menu-item index="2-2">重置密码</el-menu-item>
            <el-menu-item index="2-3" @click="toLogout">退出登录</el-menu-item>
          </el-submenu>
        </el-menu>
      </template>
      <button v-if="$store.state.token == null" @click="toLoginPage()">登录</button>
      <button v-if="$store.state.token == null" @click="toRegistPage()">注册</button>
    </span>
      <!--10    手机端下拉-->
      <span>
      <!--手机端搜索-->
      <img v-if="!isShowSearchPhone" src="../../assets/icon/search.png"
           @click="isShowSearchPhone=(!isShowSearchPhone);">
      <img v-else src="../../assets/icon/close.png" @click="isShowSearchPhone=(!isShowSearchPhone);">
      <img @click="drawer=(!drawer);" class="el-dropdown-link" src="../../assets/icon/menuBar.png">
      <!--手机端菜单-->
      <el-drawer
          class="dropSpan"
          :visible.sync="drawer"
          :direction="direction"
          :append-to-body="true"
          :before-close="handleClose">
        <template #title>
          <el-dropdown-item style="cursor: auto;padding: 0 0 0 1.2rem">
            <span class="dropSpanSon0" v-if="$store.state.token != null" @click="toUserCenter()">
              <img
                  :src="$store.state.user.uavatar!==''&&$store.state.user.uavatar!=null ? $store.state.user.uavatar:defaultAvatar">
              <span
                  class="showUserName">{{ ($store.state.user.uname !== '' && $store.state.user.uname != null) ? $store.state.user.uname : '用户***' + $store.state.user.utel.substring($store.state.user.utel.length - 4) }}</span></span>
            <span class="dropSpanSon0" v-else><img src="../../assets/icon/user.png">
              <button @click="toLoginPage()">登录</button>
              <button @click="toRegistPage()">注册</button>
            </span>
          </el-dropdown-item>
        </template>
        <el-dropdown-item v-if="$store.state.token != null">
          <span class="dropSpanSon1" @click="toNewBlog(0)"><i class="fa fa-paper-plane"></i>发布博客</span>
        </el-dropdown-item>
        <el-dropdown-item v-if="$store.state.token != null">
          <span class="dropSpanSon2" @click="toUserCenter();"><i class="fa fa-clock-o"></i>我的博客</span>
        </el-dropdown-item>
         <el-dropdown-item v-if="$store.state.token != null">
          <span class="dropSpanSon9" @click="onlySeeStar();"><i class="fa fa-star"></i>只看关注</span>
        </el-dropdown-item>
        <el-dropdown-item v-if="$store.state.token != null">
          <span class="dropSpanSon7" @click="toGameCenter();"><i class="iconfont">&#xe618;</i>娱乐中心</span>
        </el-dropdown-item>
        <el-dropdown-item>
          <span class="dropSpanSon6" @click="toSearchUser();"><i class="fa fa-user-circle-o"></i>搜索用户</span>
        </el-dropdown-item>
         <el-dropdown-item>
          <span class="dropSpanSon8" @click="toSarsCov();"><i class="iconfont">&#xe60a;</i>新冠疫情</span>
        </el-dropdown-item>
        <el-dropdown-item>
          <span class="dropSpanSon3" @click="$router.go(0);"><i class="fa fa-refresh"></i>刷新列表</span>
        </el-dropdown-item>
        <el-dropdown-item>
          <span class="dropSpanSon5" @click="getHelp()"><i class="fa fa-book"></i>使用手册</span>
        </el-dropdown-item>
        <el-dropdown-item v-if="$store.state.token != null">
          <span class="dropSpanSon10" @click="feedback()"><i class="fa fa-envelope-open"></i>意见反馈</span>
        </el-dropdown-item>
        <el-dropdown-item>
          <span class="dropSpanSon11" @click="aboutDeveloper()"><i class="fa fa-user"></i>关于站长</span>
        </el-dropdown-item>
        <el-dropdown-item v-if="$store.state.token != null">
          <span class="dropSpanSon4" @click="toLogout"><i class="fa fa-sign-out"></i>退出登录</span>
        </el-dropdown-item>
        <div class="more_no_login" v-if="$store.state.token == null"><i class="iconfont">&#xe675;</i>更多功能请先登录</div>
      </el-drawer>
    </span>
    </div>
    <!--额外搜索区域-->
    <div>
      <phone-search-component :isShowSearchPhone="isShowSearchPhone"></phone-search-component>
    </div>
    <feedback-dialog ref="feedback" />
  </div>
</template>

<script>
import {MSG} from "../../store/msg";
import PhoneSearchComponent from "./basePanelSearch/components/phoneSearchComponent";
import FeedbackDialog from "../otherComponent/feedbackDialog";

export default {
  name: "topBar",
  components: {FeedbackDialog, PhoneSearchComponent},
  data() {
    return {
      searchText: null,
      isShowSearchPhone: false,
      drawer: false,
      direction: 'rtl',
      defaultAvatar: require('../../assets/icon/user.png'),
    }
  },
  methods: {
    /**
     * 前往首页
     */
    toJumpIndex() {
      if (this.$route.path === '/index') {
        this.$router.go(0);
      } else {
        this.$router.push({path: '/index'});
      }
    },
    /**
     * 前往登陆
     */
    toLoginPage() {
      this.$router.push({path: '/login', query: {route: this.$route.fullPath}})
    },
    /**
     * 获取指南
     */
    getHelp() {
      this.$confirm('由于开发缓慢，此功能暂时以博客方式展示！', '说明', {
        confirmButtonText: '仍要前往',
        cancelButtonText: '离开',
        callback: action => {
          if (action !== 'confirm') {
            return false;
          } else {
            if (this.$route.fullPath.startsWith('/blogDetailPanel')) {
              //首先判断是否是当前，是就不做处理
              if (this.$parent.$refs.blogDetail.blogId == 62) {
                return false;
              }
              //是这一页，不能跳转直接调用方法load
              this.$parent.$refs.blogDetail.blogId = 62;
              this.$parent.$refs.blogDetail.getBlogById();
              //因为可能是本页再次请求此方法，所以更改query再赋值
              this.$router.push({path: '/blogDetailPanel', query: {blog_id: 62}});
            } else {
              //走路由
              this.$router.push({path: '/blogDetailPanel', query: {blog_id: 62}});
            }
          }
        }
      });
    },
    /**
     * 关于站长
     */
    aboutDeveloper(){
      if (this.$route.fullPath.startsWith('/blogDetailPanel')){
        this.drawer = false
      }else{
        //走路由
        this.$router.push({path:'/blogDetailPanel',query:{blog_id:87}})
      }
    },
    /**
     * 新型冠状病毒详情
     **/
    toSarsCov(){
      this.$router.push({path: '/sarsCov'})
    },
    /**
     * 新型冠状病毒详情
     **/
    feedback(){
      this.$refs.feedback.open();
    },
    handleClose(done) {
      done();
    },
    /**
     * 搜索博客
     */
    toSearchBlogs() {
      if (this.searchText == null || this.searchText === '') {
        MSG('warning', '请输入内容')
      } else {
        //持久化
        this.$store.state.searchText = this.searchText;
        if (this.$route.fullPath.startsWith('/basePanelSearchPanel')) {
          //调用父组件的其他子组件搜索
          this.$parent.$refs.searchPanel.initSearchText(this.searchText);
        } else {
          this.$router.push({path: '/basePanelSearchPanel'})
        }
      }
    },
    /**
     * 前往搜索用户
     */
    toSearchUser() {
      this.$router.push({path: '/searchUserPanel'})
    },
    /**
     * 前往游戏娱乐中心
     */
    toGameCenter() {
      this.$router.push({path: '/gameCenterPanel'})
    },
    /**
     * 前往用户中心
     * @returns {boolean}
     */
    toUserCenter() {
      if (this.$store.state.token == null) {
        MSG('warning', '尚未登录，请先登录！');
        return false;
      }
      //先路由走过去
      this.$router.push({path: '/userCenterPanel', query: {uId: 0}});
      if (this.$route.fullPath.startsWith('/userCenterPanel')) {
        //emit过去
        this.$emit("goUserCenter", 0);
      }
    },
    /**
     * 前往注册
     */
    toRegistPage() {
      this.$router.push({path: '/regist', query: {route: this.$route.fullPath}})
    },
    /**
     * 创作博客
     * @param blogId
     */
    toNewBlog(blogId) {
      console.log(blogId)
      this.$router.push({path: '/writeBlogPanel'})
    },
    /**
     * 只看关注
     */
    onlySeeStar(){
      if (this.$store.state.token == null || this.$store.state.token === ''){
        MSG('error','请先登录！');
        return false;
      }
      this.$store.state.searchType = 3;
      this.$store.state.searchText = null;
      this.$router.push({path: '/basePanelSearchPanel'})
    },
    /**
     * 登出
     */
    toLogout() {
      this.$confirm('确认登出吗？', '登出', {
        distinguishCancelAndClose:true,
        confirmButtonText: '确定',
        callback: action => {
          if (action == 'confirm') {
            //先隐藏，防止闪屏
            var obj = document.getElementById("logOutTool");
            obj.style.visibility = "hidden"
            this.$store.state.token = null;
            this.$store.state.user = null;
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            if (this.$route.path == '/index') {
              window.location.reload();
            } else {
              this.$router.push({path: '/index'})
            }
          }
        }
      });
    }
  }
}
</script>

<style scoped>
.baseSize {
  z-index: 999; /*将此组件最优先*/
  position: fixed; /*相对浏览器绝对定位*/
  background: rgba(255,255,255,0.97);
  user-select: none; /*用户不可选中*/
  box-shadow: 0 0 0.5rem #cccccc; /*设置阴影*/
  color: #777777; /*设置颜色*/
  height: 5rem;
  width: 100%;
  /*开启弹性布局*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

/*全局设置*/
.baseSize span {
  height: 4rem;
  line-height: 4rem;
  text-align: center;
}

/*logo*/
.baseSize span:nth-child(1) {
  margin-left: 10%;
  cursor: pointer;
  /*width: 150px;暂且注释，可能没用*/
}

.baseSize span:nth-child(1) img {
  width: 3rem;
  float: left;
  margin-top: 0.5rem;
}

.baseSize span:nth-child(1) table {
  float: left;
  margin-top: 0.3rem;
  line-height: 1.4rem;
  color: #777777;
}

.baseSize span:nth-child(1) table tr:nth-child(2) {
  font-size: 0.9rem;
}

/*logo-首页（space）*/
.baseSize span:nth-child(2) {
  width: 30px;
}

/*首页*/
.baseSize span:nth-child(3) {
  cursor: pointer;
  width: 60px;
}

/*个人中心*/
.baseSize span:nth-child(4) {
  cursor: pointer;
  width: 80px;
}

/*个人中心-搜索（space）*/
.baseSize span:nth-child(5) {
  width: 30px;
}

/*搜索*/
.baseSize span:nth-child(6) {
  flex: 4;
}

.baseSize span:nth-child(6) input {
  /*边框*/
  border-radius: 99rem;
  border: 1px solid #777777;
  outline: none;
  /*宽高*/
  width: 80%;
  height: 2rem;
  /*内边距*/
  padding-left: 1rem;
  padding-right: 1rem;
  /*字体大小及颜色*/
  color: #777777;
  font-size: 1.2rem;
}

.baseSize span:nth-child(6) input::placeholder {
  font-size: 1rem;
  color: #cfcfcf;
  margin-left: 1rem;
}

/*搜索按钮与更多下拉选项*/
.baseSize span:nth-child(7) {
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.baseSize span:nth-child(7) span {
  cursor: pointer;
}

.el-dropdown-menu {
  border-radius: 1rem;
  box-shadow: 0 0 1rem #C0C4CC;
}

/deep/ .el-dropdown-link {
  width: 60px;
  display: flex;
  flex-direction: row;
  align-items: center
}

/*左侧与右侧登录信息（space）*/
.baseSize span:nth-child(8) {
  flex: 3.6;
}

/*登录信息*/
.baseSize span:nth-child(9) {
  min-width: 200px;
  margin-right: 10%;
}

.baseSize span:nth-child(9) img {
  width: 3rem;
  border-radius: 100px;
  vertical-align: middle;
}

/*去除elementUI原装的箭头^*/
/deep/ .el-icon-arrow-down:before {
  display: none;
}

/*去除elementUI右边框*/
/deep/ .el-menu {
  border: none;
}

/deep/ .el-submenu__title {
  color: #777777;
  height: 4rem;
}

/*去除elementUI鼠标放上去的颜色改变*/
/deep/ .el-submenu__title:hover {
  background: #FFFFFF;
}

/*整个下拉的外形*/
/deep/ .el-submenu .el-menu {
  box-shadow: 0 0 1rem #C0C4CC;
  border-radius: 1rem;
}

/*放上去时渐变*/
/deep/ .el-menu-item:hover {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(64, 158, 255, 0.2) 50%, rgba(255, 255, 255, 0));
}

/*选中后透明*/
/deep/ .el-menu-item:focus {
  background: rgba(0, 0, 0, 0);
}

/*选中的目标渐变*/
/deep/ .el-menu-item.is-active {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ecf5ff 50%, rgba(255, 255, 255, 0));
}

.baseSize span:nth-child(9) span {
  margin-left: 10px;
  background: #FFFFFF;
  margin-right: 1rem;
}

.baseSize span:nth-child(9) button {
  border-radius: 99rem;
  width: 5rem;
  height: 2.0rem;
  color: #FFFFFF;
  border: 0px;
  cursor: pointer;
}

.baseSize span:nth-child(9) button:hover {
  border: 1px solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
}

.baseSize span:nth-child(9) button:nth-child(1) {
  background: #37a1f9;
}

.baseSize span:nth-child(9) button:nth-child(2) {
  margin-left: 10px;
  background: #909399;
}

/*手机端适配部分隐藏*/
.baseSize span:nth-child(10) {
  display: none;
}

.dropSpan span {
  font-weight: 900;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
}

.dropSpan span i {
  font-size: 1.6rem;
  width: 1.8rem;
}

.dropSpan span img {
  border-radius: 100%;
  width: 2rem;
  margin-right: 0.5rem;
}

/*个人中心*/
.dropSpanSon0 {
  color: #777777;
  text-align: center;
  cursor: pointer;
}
.dropSpanSon0 img{
  width: 1.8rem!important;
  height: 1.8rem!important;
}

.showUserName {
  font-size: 1rem !important;
  line-height: 2rem;
  height: 2rem;
  width: 100%!important;
  text-align: left !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  overflow: hidden !important;
}

.dropSpanSon0 button {
  color: #FFFFFF;
  min-width: 3rem;
  height: 1.5rem;
  border: none;
  border-radius: 1rem;
}

.dropSpanSon0 button:hover {
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
}

.dropSpanSon0 button:nth-child(2) {
  background: #158bf1;
}

.dropSpanSon0 button:nth-child(3) {
  margin-left: 0.2rem;
  background: #777777;
}

/*发布博客*/
.dropSpanSon1 {
  color: rgb(0, 150, 136);
  text-align: center;
}

/*我的博客*/
.dropSpanSon2 {
  color: #1e9f75;
}

/*刷新列表*/
.dropSpanSon3 {
  color: #158bf1;
}

/*退出登录*/
.dropSpanSon4 {
  color: #ea605c;
}

/*帮助手册*/
.dropSpanSon5 {
  color: rgb(13, 137, 25);
}

/*搜索用户*/
.dropSpanSon6 {
  color: rgba(21, 139, 241, 0.9);
}

/*娱乐中心*/
.dropSpanSon7 {
  color: #b421ee;
}

/*新型冠状病毒肺炎疫情*/
.dropSpanSon8 {
  color: #BB0000;
}

/*只看关注*/
.dropSpanSon9 {
  color: #e6a23c;
}

/*反馈意见*/
.dropSpanSon10 {
  color: rgba(129, 84, 117,1);
}

/*关于站长*/
.dropSpanSon11 {
  color: rgba(119, 119, 119,0.8);;
}
/*pc适配-------------------------------------------------------------------------------*/
/*pc适配-------------------------------------------------------------------------------*/
/*pc适配-------------------------------------------------------------------------------*/
@media screen and (max-width: 1200px) {
  /*个人中心删除*/
  .baseSize span:nth-child(4) {
    display: none;
  }

  /*将登录左侧的空格缩短*/
  .baseSize span:nth-child(8) {
    flex: 2;
  }
}

/*pc适配2-------------------------------------------------------------------------------*/
/*pc适配2-------------------------------------------------------------------------------*/
/*pc适配2-------------------------------------------------------------------------------*/
@media screen and (max-width: 1050px) {
  /*首页删除*/
  .baseSize span:nth-child(3) {
    display: none;
  }

  /*将登录左侧的空格再缩短*/
  .baseSize span:nth-child(8) {
    flex: 1;
  }
}

/*pc适配3-------------------------------------------------------------------------------*/
/*pc适配3-------------------------------------------------------------------------------*/
/*pc适配3-------------------------------------------------------------------------------*/
@media screen and (max-width: 900px) {
  /*将登录左侧的空格再缩短*/
  .baseSize span:nth-child(8) {
    display: none;
  }
}

/*进入手机端模式*/
@media screen and (max-width: 850px) {
  .dropSpan span {
    font-weight: 900;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    margin-left: 0.4rem;
  }
  .dropSpan span i{
    margin-right: 0.8rem;
  }
  /deep/.el-drawer{
    width: 14rem!important;
  }
  /deep/.el-drawer__header{
    padding: 1rem 1rem 0 0.2rem;
    margin-bottom: 0;
  }
  /deep/.el-dropdown-menu__item{
    padding: 0 0 0 1.4rem;
  }
  .more_no_login{
    font-size: 0.8rem;
    color: #909399;
    margin-left: 1.6rem;
    margin-top: 1rem;
  }
  .more_no_login i{
    margin-right: 0.8rem;
  }
  /*删除所有pc端展示的内容*/
  .baseSize span:nth-child(5) {
    display: none;
  }

  .baseSize span:nth-child(6) {
    display: none;
  }

  .baseSize span:nth-child(7) {
    display: none;
  }

  .baseSize span:nth-child(9) {
    display: none;
  }

  .baseSize span:nth-child(8) {
    display: flex;
  }

  /*调整整体高度*/
  .baseSize {
    height: 4rem;
  }

  /*调整logo以及logo附带的文字*/
  .baseSize span:nth-child(1) img {
    width: 2.6rem;
    margin-top: 0.8rem;
  }

  .baseSize span:nth-child(1) table {
    line-height: 1.0rem;
    margin-top: 0.8rem;
  }

  .baseSize span:nth-child(1) table tr:nth-child(2) {
    font-size: 0.8rem;
  }

  /*中间留空，使布局稳定不崩溃*/
  .baseSize span:nth-child(8) {
    flex: 1;
  }

  /*手机端隐藏部分恢复显示*/
  .baseSize span:nth-child(10) {
    margin-right: 8%;
    display: flex;
    /*在侧轴居中*/
    align-items: center;
  }

  /*搜索图标*/
  .baseSize span:nth-child(10) img:nth-child(1) {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.4rem;
  }

  /*菜单栏图标*/
  .baseSize span:nth-child(10) img:nth-child(2) {
    width: 1.6rem;
    height: 1.6rem;
  }
}

/*极小机型适配--删除logo文字*/
@media screen and (max-width: 275px) {
  .baseSize span:nth-child(1) table {
    display: none;
  }
}
</style>
<style>
@media screen and (max-width: 500px) {
  .el-message-box {
    display: inline-block;
    width: 90%;
    padding-bottom: 10px;
    vertical-align: middle;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    font-size: 18px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    text-align: left;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}
</style>