<template>
  <div class="sideBlog">
    <p>最新博文</p>
    <p v-if="!isError && !isLoading && total!=0 && (($route.fullPath.startsWith('/blogDetailPanel') && !$parent.$parent.$refs.blogDetail.loading) || !$route.fullPath.startsWith('/blogDetailPanel'))">
      <span v-for="(blog,index) in blogs" :key="index">
        <span class="myList" :style="$route.fullPath.startsWith('/blogDetailPanel') && $parent.$parent.$refs.blogDetail.blogId == blog.blog_id?'cursor:auto;color:#e6a23c;':'cursor:auto'">
          <a v-if="blog.isSeeing!=null && blog.isSeeing==1"><button>正在浏览<i style="margin-left: 0.2rem" class="fa fa-angle-right"></i></button></a>
          <a @click="getBlogDetail(blog.blog_id)" :style="$route.fullPath.startsWith('/blogDetailPanel') && $parent.$parent.$refs.blogDetail.blogId == blog.blog_id?'':'cursor:pointer'">{{blog.blog_title}}</a>
        </span>
      </span>
    </p>
    <p v-if="!isError && !isLoading && total==0" class="err">
      列表为空，尝试<a style="cursor:pointer;color: #1E9FFF;" @click="getBlogs()">刷新</a>
    </p>
    <p v-if="!isError && isLoading" class="err" style="user-select: none">
      加载中...
    </p>
    <p v-if="isError" class="err">
      <a>加载异常，尝试</a><a style="cursor:pointer;color: #1E9FFF;" @click="getBlogs()">刷新</a>
    </p>
    <p v-if="$route.fullPath.startsWith('/blogDetailPanel') && total != 0 && $parent.$parent.$refs.blogDetail.loading" class="err" style="user-select: none">
      正在跳转...
    </p>
  </div>
</template>

<script>
import {request} from "../../../../store/request";

export default {
  name: "sideBlog",
  data(){
    return{
      isLoading:false,
      isError:false,
      total:0,
      blogs:[],
    }
  },
  created() {
    this.getBlogs(1,3);
  },
  methods:{
    getBlogs(index,num){
      this.isError = false;
      this.isLoading = true;
      request({
        url: "/blogBlog/getSearchBlogJson",
        method: 'post',
        data:{
          uId:0,
          type:2,
          searchText:'',
          pageNum:index,
          num:num,
        }
      })
      .then((res)=>{
        //正常走逻辑
        this.blogs = res.data.data.blogs
        this.total = res.data.data.total;
        this.isLoading = false;
        //如果是详情页，就判断谁在被看
        if (this.$route.fullPath.startsWith('/blogDetailPanel')){
          this.whoIsSeeing();
        }
      })
      .catch((err)=>{
        console.error(err);
        this.isLoading = false;
        this.isError = true;
      })
    },
    whoIsSeeing(){
      let blogId = this.$parent.$parent.$refs.blogDetail.blogId;
      for (let i = 0; i < this.blogs.length; i++) {
        if (blogId == this.blogs[i].blog_id){
          this.blogs[i].isSeeing = 1;
        }else{
          this.blogs[i].isSeeing = 0;
        }
      }
      this.$forceUpdate();
    },
    getBlogDetail(blog_id){
      if (this.$route.fullPath.startsWith('/blogDetailPanel')){
        //首先判断是否是当前，是就不做处理
        if(this.$parent.$parent.$refs.blogDetail.blogId == blog_id){
          return false;
        }
        //是这一页，修改路由，然后刷新页面
        this.$router.push({path:'/blogDetailPanel',query:{blog_id:blog_id}});
      }else{
        //走路由
        this.$router.push({path:'/blogDetailPanel',query:{blog_id:blog_id}});
      }
    },
  }
}
</script>

<style scoped>
  .sideBlog{
    background: #FFFFFF;
    height: 8rem;
    box-shadow: 0 0 0.5rem #cccccc;/*设置阴影*/
    border-radius: 0.4rem;/*定义圆角*/
    padding: 1rem 1rem 1rem 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sideBlog p:nth-child(1){
    height: 2rem;
    line-height: 2rem;
    font-size: 1.1rem;
    font-weight: 550;
    letter-spacing: 0.2rem;
    user-select: none;
    /*display: -webkit-box !important;*/
    /*-webkit-box-orient: vertical !important;*/
    /*-webkit-line-clamp: 1 !important;*/
    /*overflow: hidden !important;*/
  }
  .myList{
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 1 !important;
    overflow: hidden !important;
  }
  .myList a:nth-child(1) button{
    background: #E6A23C;
    border: none;
    color: #FFFFFF!important;
    border-radius: 4px;
    padding: 0.2rem 0.4rem;
    cursor: auto;
    margin-right: 0.2rem;
  }
  .myList a:nth-child(1) button:hover{
    box-shadow: rgba(230, 162, 60,0.2) 0px 4px 8px 0px;
  }

  .sideBlog p:nth-child(2){
    display: flex;
    flex-direction: column;
    width: 94%;
    flex: 1;
  }
  .sideBlog p:nth-child(2) span{
    width: 100%;
    height: 2rem;
    line-height: 2rem;
    border-bottom: 1px solid #cfcfcf;
    cursor: pointer;
  }
  .sideBlog p:nth-child(2) span:nth-child(3){
    border: none;
  }
  .err{
    display: flex;
    flex-direction: row!important;
    align-items: center;
    justify-content: center;
  }
  /*遮罩层*/
  .box{
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:#FFFFFF;
    opacity:0.7;
  }.box-text{
     position:absolute;
     top:50%;
     bottom:0;
     left:0;
     right:0;
     color: #1E9FFF;
     font-size: 1.2rem;
     text-align: center;
   }
</style>