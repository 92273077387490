<template>
  <div class="sidePanel">
    <span>
      <side-clock></side-clock>
    </span>
    <span>
      <side-quick></side-quick>
    </span>
    <span>
      <side-blog ref="sideBlog"></side-blog>
    </span>
  </div>
</template>

<script>
import SideBlog from "./components/sideBlog";
import SideQuick from "./components/sideQuick";
import SideClock from "./components/sideClock";
export default {
  name: "sidePanel",
  components: {SideClock, SideQuick, SideBlog},
}
</script>

<style scoped>
  .sidePanel{
    height: 32rem;
    /*开启flex布局*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .sidePanel span:nth-child(1) , .sidePanel span:nth-child(2){
    margin-bottom: 1rem;
  }
</style>