<template>
  <div class="sideQuick">
    <p>
      <span @click="onlySeeStar();"><i class="fa fa-star" style="color: #e6a23c;"></i>只看关注</span>
      <span></span>
      <span @click="aboutDeveloper();"><i class="fa fa-user" style="color: #777777;"></i>关于站长</span>
    </p>
    <p></p>
    <p>
      <span @click="feedback()"><i class="fa fa-envelope-open" style="color: #815475"></i>反馈意见</span>
      <span></span>
      <span @click="getHelp()"><i class="fa fa-book" style="color: #0d8919;"></i>帮助手册</span>
    </p>
    <feedback-dialog ref="feedback" />
  </div>
</template>

<script>
import {MSG} from "../../../../store/msg";
import FeedbackDialog from "../../../otherComponent/feedbackDialog";

export default {
  name: "sideQuick",
  components: {FeedbackDialog},
  methods:{
    //只看关注
    onlySeeStar(){
      if (this.$store.state.token == null || this.$store.state.token === ''){
        MSG('error','请先登录！');
        return false;
      }
      this.$store.state.searchType = 3;
      this.$store.state.searchText = null;
      if (this.$route.fullPath.startsWith('/basePanelSearchPanel')) {
        //调用父组件的其他子组件搜索
        this.$parent.$parent.$refs.searchPanel.initSearchText();
      } else {
        this.$router.push({path: '/basePanelSearchPanel'})
      }
    },
    //关于站长
    aboutDeveloper(){
      if (this.$route.fullPath.startsWith('/blogDetailPanel')){
        //首先判断是否是当前，是就不做处理
        if(this.$parent.$parent.$refs.blogDetail.blogId === 87){
          return false;
        }
        //是这一页，不能跳转直接调用方法load
        this.$parent.$parent.$refs.blogDetail.blogId = 87;
        this.$parent.$parent.$refs.blogDetail.getBlogById();
        //因为可能是本页再次请求此方法，所以更改query再赋值
        this.$router.push({path:'/blogDetailPanel',query:{blog_id:87}});
      }else{
        //走路由
        this.$router.push({path:'/blogDetailPanel',query:{blog_id:87}});
      }
    },
    feedback(){
      this.$refs.feedback.open();
    },
    //帮助手册
    getHelp(){
      this.$confirm('由于开发缓慢，此功能（仅含手机端）暂时以博客方式展示！', '很抱歉的通知', {
        confirmButtonText: '仍要前往',
        cancelButtonText: '离开',
        callback: action => {
          if (action !== 'confirm'){
            return false;
          }else{
            if (this.$route.fullPath.startsWith('/blogDetailPanel')){
              //首先判断是否是当前，是就不做处理
              if(this.$parent.$parent.$refs.blogDetail.blogId === 62){
                return false;
              }
              //是这一页，不能跳转直接调用方法load
              this.$parent.$parent.$refs.blogDetail.blogId = 62;
              this.$parent.$parent.$refs.blogDetail.getBlogById();
              //因为可能是本页再次请求此方法，所以更改query再赋值
              this.$router.push({path:'/blogDetailPanel',query:{blog_id:62}});
            }else{
              //走路由
              this.$router.push({path:'/blogDetailPanel',query:{blog_id:62}});
            }
          }
        }
      });
    },
  }
}
</script>

<style scoped>
  .sideQuick{
    background: #FFFFFF;
    height: 4rem;
    box-shadow: 0 0 0.5rem #cccccc;/*设置阴影*/
    border-radius: 0.4rem;/*定义圆角*/
    padding: 1rem 1rem 1rem 1rem;

    display: flex;
    flex-direction: column;
  }
  .sideQuick p:nth-child(1){
    flex: 2;
  }
  .sideQuick p:nth-child(2){
    flex: 1;
  }
  .sideQuick p:nth-child(3){
    flex: 2;
  }
  .sideQuick p{
    display: flex;
    flex-direction: row;
    text-align: center;
  }
  .sideQuick p span:nth-child(1){
    cursor: pointer;
    flex: 2;
  }
  .sideQuick p span:nth-child(2){
    flex: 1;
  }
  .sideQuick p span:nth-child(3){
    cursor: pointer;
    flex: 2;
  }
  i{
    margin-right: 0.2rem;
  }
</style>