<template>
  <div class="comment_body">
    <!--发表评论打字区-->
    <div class="publish_comment">
      <div class="one">
        <!--头像-->
        <img @click="$emit('toUserCenter1',$store.state.user.uid)"
             :src="$store.state.user != null && $store.state.user.uavatar!=='' && $store.state.user.uavatar!=null ? $store.state.user.uavatar:defaultAvatar" />
        <!--评论输入框-->
        <textarea
            v-model="commentForm.commentMsg"
            id="commentMsg"
            :placeholder="commentForm.placeholder"/>
      </div>
      <div class="two">
        <!--发表按钮-->
        <button @click="toPublishComment()"><span class="isPC">发表</span>评论<i class="el-icon-edit"></i></button>
        <!--取@按钮-->
        <button
            :style="commentForm.replyId == null?'cursor:not-allowed':'background:#e6a23c'"
            @click="commentForm.replyId == null?'':toCancelReply()">取消<span class="isPC">回复</span>@</button>
      </div>
    </div>
    <!--评论展示区-->
    <div class="parent" v-for="(item,index) in comments" :key="index">
      <div class="main">
        <!--头像-->
        <img @click="$emit('toUserCenter1',item.commentUserId)"
             :src="item.userAvatar!=='' && item.userAvatar!=null ? item.userAvatar:defaultAvatar"  alt=""/>
        <!--信息-->
        <div class="info">
          <!--用户名-->
          <div>{{ item.userName }} <button class="authorBtn" v-if="item.commentUserId === blog.blog_author_id">博主</button></div>
          <!--评论内容-->
          <div>{{ item.commentContent }}</div>
          <!--发表日期-->
          <div>{{ item.commentDatetime | dateFormat1 }}</div>
          <div>
            <!--点赞一级评论-->
            <i @click="toLikeComment(item.commentId,item.isLike,item)" :class="[item.isLike === 1?'fa fa-thumbs-up':'fa fa-thumbs-o-up']" :style="[{color:item.isLike === 1?'#6b62fd':'#777777'}]">
              <span style="margin-left: 0.2rem">{{item.likeNum}}</span>
            </i>
            <!--评论一级评论-->
            <i class="fa fa-commenting-o"
               @click="toReplyComment(item.commentId,item.userName,item.userPhone)"></i>
            <!--复制一级评论-->
            <i v-clipboard:copy="toCopyComment(item.commentContent,item.userName,item.commentDatetime)" v-clipboard:success="onCopy" v-clipboard:error="onError" class="fa fa-copy"></i>
            <!--举报一级评论-->
            <i @click="$emit('toReport',item.commentId,item.userName===''||item.userName==null?item.userPhone.substring(item.userPhone.length-4):item.userName,item.commentContent,2)" class="fa fa-warning"
               v-if="$store.state.user == null || item.commentUserId !== $store.state.user.uid"></i>
            <!--删除一级评论-->
            <el-popconfirm title="确定删除评论吗？"
                           @confirm="delCommentById(item.commentId)" v-else>
              <el-button type="text" slot="reference"><i class="fa fa-trash"></i></el-button>
            </el-popconfirm>
          </div>
        </div>
      </div>
      <div class="son_all" v-show="item.comments.length > 0">
        <div class="son" v-for="(it,ind) in item.comments" :key="ind">
          <div class="main_1">
            <!--2级评论  头像-->
            <img @click="$emit('toUserCenter1',it.commentUserId)"
                 :src="it.userAvatar!=='' && it.userAvatar!=null ? it.userAvatar:defaultAvatar" />
            <div class="info">
              <!--2级评论  用户名-->
              <div>
                {{ it.userName }}
                <button class="authorBtn" v-if="it.commentUserId === blog.blog_author_id">博主</button>
                <span v-show="it.needShow" style="color: #606266">
                  <span style="margin-left: 0.1rem;margin-right: 0.1rem;color: #606266">回复</span>
                  {{ getParentCommentByPid(it.commentPid).userName }}
                  <button class="authorBtn" v-if="blog.blog_author_id === getParentCommentByPid(it.commentPid).commentUserId">博主</button>
                </span>
              </div>
              <!--2级评论  评论内容-->
              <div>{{ it.commentContent }}</div>
              <!--2级评论  时间-->
              <div>{{ it.commentDatetime | dateFormat1 }}</div>
              <div>
                <!--点赞一级评论-->
                <i @click="toLikeComment(it.commentId,it.isLike,it)" :class="[it.isLike === 1?'fa fa-thumbs-up':'fa fa-thumbs-o-up']" :style="[{color:it.isLike === 1?'#6b62fd':'#777777'}]">
                  <span style="margin-left: 0.2rem">{{it.likeNum == null?0:it.likeNum}}</span>
                </i>
                <!--评论二级评论-->
                <i class="fa fa-commenting-o"
                   @click="toReplyComment(it.commentId,it.userName,it.userPhone)"></i>
                <!--复制二级评论-->
                <i v-clipboard:copy="toCopyComment(it.commentContent,it.userName,it.commentDatetime)" v-clipboard:success="onCopy" v-clipboard:error="onError" class="fa fa-copy"></i>
                <!--举报二级评论-->
                <i @click="$emit('toReport',it.commentId,it.userName===''||it.userName==null?it.userPhone.substring(it.userPhone.length-4):it.userName,it.commentContent,2)" class="fa fa-warning"
                   v-if="$store.state.user == null || it.commentUserId !== $store.state.user.uid"></i>
                <!--删除二级评论-->
                <el-popconfirm title="确定删除评论吗？"
                               @confirm="delCommentById(it.commentId)" v-else>
                  <el-button type="text" slot="reference"><i class="fa fa-trash"></i></el-button>
                </el-popconfirm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MSG} from "../../../../store/msg";
import {clearToken, requestToken} from "../../../../store/request";

export default {
  name: "blogComments",
  props: ['comments','blog'],
  data() {
    return {
      defaultAvatar: require('../../../../assets/icon/user.png'),
      isPublishCommentLoading: false,//加载状态
      //发表评论表单
      commentForm: {
        replyId: null,
        commentMsg: null,
        placeholder: this.$store.state.token == null?'您还未登录，请先登录才能发表评论哦！':'一起讨论吧~',
        blog_id: null,
        user_id: null
      },
    }
  },
  methods: {
    /**
     * 复制函数
     */
    toCopyComment(text,userName,Time){
      console.log(Time);
      return "@"+userName+"："+text;
    },
    /**
     * 复制成功时的回调
     * @param e
     */
    onCopy (e) {
      MSG('success','复制成功')
      console.log(e);
    },
    /**
     * 复制失败时的回调
     * @param e
     */
    onError (e) {
      MSG('error',"复制失败");
      console.log(e);
    },
    /**
     * 点赞评论
     * @param commentId
     * @param isLike
     * @param obj
     * @returns {boolean}
     */
    toLikeComment(commentId,isLike,obj){
      if (this.$store.state.token==null){
        //说明没有登录
        MSG('warning','您还未登录，登录后才能点赞哦');
        return false;
      }else{
        requestToken({
          url:'/blogComment/toLikeComment',
          method:'post',
          data:{
            uId: this.$store.state.user.uid,
            commentId: commentId,
            type:isLike===1?0:1
          }
        }).then((res) => {
          if (res.data.code === 200) {
            MSG('success',isLike === 1?'取赞成功':'点赞成功');
            obj.isLike = isLike===1?0:1;
            obj.likeNum = isLike===1?(obj.likeNum-1):(obj.likeNum+1);
          } else {
            MSG('error',res.data.msg);
            if (res.data.code === 300){
              clearToken();
            }
          }
        })
        .catch((error) => {
          MSG('error','网络错误，操作失败！');
          console.log("error:" + error)
        })
      }
    },
    /**
     * 删除评论
     * @param commentId
     */
    delCommentById(commentId) {
      requestToken({
        url:'/blogComment/toDelCommentById',
        method:'post',
        data:{
          comment_id: commentId,
          uId:this.$store.state.user.uid===undefined?-1:this.$store.state.user.uid,
          blog_id: this.blog.blog_id
        }
      }).then((res) => {
        if (res.data.code === 200) {
          MSG('success','评论删除成功！');
          this.$emit('changeCommentsData',res.data.data.comments)
        } else {
          MSG('error',res.data.msg);
          if (res.data.code === 300){
            clearToken();
          }
        }
      })
      .catch((error) => {
        MSG('error','网络错误，评论删除失败！');
        console.log("toDelComment_error" + error)
      })
    },
    /**
     * 艾特某条评论
     * @param replyId
     * @param replyName
     * @param userPhone
     */
    toReplyComment(replyId, replyName, userPhone) {
      this.commentForm.commentMsg = null;
      this.commentForm.replyId = replyId;
      if (replyName == null || replyName === '') {
        replyName = "用户***" + userPhone.substring(userPhone.length - 4);
      }
      this.commentForm.placeholder = "@" + replyName;
      document.getElementById('commentMsg').focus();
    },
    /**
     * 取消艾特
     */
    toCancelReply() {
      if (this.commentForm.replyId != null) {
        this.commentForm = {
          replyId: null,
          commentMsg: null,
          placeholder: this.$store.state.token == null?'您还未登录，请先登录才能发表评论哦！':'一起讨论吧~',
          blog_id: null,
          user_id: null
        }
      }
    },
    /**
     * 发表评论
     */
    toPublishComment() {
      if (this.commentForm.commentMsg == null || this.commentForm.commentMsg === '') {
        MSG('warning','评论不能为空！');
        return false;
      }
      this.isPublishCommentLoading = true;
      this.commentForm.blog_id = this.blog.blog_id;
      try{
        this.commentForm.user_id = this.$store.state.user.uid;
      }catch (err){
        MSG('warning','您还未登录，请登录！');
        this.isPublishCommentLoading = false;
        return false;
      }
      if (this.commentForm.replyId == null) {
        this.commentForm.replyId = -1;
      }
      requestToken({
        url:'/blogComment/toPublishComment',
        method:'post',
        data:{
          form:this.commentForm
        }
      }).then((res) => {
        if (res.data.code === 200) {
          MSG('success','评论发表成功！');
          this.commentForm = {
            replyId: null,
            commentMsg: null,
            placeholder: this.$store.state.token == null?'您还未登录，请先登录才能发表评论哦！':'一起讨论吧~',
            blog_id: null,
            user_id: null
          };
          this.$emit('changeCommentsData',res.data.data.comments)
        } else {
          MSG('error',res.data.msg);
          if (res.data.code === 300){
            clearToken();
          }
        }
        this.isPublishCommentLoading = false;
      })
      .catch((error) => {
        MSG('error','网络错误，评论发表失败！');
        console.log("toCommentBlog_error" + error)
        this.isPublishCommentLoading = false;
      })
    },
    /**
     * 获取某条评论的父级对象obj
     * @param commentPid
     * @returns {string}
     */
    getParentCommentByPid(commentPid){
      let ret = ''
      for (const comment of this.comments) {
        for (const commentElement of comment.comments) {
          if (commentElement.commentId === commentPid) {
            ret = commentElement
            break;
          }
        }
      }
      return ret
    },
  }

}
</script>

<style lang="scss" scoped>
  .isPE{
    display: none;
  }
  .comment_body{
    margin-left: 0.5rem;
    //发布评论
    .publish_comment{
      margin-bottom: 1rem;
      .one{
        display: flex;
        flex-direction: row;
        margin-bottom: 0.4rem;
        img{
          width: 3rem;
          height: 3rem;
          border-radius: 100%;
          margin-right: 0.3rem;
        }
        textarea{
          resize: vertical;
          width: 100%;
          border-radius: 0.4rem;
          outline: none;
          padding: 12px;
          font-size: 1rem;
          min-height: 3rem;
          border: 1px solid #E4E7ED;
          background: #F2F6FC;
          color: #606266;
          line-height: 1.2rem;
        }
      }
      .two{
        display: flex;
        flex-direction: row;
        margin-left: 3.3rem;
        justify-content: space-between;
        button{
          width: 48%;
          height: 1.8rem;
          font-size: 1rem;
          line-height: 1.8rem;
          border: 0;
          border-radius: 0.2rem;
          color: #FFFFFF;
          letter-spacing: 2px;
          cursor: pointer;
        }
        button:nth-child(1){
          background: #158bf1;
        }
        button:nth-child(2){
          background: #909399;
        }
      }

    }
    //一级评论
    .parent{
      //单独的评论div
      .main{
        display: flex;
        flex-direction: row;
        margin-bottom: 0.5rem;
        //头像
        img{
          width: 3rem;
          height: 3rem;
          margin-right: 0.3rem;
          border-radius: 100%;
        }
        //头像右侧的一堆信息
        .info{
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 1rem 0 0.5rem;
          div:nth-child(1){
            font-size: 0.8rem;
            font-weight: 900;
            color: #606266;
            span{
              margin-left: 0.2rem;
              color: #ea605c;
            }
          }
          div:nth-child(3){
            font-size: 0.8rem;
            color: #909399;
          }
          div:nth-child(4){
            font-size: 0.9rem;
            color: #909399;
            //去除icon为trash这个button的padding
            /deep/.el-button{
              padding:0;
            }
            i{
              cursor: pointer;
              margin-right: 0.8rem;
            }
            //垃圾图标变红
            .fa-trash{
              color: #ea605c!important;
            }
          }
        }
      }
      //二级评论
      .son_all{
        margin-left: 2.5rem;
        margin-bottom: 0.5rem;
        background: #F2F6FC;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 0.4rem;
        border: 1px solid #E4E7ED;
        .son{
          .main_1{
            display: flex;
            flex-direction: row;
            margin-bottom: 0.5rem;
            //头像
            img{
              width: 1.8rem;
              height: 1.8rem;
              margin-right: 0.3rem;
              border-radius: 100%;
            }
            //头像右侧的一堆信息
            .info{
              display: flex;
              flex-direction: column;
              justify-content: center;
              div:nth-child(1){
                font-size: 0.8rem;
                font-weight: 900;
                color: #606266;
                span{
                  margin-left: 0.2rem;
                  color: #ea605c;
                }
              }
              div:nth-child(3){
                font-size: 0.8rem;
                color: #909399;
              }
              div:nth-child(4){
                font-size: 0.9rem;
                color: #909399;
                //去除icon为trash这个button的padding
                /deep/.el-button{
                  padding:0;
                }
                i{
                  cursor: pointer;
                  margin-right: 0.8rem;
                }
                //垃圾图标变红
                .fa-trash{
                  color: #ea605c!important;
                }
              }
            }
          }
        }
      }
    }
  }
  .authorBtn{
    background: rgba(234, 96, 92,0.9);
    border: none;
    border-radius: 0.2rem;
    color: #ffffff;
    font-size: 0.6rem;
  }
  @media screen and (max-width: 600px)
  {
    .isPE{
      display: block;
    }
    .isPC{
      display: none;
    }
    .comment_body{
      margin-left: 0.5rem;
      //发布评论
      .publish_comment{
        margin-bottom: 1rem;
        .one{
          display: flex;
          flex-direction: row;
          margin-bottom: 0.4rem;
          img{
            width: 2rem;
            height: 2rem;
            border-radius: 100%;
            margin-right: 0.3rem;
          }
          textarea{
            resize: vertical;
            width: 100%;
            border-radius: 0.4rem;
            outline: none;
            padding: 12px;
            font-size: 1rem;
            min-height: 3rem;
            border: 1px solid #E4E7ED;
            background: #F2F6FC;
            color: #606266;
            line-height: 1.2rem;
          }
        }
        .two{
          display: flex;
          flex-direction: row;
          margin-left: 2.3rem;
          justify-content: space-between;
          button{
            width: 48%;
            height: 1.8rem;
            font-size: 1rem;
            line-height: 1.8rem;
            border: 0;
            border-radius: 0.2rem;
            color: #FFFFFF;
            letter-spacing: 2px;
            cursor: pointer;
          }
          button:nth-child(1){
            background: #158bf1;
          }
          button:nth-child(2){
            background: #909399;
          }
        }

      }
      //一级评论
      .parent{
        //单独的评论div
        .main{
          display: flex;
          flex-direction: row;
          margin-bottom: 0.5rem;
          //头像
          img{
            width: 2rem;
            height: 2rem;
            margin-right: 0.3rem;
            border-radius: 100%;
          }
          //头像右侧的一堆信息
          .info{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 0.3rem 0 0.1rem;
            div:nth-child(1){
              font-size: 0.8rem;
              font-weight: 900;
              color: #606266;
              span{
                margin-left: 0.2rem;
                color: #ea605c;
              }
            }
            div:nth-child(3){
              font-size: 0.8rem;
              color: #909399;
            }
            div:nth-child(4){
              font-size: 0.9rem;
              color: #909399;
              //去除icon为trash这个button的padding
              /deep/.el-button{
                padding:0;
              }
              i{
                cursor: pointer;
                margin-right: 0.8rem;
              }
              //垃圾图标变红
              .fa-trash{
                color: #ea605c!important;
              }
            }
          }
        }
        //二级评论
        .son_all{
          margin-left: 1.4rem;
          margin-bottom: 0.5rem;
          background: #F2F6FC;
          padding: 0.5rem 0.5rem 0.3rem 0.8rem;
          border-radius: 0.4rem;
          border: 1px solid #E4E7ED;
          .son{
            .main_1{
              display: flex;
              flex-direction: row;
              margin-bottom: 0.5rem;
              //头像
              img{
                width: 1.2rem;
                height: 1.2rem;
                margin-right: 0.3rem;
                border-radius: 100%;
              }
              //头像右侧的一堆信息
              .info{
                display: flex;
                flex-direction: column;
                justify-content: center;
                div:nth-child(1){
                  font-size: 0.8rem;
                  font-weight: 900;
                  color: #606266;
                  span{
                    margin-left: 0.2rem;
                    color: #ea605c;
                  }
                }
                div:nth-child(3){
                  font-size: 0.8rem;
                  color: #909399;
                }
                div:nth-child(4){
                  font-size: 0.9rem;
                  color: #909399;
                  //去除icon为trash这个button的padding
                  /deep/.el-button{
                    padding:0;
                  }
                  i{
                    cursor: pointer;
                    margin-right: 0.8rem;
                  }
                  //垃圾图标变红
                  .fa-trash{
                    color: #ea605c!important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>